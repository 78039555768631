import { createContext, useContext } from 'react';
import { type ReadonlyBinding, useBinding, useCallbackRef, useDerivedBinding } from 'react-bindings';

import { DomResizeDetector } from '../components/layout/DomResizeDetector';
import type { ChildrenProps } from '../types/ChildrenProps';
import { assert } from '../utils/assert';

const ContainerHeightContext = createContext<ContainerHeightInfo | undefined>(undefined);

export interface ContainerHeightProviderProps {
  /** If not provided, `ResizeDetector` will be used */
  heightPx?: ReadonlyBinding<number>;
}

export const useContainerHeight = () => {
  const output = useContext(ContainerHeightContext);
  assert(output !== undefined, 'ContainerHeightProvider must be used with useContainerHeight');
  return output;
};

export const useRequiredContainerHeight = () => {
  const output = useContext(ContainerHeightContext);
  assert(output !== undefined, 'ContainerHeightProvider must be used with useRequiredContainerHeight');
  return output;
};

export const ContainerHeightProvider = ({ children, heightPx }: ChildrenProps & ContainerHeightProviderProps) =>
  heightPx !== undefined ? (
    <ExplicitContainerHeightProvider heightPx={heightPx}>{children}</ExplicitContainerHeightProvider>
  ) : (
    <ImplicitContainerHeightProvider>{children}</ImplicitContainerHeightProvider>
  );

// Helpers

const ExplicitContainerHeightProvider = ({
  children,
  heightPx
}: ChildrenProps & ContainerHeightProviderProps & { heightPx: ReadonlyBinding<number> }) => {
  const sizeInfo = useContainerHeightInfo(heightPx);

  return <ContainerHeightContext.Provider value={sizeInfo}>{children}</ContainerHeightContext.Provider>;
};

const ImplicitContainerHeightProvider = ({ children }: ChildrenProps & ContainerHeightProviderProps & { heightPx?: undefined }) => {
  // Using the parent container height (or `window.innerHeight`) by default
  const parentContainerHeight = useContext(ContainerHeightContext);
  const heightPx = useBinding(() => (parentContainerHeight === undefined ? window.innerHeight : parentContainerHeight.get()), {
    id: 'heightPx',
    detectChanges: true
  });
  const sizeInfo = useContainerHeightInfo(heightPx);

  const onResize = useCallbackRef((_width: number | undefined, height: number | undefined) => heightPx.set(Math.round(height ?? 0)));

  return (
    <>
      <DomResizeDetector handleWidth={false} onResize={onResize} />
      <ContainerHeightContext.Provider value={sizeInfo}>{children}</ContainerHeightContext.Provider>
    </>
  );
};

// Helpers

type ContainerHeightInfo = ReturnType<typeof useContainerHeightInfo>;

const useContainerHeightInfo = (sizePx: ReadonlyBinding<number>) => {
  const b = useDerivedBinding(sizePx, (sizePx) => sizePx, { id: 'containerHeightInfo' });

  return {
    ...b,
    useIs: (compareTo: number) => useDerivedBinding(b, (v) => v === compareTo, { id: 'isEq' }),
    useIsGt: (compareTo: number) => useDerivedBinding(b, (v) => v > compareTo, { id: 'isGt' }),
    useIsGte: (compareTo: number) => useDerivedBinding(b, (v) => v >= compareTo, { id: 'isGte' }),
    useIsLt: (compareTo: number) => useDerivedBinding(b, (v) => v < compareTo, { id: 'isLt' }),
    useIsLte: (compareTo: number) => useDerivedBinding(b, (v) => v <= compareTo, { id: 'isLte' })
  };
};
