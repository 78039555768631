import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas, makePaginatedSchema } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { articleSearchSortSchema } from '../../types/api-data/article/ArticleSearchSort';
import { organizedArticleInfosSchema } from '../../types/api-data/article/OrganizedArticleInfos';
import { groupIdentifierSchema } from '../../types/api-data/identifiers/GroupIdentifier';
import { personaIdentifierSchema } from '../../types/api-data/identifiers/PersonaIdentifier';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'search',
  credentials: 'include',
  url: '/search/articles',
  schemas: {
    request: {
      query: schema.object({
        authorId: personaIdentifierSchema.optional(),
        groupId: groupIdentifierSchema.optional(),
        /** @defaultValue `'highlighted'` */
        sort: articleSearchSortSchema.optional(),
        query: schema.string().optional()
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        organizedArticles: makePaginatedSchema(organizedArticleInfosSchema)
      })
    },
    failureResponse: genericFailureSchemas
  }
});
