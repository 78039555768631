import type { Omit } from 'lodash';
import type { CSSProperties } from 'react';
import type { Binding, ReadonlyBinding } from 'react-bindings';
import { BC, useCallbackRef } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { imageIconsBack } from '../../assets/icons/back';
import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { px, sp, SP_PX, STD_ROW_SIZE_PX } from '../../consts/layout';
import { palette, resolveColorVariant } from '../../consts/palette';
import { ONE_SEC_MSEC } from '../../consts/time';
import { useNavigation } from '../../context/navigation';
import { useNavigationStackLeftItems } from '../../context/navigation/useNavigationStackLeftItems';
import { useNavigationStackRightItems } from '../../context/navigation/useNavigationStackRightItems';
import { useNavigationStackTitle } from '../../context/navigation/useNavigationStackTitle';
import { useAnimatedThemeColor } from '../../context/theme-color';
import { TypographyContextProvider } from '../../context/typography';
import { useFwdDomRef } from '../../context/useFwdDomRef';
import { makeCustomColorVariant } from '../../types/ColorVariant';
import type { Sides } from '../../types/Sides';
import { typographyOrContext } from '../aliases/typographyOrContext';
import { CrossFade } from '../animation/CrossFade';
import { DomResizeDetector } from '../layout/DomResizeDetector';
import { SafeSidesRow } from '../layout/SafeSidesRow';
import { Button } from './Button';
import { Icon } from './Icon';
import { LCR } from './LCR';
import { PullDown } from './PullDown';

export interface NavigationBarProps {
  outHeightPx: Binding<number>;
  needsSafeSides: Sides;
  showSeparator?: ReadonlyBinding<boolean>;
  onClick?: () => void;
}

export const NavigationBar = ({ outHeightPx, needsSafeSides, showSeparator, onClick }: NavigationBarProps) => {
  const themeColor = useAnimatedThemeColor();
  const navigation = useNavigation();
  const leftItems = useNavigationStackLeftItems();
  const rightItems = useNavigationStackRightItems();
  const title = useNavigationStackTitle();
  const classNames = useStyles();

  const domRef = useFwdDomRef<HTMLDivElement>();

  const onBackButtonClick = useCallbackRef(() => {
    navigation.pop();
  });

  const onResize = useCallbackRef((_width: number | undefined, height: number | undefined) =>
    outHeightPx.set(Math.round(height ?? 0) + SP_PX * 2)
  );

  return BC({ themeColor, showSeparator }, ({ themeColor, showSeparator }) => {
    const textColorVariant = makeCustomColorVariant(themeColor.contrastColor, themeColor.colorClass === 'light' ? 'dark' : 'light');

    return (
      <TypographyContextProvider color={textColorVariant}>
        <PullDown color={themeColor.color} />
        <SafeSidesRow
          fwdRef={domRef}
          needsSafeSides={needsSafeSides}
          className={`NavigationBar ${classNames.navigationBar} ${showSeparator ?? false ? classNames.showSeparator : ''}`}
          alignItems="center"
          style={{
            backgroundColor: themeColor.color,
            ...mainStyle
          }}
          onClick={onClick}
        >
          <DomResizeDetector handleWidth={false} onResize={onResize} targetRef={domRef} />
          <LCR
            left={BC({ canPop: navigation.canPop, leftItems }, ({ canPop, leftItems }, { leftItems: leftItemsBinding }) => (
              <CrossFade
                contentChangeUid={`${navigation.canPop.getChangeUid()};${leftItemsBinding.getChangeUid()}`}
                transitioningPartStyle={partStyle}
              >
                {canPop ? (
                  <Button color="textPrimary" variant="text" onClick={onBackButtonClick}>
                    <Icon src={imageIconsBack} variant={themeColor.colorClass === 'light' ? 'black' : 'white'} />
                  </Button>
                ) : null}
                {leftItems}
              </CrossFade>
            ))}
            center={({ maxWidthPx }) =>
              BC(title, (title, titleBinding) => (
                <CrossFade
                  style={{ maxWidth: px(maxWidthPx) }}
                  transitioningPartStyle={{ ...partStyle, maxWidth: px(maxWidthPx) }}
                  contentChangeUid={titleBinding.getChangeUid()}
                >
                  {typographyOrContext(
                    title,
                    { variant: 'navigationBarTitle' },
                    {
                      typographyProps: {
                        component: 'div',
                        style: { textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }
                      }
                    }
                  )}
                </CrossFade>
              ))
            }
            right={BC(rightItems, (rightItems, rightItemsBinding) => (
              <CrossFade contentChangeUid={rightItemsBinding.getChangeUid()} transitioningPartStyle={partStyle}>
                {rightItems}
              </CrossFade>
            ))}
          />
        </SafeSidesRow>
      </TypographyContextProvider>
    );
  });
};

// Helpers

const useStyles = createUseStyles({
  navigationBar: {
    minHeight: `${STD_ROW_SIZE_PX}px`,
    width: '100%',
    transition: `box-shadow ${ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s`
  },
  showSeparator: {
    boxShadow: `0 1px 0 ${resolveColorVariant(palette.divider)}`
  }
});

const mainStyle: Omit<CSSProperties, 'padding'> = {
  paddingTop: sp(0.5),
  paddingRight: sp(2),
  paddingBottom: sp(0.5),
  paddingLeft: sp(2)
};

const partStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  columnGap: sp(1),
  alignItems: 'center'
};
