import { mix } from 'color2k';
import { useTimerBinding } from 'linefeedr-timer-binding';
import { createContext, useContext, useEffect, useRef } from 'react';
import type { ReadonlyBinding } from 'react-bindings';
import { BC, makeBinding, useBinding, useBindingEffect, useDerivedBinding } from 'react-bindings';
import { Helmet } from 'react-helmet';

import { SCREEN_LEVEL_TRANSITION_DURATION_MSEC } from '../consts/animation';
import { palette, resolveColorVariant } from '../consts/palette';
import { ONE_SEC_MSEC } from '../consts/time';
import type { ColorValues } from '../types/ColorValues';
import { DoubleLinkedList } from '../types/DoubleLinkedList';
import { determineColorClass } from '../utils/colors';

const DEFAULT_THEME_COLOR: ColorValues = {
  color: resolveColorVariant(palette.mainBackground),
  contrastColor: resolveColorVariant(palette.textPrimary),
  colorClass: determineColorClass(resolveColorVariant(palette.mainBackground))
};

const ThemeColorContext = createContext(makeBinding(() => new DoubleLinkedList<ColorValues>(), { id: 'themeColorStack' }));

export const useThemeColor = () => {
  const stack = useContext(ThemeColorContext);
  return useDerivedBinding(stack, (stack) => stack.getTail()?.value ?? DEFAULT_THEME_COLOR, {
    id: 'themeColor',
    detectInputChanges: false
  });
};

export const useAnimatedThemeColor = () => {
  const themeColor = useThemeColor();

  const lastThemeColor = useRef({ ...themeColor.get(), updateTimeMSec: 0 });
  const animatedThemeColor = useBinding(() => themeColor.get(), { id: 'animatedThemeColor' });

  const animationTimer = useTimerBinding({ intervalMSec: ONE_SEC_MSEC / 60, enabled: false });
  const disableAnimationTimerTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();

  useBindingEffect(
    themeColor,
    (_themeColor, themeColorBinding) => {
      if (disableAnimationTimerTimeout.current !== undefined) {
        clearTimeout(disableAnimationTimerTimeout.current);
        disableAnimationTimerTimeout.current = undefined;
      }

      lastThemeColor.current = { ...animatedThemeColor.get(), updateTimeMSec: Date.now() };
      animationTimer.enabled.set(true);
      disableAnimationTimerTimeout.current = setTimeout(() => {
        animatedThemeColor.set(themeColorBinding.get());
        animationTimer.enabled.set(false);
      }, SCREEN_LEVEL_TRANSITION_DURATION_MSEC);
    },
    { detectInputChanges: true }
  );

  useBindingEffect(animationTimer, () => {
    const elapsedTimeMSec = Date.now() - lastThemeColor.current.updateTimeMSec;
    const elapsedPercent = Math.min(1, Math.max(0, elapsedTimeMSec / SCREEN_LEVEL_TRANSITION_DURATION_MSEC));
    const newColor = mix(lastThemeColor.current.color, themeColor.get().color, elapsedPercent);
    animatedThemeColor.set({
      color: newColor,
      contrastColor: mix(lastThemeColor.current.contrastColor, themeColor.get().contrastColor, elapsedPercent),
      colorClass: determineColorClass(newColor)
    });
  });

  return animatedThemeColor as ReadonlyBinding<ColorValues>;
};

export const useSetThemeColorWhileMounted = ({ color, contrastColor }: Partial<ColorValues>) => {
  const stack = useContext(ThemeColorContext);

  useEffect(() => {
    if (color === undefined || contrastColor === undefined) {
      return undefined;
    }

    const theStack = stack.get();
    const node = theStack.append({ color, contrastColor, colorClass: determineColorClass(color) });
    stack.set(theStack);

    return () => {
      theStack.remove(node);
      stack.set(theStack);
    };
  });
};

export const SetThemeColor = (themeColor: Partial<ColorValues>) => {
  useSetThemeColorWhileMounted(themeColor);

  return null;
};

export const ThemeColorConnector = () => {
  const themeColor = useAnimatedThemeColor();

  return BC(themeColor, (themeColor) => (
    <Helmet>
      <meta name="theme-color" content={themeColor.color} />
    </Helmet>
  ));
};
