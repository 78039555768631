import type { ThemeColor } from '../types/api-data/ThemeColor';

export const defaultThemeColor: ThemeColor = 'blue2';

export const themeColorHexValues: Record<ThemeColor, string> = {
  blue1: '#56D2FF',
  blue2: '#00BBFF',
  blue3: '#0088BA',
  blue4: '#005A80',
  aqua1: '#73FDDD',
  aqua2: '#16E7BA',
  aqua3: '#00AB7D',
  aqua4: '#006C5A',
  green1: '#99FA4E',
  green2: '#71D836',
  green3: '#2FB100',
  green4: '#0D7100',
  yellow1: '#FFE056',
  yellow2: '#FFC532',
  yellow3: '#FE9400',
  yellow4: '#F25900',
  red1: '#FF8D8F',
  red2: '#FF514E',
  red3: '#EE0C0C',
  red4: '#B50600',
  pink1: '#FF95D5',
  pink2: '#FF42B3',
  pink3: '#D41889',
  pink4: '#970E60',
  white: '#FFFFFF',
  gray1: '#D5D5D5',
  gray2: '#929292',
  gray3: '#5E5E5E',
  black: '#000000'
};

/** Slightly darker versions of these colors when using for text on light/white backgrounds */
export const themeColorTextColorsOnLightBackgrounds: Record<ThemeColor, string> = {
  ...themeColorHexValues,
  aqua1: '#16E7BA',
  green1: '#71D836',
  yellow1: '#FFC532',
  white: '#929292',
  gray1: '#929292'
};

export const themeColorContrastHexValues: Record<ThemeColor, string> = {
  blue1: '#000000',
  blue2: '#000000',
  blue3: '#FFFFFF',
  blue4: '#FFFFFF',
  aqua1: '#000000',
  aqua2: '#000000',
  aqua3: '#FFFFFF',
  aqua4: '#FFFFFF',
  green1: '#000000',
  green2: '#000000',
  green3: '#FFFFFF',
  green4: '#FFFFFF',
  yellow1: '#000000',
  yellow2: '#000000',
  yellow3: '#FFFFFF',
  yellow4: '#FFFFFF',
  red1: '#000000',
  red2: '#000000',
  red3: '#FFFFFF',
  red4: '#FFFFFF',
  pink1: '#000000',
  pink2: '#000000',
  pink3: '#FFFFFF',
  pink4: '#FFFFFF',
  white: '#000000',
  gray1: '#000000',
  gray2: '#000000',
  gray3: '#FFFFFF',
  black: '#FFFFFF'
};
