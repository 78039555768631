import { createContext, useContext } from 'react';
import type { Binding, ReadonlyBinding } from 'react-bindings';
import { makeConstBinding, useBinding, useCallbackRef, useDerivedBinding } from 'react-bindings';

import { DomResizeDetector } from '../components/layout/DomResizeDetector';
import type { ChildrenProps } from '../types/ChildrenProps';
import type { EdgeInsetsPx } from '../types/EdgeInsetsPx';
import type { Sides } from '../types/Sides';
import { extractSides } from '../types/Sides';
import { useDomRef } from './useDomRef';

const defaultSafeAreaInsets = makeConstBinding<EdgeInsetsPx>(
  { topPx: 0, rightPx: 0, bottomPx: 0, leftPx: 0 },
  { id: 'defaultSafeAreaInsets' }
);

const SafeAreaInsetsContext = createContext<ReadonlyBinding<EdgeInsetsPx>>(defaultSafeAreaInsets);

export const useSafeAreaInsets = () => useContext(SafeAreaInsetsContext);

export interface SafeAreaInsetsProviderProps {
  /** @defaultValue `false` */
  disabled?: boolean;
}

export const SafeAreaInsetsProvider = ({ children, disabled = false }: ChildrenProps & SafeAreaInsetsProviderProps) => {
  const value = useBinding<EdgeInsetsPx>(() => ({ topPx: 0, rightPx: 0, bottomPx: 0, leftPx: 0 }), { id: 'safeAreaInsets' });

  return (
    <SafeAreaInsetsContext.Provider value={disabled ? defaultSafeAreaInsets : value}>
      {!disabled ? <SafeAreaInsetsConnector value={value} /> : null}
      {children}
    </SafeAreaInsetsContext.Provider>
  );
};

export interface SafeAreaInsetsEliminatorProps {
  eliminateSides: Sides;
}

export const SafeAreaInsetsEliminator = ({ children, eliminateSides }: ChildrenProps & SafeAreaInsetsEliminatorProps) => {
  const safeAreaInsets = useSafeAreaInsets();

  const { top, right, bottom, left } = extractSides(eliminateSides);

  const modifiedSafeAreaInsets = useDerivedBinding(
    safeAreaInsets,
    (safeAreaInsets): EdgeInsetsPx => ({
      topPx: top ? 0 : safeAreaInsets.topPx,
      rightPx: right ? 0 : safeAreaInsets.rightPx,
      bottomPx: bottom ? 0 : safeAreaInsets.bottomPx,
      leftPx: left ? 0 : safeAreaInsets.leftPx
    }),
    { id: 'modifiedSafeAreaInsets', deps: [top, right, bottom, left] }
  );

  return <SafeAreaInsetsContext.Provider value={modifiedSafeAreaInsets}>{children}</SafeAreaInsetsContext.Provider>;
};

// Helpers

interface SafeAreaInsetsConnectorProps {
  value: Binding<EdgeInsetsPx>;
}

const SafeAreaInsetsConnector = ({ value }: SafeAreaInsetsConnectorProps) => {
  const topRef = useDomRef<HTMLDivElement>();
  const rightRef = useDomRef<HTMLDivElement>();
  const bottomRef = useDomRef<HTMLDivElement>();
  const leftRef = useDomRef<HTMLDivElement>();

  const onTopResize = useCallbackRef((size: number | undefined) => {
    value.set({ ...value.get(), topPx: Math.round(size ?? 0) });
  });
  const onRightResize = useCallbackRef((size: number | undefined) => {
    value.set({ ...value.get(), rightPx: Math.round(size ?? 0) });
  });
  const onBottomResize = useCallbackRef((size: number | undefined) => {
    value.set({ ...value.get(), bottomPx: Math.round(size ?? 0) });
  });
  const onLeftResize = useCallbackRef((size: number | undefined) => {
    value.set({ ...value.get(), leftPx: Math.round(size ?? 0) });
  });

  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        visibility: 'hidden'
      }}
    >
      <div ref={topRef} style={{ width: 'env(safe-area-inset-top, 0px)' }}>
        <DomResizeDetector onResize={onTopResize} targetRef={topRef} />
      </div>
      <div ref={rightRef} style={{ width: 'env(safe-area-inset-right, 0px)' }}>
        <DomResizeDetector onResize={onRightResize} targetRef={rightRef} />
      </div>
      <div ref={bottomRef} style={{ width: 'env(safe-area-inset-bottom, 0px)' }}>
        <DomResizeDetector onResize={onBottomResize} targetRef={bottomRef} />
      </div>
      <div ref={leftRef} style={{ width: 'env(safe-area-inset-left, 0px)' }}>
        <DomResizeDetector onResize={onLeftResize} targetRef={leftRef} />
      </div>
    </div>
  );
};
