import { resolveStringy, type Stringy } from 'linefeedr-localization';
import type { CSSProperties } from 'react';
import { useCallbackRef } from 'react-bindings';

import { px, STD_ICON_SIZE_PX, STD_SMALL_ICON_SIZE_PX } from '../../consts/layout';
import { palette } from '../../consts/palette';
import { useT } from '../../context/useT';
import type { RegularImageSource } from '../../types/RegularImageSource';
import { isVariantImageSource, type VariantImageSource } from '../../types/VariantImageSource';

export type IconStandardSize = 'list-item-icon' | 'small-icon';

export type IconProps<VariantT extends string = string> = {
  className?: string;
  style?: Omit<CSSProperties, 'height' | 'width'>;
  alt?: Stringy;

  onLoad?: (elem: HTMLImageElement) => void;
} & (RegularSourceExtraIconProps | VariantImageSourceExtraIconProps<VariantT>);

export const Icon = <VariantT extends string = string>(props: IconProps<VariantT>) => {
  const t = useT();

  const { src, className, style, standardSize, alt, onLoad } = props;
  let { width, height } = props;

  if (standardSize !== undefined) {
    const resolvedStandardSize = sizesPxByIconStandardSize[standardSize];
    if (width === undefined) {
      width = px(resolvedStandardSize);
    }
    if (height === undefined) {
      height = px(resolvedStandardSize);
    }
  }

  const wrappedOnLoad: React.EventHandler<React.SyntheticEvent<HTMLImageElement>> = useCallbackRef(
    (event) => onLoad?.(event.currentTarget)
  );
  const needsOnLoad = onLoad !== undefined;

  if (src === undefined) {
    const regularProps = props as typeof props & RegularSourceExtraIconProps;
    return <IconPlaceholder {...regularProps} />;
  } else if (isVariantImageSource(src)) {
    const { variant } = props as typeof props & VariantImageSourceExtraIconProps<VariantT>;
    return (
      <img
        className={`Icon ${className ?? ''}`}
        src={src.svgs?.[variant] ?? src.pngs?.[variant]}
        style={{ ...style, width: width ?? px(src.widthPx), height: height ?? px(src.heightPx) }}
        alt={alt !== undefined ? resolveStringy(alt, t) : undefined}
        onLoad={needsOnLoad ? wrappedOnLoad : undefined}
      />
    );
  } else {
    const { src } = props as typeof props & RegularSourceExtraIconProps;
    return (
      <img
        className={`Icon ${className ?? ''}`}
        src={src}
        style={{ ...style, width, height }}
        alt={alt !== undefined ? resolveStringy(alt, t) : undefined}
        onLoad={needsOnLoad ? wrappedOnLoad : undefined}
      />
    );
  }
};

export type IconPlaceholderProps = Omit<IconProps, 'src'>;

export const IconPlaceholder = ({ width, height, standardSize, className, style }: IconPlaceholderProps) => {
  if (standardSize !== undefined) {
    const resolvedStandardSize = sizesPxByIconStandardSize[standardSize];
    if (width === undefined) {
      width = px(resolvedStandardSize);
    }
    if (height === undefined) {
      height = px(resolvedStandardSize);
    }
  }

  return (
    <div
      className={`Icon ${className ?? ''}`}
      style={{ backgroundColor: palette.placeholderBackground(), flexShrink: 0, ...style, width, height }}
    />
  );
};

// Helpers

const sizesPxByIconStandardSize: Record<IconStandardSize, number> = {
  'list-item-icon': STD_ICON_SIZE_PX,
  'small-icon': STD_SMALL_ICON_SIZE_PX
};

type RegularSourceExtraIconProps = {
  src: RegularImageSource | undefined;
} & (
  | { width: CSSProperties['width']; height: CSSProperties['height']; standardSize?: undefined }
  | { width?: CSSProperties['width']; height?: CSSProperties['height']; standardSize: IconStandardSize }
);

interface VariantImageSourceExtraIconProps<VariantT extends string> {
  src: VariantImageSource<VariantT>;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  standardSize?: IconStandardSize;
  variant: VariantT;
}
