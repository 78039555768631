import { useEffect } from 'react';
import { BC } from 'react-bindings';

import type { DocumentNavigation } from '../../context/navigation/types/DocumentNavigation';
import type { NavDocumentHook } from '../../context/navigation/types/NavDocument';

export interface DocumentNavigationConnectorProps {
  navigation: DocumentNavigation;
}

/** Hooks up the stacks topMounted binding by resolving the hook while mounted */
export const DocumentNavigationConnector = ({ navigation }: DocumentNavigationConnectorProps) =>
  BC(navigation.current, (current) => (
    <Connect key={navigation.current.getChangeUid()} navigation={navigation} useCurrent={current?.useDoc} />
  ));

// Helpers

const Connect = ({ navigation, useCurrent }: { navigation: DocumentNavigation; useCurrent: NavDocumentHook | undefined }) => {
  const current = useCurrent?.();

  useEffect(() => {
    navigation.currentMounted.set(current);

    return () => navigation.currentMounted.set(undefined);
  });

  return null;
};
