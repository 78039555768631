import { schema } from 'yaschema';

import { personaIdentifierSchema } from '../identifiers/PersonaIdentifier';
import { articleAccessStatusSchema } from './ArticleAccessStatus';

export const articleAccessorInfoSchema = schema.object({
  personaId: personaIdentifierSchema,
  status: articleAccessStatusSchema
});

export type ArticleAccessorInfo = typeof articleAccessorInfoSchema.valueType;
