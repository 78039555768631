import { useRef } from 'react';
import { useBinding, useCallbackRef } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { STD_ROW_SIZE_PX } from '../../consts/layout';
import { useNavigation } from '../../context/navigation';
import { DocumentNavigationSelector } from '../../context/navigation/DocumentNavigationSelector';
import { ScreenLockerProvider } from '../../context/screen-locker';
import type { ChildrenProps } from '../../types/ChildrenProps';
import { Col } from '../layout/Col';
import type { DocumentBodyControls } from './DocumentBody';
import { DocumentBody } from './DocumentBody';
import { DocumentNavigationConnector } from './DocumentNavigationConnector';
import { StickyNavigationBar } from './StickyNavigationBar';
import { StickyToolbar } from './StickyToolbar';

export const Presenter = ({ children }: ChildrenProps) => {
  const navigation = useNavigation();
  const classNames = useStyles();

  const navigationBarHeightPx = useBinding(() => STD_ROW_SIZE_PX, { id: 'navigationBarHeightPx', detectChanges: true });
  const toolbarHeightPx = useBinding(() => STD_ROW_SIZE_PX, { id: 'toolbarHeightPx', detectChanges: true });

  const showNavigationBarSeparator = useBinding(() => false, { id: 'showNavigationBarSeparator', detectChanges: true });
  const showToolbarSeparator = useBinding(() => false, { id: 'isScrolled', detectChanges: true });

  const documentBodyControls = useRef<DocumentBodyControls>({});
  const scrollToTop = useCallbackRef(() => documentBodyControls.current.scrollToTop?.());

  return (
    <ScreenLockerProvider>
      <DocumentNavigationConnector navigation={navigation.main} />
      <DocumentNavigationSelector screenArea="main">
        <Col className={`Presenter ${classNames.main}`} alignItems="stretch">
          <StickyNavigationBar outHeightPx={navigationBarHeightPx} showSeparator={showNavigationBarSeparator} onClick={scrollToTop} />
          <DocumentBody
            topInsetPx={navigationBarHeightPx}
            bottomInsetPx={toolbarHeightPx}
            outShowNavigationBarSeparator={showNavigationBarSeparator}
            outShowToolbarSeparator={showToolbarSeparator}
            controls={documentBodyControls.current}
          />
          {/* children doesn't actually render anything in most cases, but it's used to set various contexts that update the other
          presented components */}
          {children}
          <StickyToolbar outHeightPx={toolbarHeightPx} showSeparator={showToolbarSeparator} />
        </Col>
      </DocumentNavigationSelector>
    </ScreenLockerProvider>
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    flexGrow: 1,
    overflow: 'hidden'
  }
});
