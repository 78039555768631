/* eslint-disable */
import svg_black from './black.svg';
import svg_white from './white.svg';
import svg_divider from './divider.svg';

export const imageIconsDisclosureArrow = {
  isVariantImageSource: true as const,
  widthPx: 24,
  heightPx: 24,
  svgs: { black: svg_black, white: svg_white, divider: svg_divider }
};
