import { apiData } from 'lf-blog-schema';

import type { ArticlePreviewScreenArgs } from '../../screens/ArticlePreviewScreen';
import { makeRouteInfo } from '../makeRouteInfo';
import { editArticleRoute } from './editArticleRoute';

export const articlePreviewRoute = makeRouteInfo<
  { articleId: apiData.ArticleIdentifier },
  [Omit<ArticlePreviewScreenArgs, 'articleId'>] | []
>({
  path: ['article', ['articleId'], 'preview'],
  pathSchemas: { articleId: apiData.articleIdentifierSchema },
  defaultHistory: (context, { articleId }, _args = {}) => [editArticleRoute(context, { articleId })],
  screen: async (_context, { articleId }, args = {}) => {
    const { ArticlePreviewScreen } = await import('../../screens/ArticlePreviewScreen');

    return ArticlePreviewScreen({ ...args, articleId });
  }
});
