import { Fragment } from 'react';
import { BC, useDerivedBinding } from 'react-bindings';

import { VirtualizedCol } from '../../../components/layout/VirtualizedCol';
import { ListHeader } from '../../../components/list/ListHeader';
import { inlineStateless } from '../../../makers/makeStatelessComponent';
import type { RealReactNode } from '../../../types/RealReactNode';
import { renderDevToolComponent, useLiveDevTools } from '../../tools/dev-tools-support';

export const DevToolsSection = () => {
  const liveDevTools = useLiveDevTools();

  const dedupedLiveDevTools = useDerivedBinding(
    liveDevTools,
    (tools) => {
      const alreadyUsed = new Set<string>();

      const toolNodes: RealReactNode[] = [];
      for (const tool of tools.toArray()) {
        if (alreadyUsed.has(tool.id)) {
          continue;
        }
        alreadyUsed.add(tool.id);

        toolNodes.push(<Fragment key={tool.id}>{renderDevToolComponent(tool)}</Fragment>);
      }

      return toolNodes;
    },
    { id: 'dedupedLiveDevTools', detectInputChanges: false, detectOutputChanges: false }
  );

  return inlineStateless(() =>
    BC(dedupedLiveDevTools, (toolNodes) => (
      <VirtualizedCol>
        <ListHeader>Tools</ListHeader>
        {toolNodes}
      </VirtualizedCol>
    ))
  );
};
