import { makeConstBinding, useFlattenedBinding } from 'react-bindings';

import { useDocumentNavigation } from './DocumentNavigationProvider';

const defaultItemsBinding = makeConstBinding([], { id: 'defaultToolbarStackLeftItems' });

export const useToolbarStackLeftItems = () => {
  const navigation = useDocumentNavigation();

  return useFlattenedBinding(navigation?.currentMounted, (top) => top?.toolbarItems.leftItems ?? defaultItemsBinding, {
    id: 'toolbarStackLeftItems',
    detectInputChanges: false,
    detectOutputChanges: true,
    areOutputValuesEqual: (a, b) => a === b
  });
};
