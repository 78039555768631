import type { EmptyObject } from 'react-bindings';

import type { SignInScreenArgs } from '../../screens/SignInScreen';
import { makeRouteInfo } from '../makeRouteInfo';

export const signInRoute = makeRouteInfo<EmptyObject, [SignInScreenArgs] | []>({
  path: ['sign-in'],
  pathSchemas: {},
  screen: async (_context, _params, args = {}) => (await import('../../screens/SignInScreen')).SignInScreen(args)
});
