import type { SelectFieldChoice } from '../../../components/fields/SelectField';
import { SelectField } from '../../../components/fields/SelectField';
import { ListItem } from '../../../components/list/ListItem';
import type { DevTool } from '../dev-tools-support';

export interface ChoiceDevToolProps<T> {
  devTool: DevTool<T>;
}

export const makeChoiceDevTool =
  <T extends string>(title: string, choices: SelectFieldChoice<T>[]) =>
  ({ devTool }: ChoiceDevToolProps<T>) => (
    <ListItem>
      <SelectField title={title} value={devTool.value} choiceGroups={[{ choices }]} width="100%" />
    </ListItem>
  );
