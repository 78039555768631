import _ from 'lodash';

import { developmentConfig } from './development';
import type { AppConfig } from './types';

export const offlineConfig = _.once(
  (): AppConfig =>
    _.merge({}, developmentConfig(), {
      appEnv: 'offline',
      devMode: 'offline',
      api: {
        baseUrls: {
          default: 'offline://'
        }
      }
    })
);
