import { demoButtonRoute } from './demo/demoButtonRoute';
import { demoCrossFadeRoute } from './demo/demoCrossFadeRoute';
import { demoLocalizationRoute } from './demo/demoLocalizationRoute';
import { demoMessageModalRoute } from './demo/demoMessageModalRoute';
import { demoSpinnerRoute } from './demo/demoSpinnerRoute';

export const demoRoutes = {
  demoButton: demoButtonRoute,
  demoCrossFade: demoCrossFadeRoute,
  demoLocalization: demoLocalizationRoute,
  demoMessageModal: demoMessageModalRoute,
  demoSpinner: demoSpinnerRoute
};
