import type { ResourceKey } from 'i18next';

import { getConfig } from '../config/getConfig';
import demo from '../dev/screens/demo/localization/importers';
import { inline } from '../utils/inline';

const supportedNamespaces: Partial<
  Record<string, Partial<Record<string, () => Promise<Record<string, ResourceKey | undefined> | undefined>>>>
> = { demo };

const promises: Partial<Record<string, Promise<ResourceKey | undefined> | ResourceKey>> = {};

export const loadNamespaceForLanguage = async (lng: string, ns: string): Promise<ResourceKey | undefined> => {
  const promisesKey = `${lng}::${ns}`;
  let promise = promises[promisesKey];
  if (promise !== undefined) {
    return promise;
  }

  getConfig().loggers.localization.debug?.(`Loading namespace: ${ns} for: ${lng}`);
  promise = inline(async (): Promise<ResourceKey> => {
    const loaded = await supportedNamespaces[ns]?.[lng]?.();
    return loaded?.[ns] ?? {};
  });
  promises[promisesKey] = promise;
  return promise;
};
