import { makeStringSubtypeArray, schema } from 'yaschema';

export const personaPermissionsCategories = makeStringSubtypeArray(
  'view',
  /** Make and commit general changes */
  'edit',
  'change-access-requirements',
  'delete',
  /** Can act as this persona as an author */
  'author-as'
);
export const personaPermissionCategorySchema = schema.string(...personaPermissionsCategories);
export type PersonaPermissionsCategory = typeof personaPermissionCategorySchema.valueType;

// export const personaAccessStatuses = makeStringSubtypeArray('deleted', 'owner', 'admin', 'editor', 'user', 'viewer');
