import { ListItemAccessory } from './ListItemAccessory';
import { ListItemDetail } from './ListItemDetail';
import { ListItemIcon } from './ListItemIcon';
import { ListItemSubtitle } from './ListItemSubtitle';
import { ListItemTitle } from './ListItemTitle';
import { ListItemTitles } from './ListItemTitles';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace li {
  export const Accessory = ListItemAccessory;
  export const Detail = ListItemDetail;
  export const Icon = ListItemIcon;
  export const Subtitle = ListItemSubtitle;
  export const Title = ListItemTitle;
  export const Titles = ListItemTitles;
}
