import { StatusCodes } from 'http-status-codes';
import { authApi } from 'linefeedr-auth-schema';
import { registry as cacheRegistry } from 'linefeedr-cache';
import { apiFetch } from 'yaschema-api-fetcher';

import { ONE_SEC_MSEC } from '../../consts/time';
import { makeTask } from '../../makers/makeTask';
import type { AppTaskContext } from '../../types/AppTaskContext';
import type { BasicHttpTaskFailure } from '../../types/BasicHttpTaskFailure';

export const signOut = makeTask<AppTaskContext, [], undefined, BasicHttpTaskFailure>({
  id: 'signOut',
  run: async () => {
    const res = await apiFetch(
      authApi.auth.DEL,
      {},
      {
        shouldRetry: async ({ retryCount, res }) => {
          // This should always be retried, except if status is StatusCodes.UNAUTHORIZED, which is a success state that indicates the user
          // isn't signed in
          if (res?.status === StatusCodes.UNAUTHORIZED) {
            return false;
          } else {
            return { afterDelayMSec: Math.min(retryCount * 300, 30 * ONE_SEC_MSEC) };
          }
        }
      }
    );

    await cacheRegistry.clearAllCaches();

    if (!res.ok) {
      // The only case that should reach here is if status is UNAUTHORIZED
      return { ok: true, value: undefined };
    }

    return { ok: true, value: undefined };
  },
  ifOfflineDev: { ok: true, value: undefined }
});
