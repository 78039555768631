import type { ResizeDetectorProps } from 'react-resize-detector';
import ResizeDetector, { useResizeDetector } from 'react-resize-detector';

import { useDomRenderingMode } from '../../context/dom-rendering';

export const DomResizeDetector = <ElementT extends HTMLElement = HTMLElement>(props: ResizeDetectorProps<ElementT>) => {
  const mode = useDomRenderingMode();

  const propsOverrides: ResizeDetectorProps<ElementT> = {};
  switch (mode) {
    case 'no-render':
      propsOverrides.handleWidth = false;
      propsOverrides.handleHeight = false;
      break;
    case 'render':
      // Nothing special to do
      break;
  }

  if (props.targetRef !== undefined) {
    return <DomResizeConnector {...props} {...propsOverrides} />;
  } else {
    return <ResizeDetector {...props} {...propsOverrides} />;
  }
};

// Helpers

const DomResizeConnector = <ElementT extends HTMLElement = HTMLElement>(props: ResizeDetectorProps<ElementT>) => {
  const { width, height } = useResizeDetector({
    targetRef: props.targetRef,
    handleHeight: props.handleHeight,
    handleWidth: props.handleWidth
  });
  props.onResize?.(width, height);

  return null;
};
