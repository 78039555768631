import { createContext, useContext } from 'react';

import type { ChildrenProps } from '../../types/ChildrenProps';
import { assert } from '../../utils/assert';
import type { ScreenNavigation } from './types/ScreenNavigation';

const NavigationContext = createContext<ScreenNavigation | undefined>(undefined);

/** A NavigationProvider must be used */
export const useNavigation = (): ScreenNavigation => {
  const output = useContext(NavigationContext);
  assert(output !== undefined, 'NavigationProvider must be used with useNavigation');
  return output;
};

export const NavigationProvider = ({ children, navigation }: ChildrenProps & { navigation: ScreenNavigation }) => (
  <NavigationContext.Provider value={navigation}>{children}</NavigationContext.Provider>
);
