import { isBinding, makeBinding } from 'react-bindings';

import type { PushableToolbarItems, ToolbarItems } from './types/ToolbarItems';

export const makeToolbarItems = ({ leftItems, centerItems, rightItems }: PushableToolbarItems): ToolbarItems => ({
  leftItems: isBinding(leftItems)
    ? leftItems
    : makeBinding(() => leftItems, {
        id: 'toolbarItems.leftItems',
        detectChanges: true,
        areEqual: (a, b) => a === b
      }),
  centerItems: isBinding(centerItems)
    ? centerItems
    : makeBinding(() => centerItems, {
        id: 'toolbarItems.centerItems',
        detectChanges: true,
        areEqual: (a, b) => a === b
      }),
  rightItems: isBinding(rightItems)
    ? rightItems
    : makeBinding(() => rightItems, {
        id: 'toolbarItems.rightItems',
        detectChanges: true,
        areEqual: (a, b) => a === b
      })
});
