import type { TypographyContextProviderProps } from '../../context/typography';
import { TypographyContextProvider } from '../../context/typography';
import type { RealReactNode } from '../../types/RealReactNode';
import type { BasicTextProps, TextProps } from '../core/Text';
import { Text } from '../core/Text';

export const typographyOrContext = (
  value: RealReactNode | string,
  props?: BasicTextProps,
  { typographyProps, contextProps }: { typographyProps?: TextProps; contextProps?: TypographyContextProviderProps } = {}
) =>
  typeof value === 'string' ? (
    <Text {...props} {...typographyProps}>
      {value}
    </Text>
  ) : (
    <TypographyContextProvider {...props} {...contextProps}>
      {value}
    </TypographyContextProvider>
  );
