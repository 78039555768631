import type { CSSProperties } from 'react';

import { sp } from '../../consts/layout';
import type { ChildrenProps, StringChildrenProps } from '../../types/ChildrenProps';
import type { ColorVariant } from '../../types/ColorVariant';
import { typographyOrContext } from '../aliases/typographyOrContext';
import { SafeSidesRow } from '../layout/SafeSidesRow';

export interface ListHeaderProps {
  /** @defaultValue `'textPrimary'` when variant is `1`, `'textSecondary'` when variant is `2` */
  color?: ColorVariant;
  /** @defaultValue `1` */
  variant?: 1 | 2;
  style?: CSSProperties;
}

export const ListHeader = ({ children, variant = 1, color, style }: (ChildrenProps | StringChildrenProps) & ListHeaderProps) => (
  <SafeSidesRow needsSafeSides="rl" alignItems="center" style={style !== undefined ? { ...mainStyle, ...style } : mainStyle}>
    {typographyOrContext(children, { variant: `listHeader${variant}`, color: color ?? defaultColorByVariant[`${variant}`] })}
  </SafeSidesRow>
);

// Helpers

const mainStyle: Omit<CSSProperties, 'padding'> = {
  paddingTop: sp(1),
  paddingRight: sp(2),
  paddingBottom: sp(1),
  paddingLeft: sp(2)
};

const defaultColorByVariant: Record<'1' | '2', ColorVariant> = {
  '1': 'textPrimary',
  '2': 'textSecondary'
};
