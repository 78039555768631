import { createContext, useContext } from 'react';

import type { ChildrenProps } from '../../types/ChildrenProps';
import type { DocumentNavigation } from './types/DocumentNavigation';

const DocumentNavigationContext = createContext<DocumentNavigation | undefined>(undefined);

export const useDocumentNavigation = () => useContext(DocumentNavigationContext);

export const DocumentNavigationProvider = ({ children, navigation }: ChildrenProps & { navigation: DocumentNavigation }) => (
  <DocumentNavigationContext.Provider value={navigation}>{children}</DocumentNavigationContext.Provider>
);
