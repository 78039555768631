import { makeStringSubtypeArray } from 'yaschema';

export type Sides = `${'t' | ''}${'r' | ''}${'b' | ''}${'l' | ''}`;

export const sideNames = makeStringSubtypeArray('top', 'right', 'bottom', 'left');
export type SideName = (typeof sideNames)[0];

export const extractSides = (sides: Sides): Record<SideName, boolean> => ({
  top: withTop.has(sides),
  right: withRight.has(sides),
  bottom: withBottom.has(sides),
  left: withLeft.has(sides)
});

// Helpers

const withTop = new Set<Sides>(['t', 'tb', 'tbl', 'tl', 'tr', 'trb', 'trbl', 'trl']);
const withRight = new Set<Sides>(['r', 'rb', 'rbl', 'rl', 'tr', 'trb', 'trbl', 'trl']);
const withBottom = new Set<Sides>(['b', 'bl', 'rb', 'rbl', 'tb', 'tbl', 'trb', 'trbl']);
const withLeft = new Set<Sides>(['bl', 'l', 'rbl', 'rl', 'tbl', 'tl', 'trbl', 'trl']);
