import type { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

import { sp } from './consts/layout';
import { palette, resolveColorVariant } from './consts/palette';

export const monospacedStyle: CSSProperties = {
  fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace"
};

export const useGlobalStyles = createUseStyles({
  '@global': {
    body: {
      margin: '0',
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      fontSize: '0',
      webkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      overflow: 'hidden',
      position: 'fixed',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'stretch'
    },
    '.hideScrollIndicators': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        display: 'none',
        width: 0,
        height: 0
      }
    },
    '#root': {
      flexGrow: '1',
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'stretch'
    },
    a: {
      textDecoration: 'none'
    },
    code: monospacedStyle,
    'a, div, button, input, select, textarea': {
      boxSizing: 'border-box',
      position: 'relative'
    },
    hr: {
      backgroundColor: resolveColorVariant(palette.divider),
      border: 'none',
      flex: 1,
      flexBasis: '1px',
      margin: `${sp(2)} 0`,
      height: '1px',
      '&.inset': {
        margin: sp(2)
      }
    },
    div: {
      '&.VirtualizedColElem-render': {
        position: 'absolute',
        width: '100%'
      },
      '&.VirtualizedColElem-no-render': {
        display: 'none'
      }
    }
  }
});
