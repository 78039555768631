import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas, makePaginatedSchema } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { userPersonaInfoSchema } from '../../types/api-data/persona/UserPersonaInfo';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'user',
  credentials: 'include',
  url: '/user/personas',
  schemas: {
    request: {},
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        personas: makePaginatedSchema(userPersonaInfoSchema)
      })
    },
    failureResponse: genericFailureSchemas
  }
});
