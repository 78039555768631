import isPromise from 'is-promise';
import { noop } from 'lodash';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import type { Binding, ReadonlyBinding, TypeOrDeferredType } from 'react-bindings';
import { makeBinding, resolveTypeOrDeferredType, useBinding, useBindingEffect, useCallbackRef, useDerivedBinding } from 'react-bindings';

import type { ChildrenProps } from '../types/ChildrenProps';
import { inline } from '../utils/inline';
import { useIncSsrBusyCount } from './ssr';

const ScreenLockerContext = createContext<Binding<number>>(makeBinding(() => 0, { id: 'defaultScreenLocker' }));

export const useIsScreenLocked = () => {
  const screenLocker = useContext(ScreenLockerContext);

  return useDerivedBinding(screenLocker, () => (screenLocker?.get() ?? 0) > 0, { id: 'isScreenLocked' });
};

export const ScreenLockerProvider = ({ children }: ChildrenProps) => {
  const parentCount = useContext(ScreenLockerContext);
  const count = useBinding(() => 0, { id: 'screenLocker' });

  return (
    <ScreenLockerContext.Provider value={count}>
      <ParentCountConnector parentCount={parentCount} />
      {children}
    </ScreenLockerContext.Provider>
  );
};

export const useScreenLocker = <T,>() => {
  const screenLocker = useContext(ScreenLockerContext);
  const incSsrBusyCount = useIncSsrBusyCount();

  const lockScreen = useCallbackRef(() => {
    screenLocker.set(screenLocker.get() + 1);
    const decSsrBusyCount = incSsrBusyCount?.();

    let alreadyUnlocked = false;
    return () => {
      if (alreadyUnlocked) {
        return;
      }
      alreadyUnlocked = true;

      screenLocker.set(screenLocker.get() - 1);
      decSsrBusyCount?.();
    };
  });

  const lockScreenUntilComplete = useCallbackRef((value: TypeOrDeferredType<T>): T => {
    const unlock1 = lockScreen();
    try {
      const resolvedValue = resolveTypeOrDeferredType(value);

      if (isPromise(resolvedValue)) {
        return inline(async () => {
          const unlock2 = lockScreen();
          try {
            return await resolvedValue;
          } finally {
            setTimeout(unlock2, 0);
          }
        }) as T;
      } else {
        return resolvedValue;
      }
    } finally {
      setTimeout(unlock1, 0);
    }
  });

  return useMemo(() => ({ lockScreen, lockScreenUntilComplete }), [lockScreen, lockScreenUntilComplete]);
};

// Helpers

const ParentCountConnector = ({ parentCount }: { parentCount: ReadonlyBinding<number> }) => {
  const { lockScreen } = useScreenLocker();

  const lastUnlock = useRef<() => void>(noop);
  useBindingEffect(
    parentCount,
    (parentCount) => {
      lastUnlock.current();
      lastUnlock.current = noop;
      if (parentCount > 0) {
        lastUnlock.current = lockScreen();
      }
    },
    { triggerOnMount: true, limitType: 'none' }
  );

  useEffect(() => {
    return () => {
      lastUnlock.current();
      lastUnlock.current = noop;
    };
  });

  return null;
};
