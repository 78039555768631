import { schema } from 'yaschema';

import { articleInfoSchema } from './ArticleInfo';

export const featureArticleSchema = schema.object({
  type: schema.string('featured'),
  article: articleInfoSchema
});

export const sideBySideFeatureArticleSchema = schema.object({
  type: schema.string('side-by-side-featured'),
  articles: schema.array({ items: articleInfoSchema })
});

export const organizedArticleInfosSchema = schema.oneOf(featureArticleSchema, sideBySideFeatureArticleSchema);
export type OrganizedArticleInfos = typeof organizedArticleInfosSchema.valueType;
