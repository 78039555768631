import type { EmptyObject } from 'react-bindings';

import type { SignUpScreenArgs } from '../../screens/SignUpScreen';
import { makeRouteInfo } from '../makeRouteInfo';

export const signUpRoute = makeRouteInfo<EmptyObject, [SignUpScreenArgs] | []>({
  path: ['sign-up'],
  pathSchemas: {},
  screen: async (_context, _params, args = {}) => (await import('../../screens/SignUpScreen')).SignUpScreen(args)
});
