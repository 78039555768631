import { schema } from 'yaschema';

import { articleCodeParagraphSchema } from './ArticleCodeParagraph';
import { articleHeadingParagraphSchema } from './ArticleHeadingParagraph';
import { articleImageParagraphSchema } from './ArticleImageParagraph';
import { articleQuoteParagraphSchema } from './ArticleQuoteParagraph';
import { articleTextParagraphSchema } from './ArticleTextParagraph';

export const articleParagraphSchema = schema.oneOf5(
  articleTextParagraphSchema,
  articleHeadingParagraphSchema,
  articleImageParagraphSchema,
  articleQuoteParagraphSchema,
  articleCodeParagraphSchema
);

export type ArticleParagraph = typeof articleParagraphSchema.valueType;
