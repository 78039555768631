import { schema } from 'yaschema';

import { MAX_PERSONA_LINKS } from '../../../consts/limits';
import { richTextSchema } from '../RichText';
import { contactInfoSchema } from './ContactInfo';
import { personaGeneratedMetaSchema } from './PersonaGeneratedMeta';
import { personaInfoSchema } from './PersonaInfo';
import { personaLinkSchema } from './PersonaLink';

export const personaDetailsExtrasSchema = schema.object({
  /** Max length should be applied to the concatenated text.  We check the lengths of the individual parts against the max, but the
   * total length will have to be validated separately from yaschema */
  bio: richTextSchema.optional(),
  links: schema.array({ items: personaLinkSchema, maxLength: MAX_PERSONA_LINKS }),
  contactInfo: contactInfoSchema.optional()
});
export type PersonaDetailsExtras = typeof personaDetailsExtrasSchema.valueType;

export const mutablePersonaDetailsSchema = schema.allOf(personaInfoSchema, personaDetailsExtrasSchema);
export type MutablePersonaDetails = typeof mutablePersonaDetailsSchema.valueType;

export const personaDetailsSchema = schema.object({
  details: mutablePersonaDetailsSchema,
  meta: personaGeneratedMetaSchema,
  score: schema.number().optional()
});
export type PersonaDetails = typeof personaDetailsSchema.valueType;
