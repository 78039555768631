import { schema } from 'yaschema';

import { totpAuthenticatorIdentifierSchema } from '../identifiers/TotpAuthenticatorIdentifier';
import { totpAuthenticatorNameSchema } from './TotpAuthenticatorName';

export const totpHeaderSchema = schema.object({
  totpId: totpAuthenticatorIdentifierSchema,
  name: totpAuthenticatorNameSchema,
  isActive: schema.boolean()
});
export type TotpHeader = typeof totpHeaderSchema.valueType;
