import { type ComponentType, useMemo } from 'react';
import type { Binding } from 'react-bindings';
import { useBinding, useCallbackRef, useStableValue } from 'react-bindings';

import { makeNavigationBarItems } from '../context/navigation/makeNavigationBarItems';
import { makeToolbarItems } from '../context/navigation/makeToolbarItems';
import type { NavDocument, NavDocumentHook, NavDocumentProps } from '../context/navigation/types/NavDocument';
import type { NavigationBarItems } from '../context/navigation/types/NavigationBarItems';
import type { NavigationBarStyle } from '../context/navigation/types/NavigationBarStyle';
import type { ToolbarItems } from '../context/navigation/types/ToolbarItems';
import type { ToolbarStyle } from '../context/navigation/types/ToolbarStyle';
import type { RealReactNode } from '../types/RealReactNode';

export interface MakeNavDocumentArgs<ArgsT extends any[]> {
  useTitle: (...args: ArgsT) => RealReactNode | string | Binding<RealReactNode | string>;
  /** @defaultValue `'sticky'` */
  navigationBarStyle?: NavigationBarStyle;
  /** @defaultValue `'hidden'` */
  toolbarStyle?: ToolbarStyle;
  Body: ComponentType<{
    navigationBarItems: NavigationBarItems;
    navigationBarStyle: Binding<NavigationBarStyle>;
    toolbarItems: ToolbarItems;
    toolbarStyle: Binding<ToolbarStyle>;
    args: ArgsT;
  }>;
}

export const makeNavDocument =
  <ArgsT extends any[] = []>(useNavDocument: (...args: ArgsT) => MakeNavDocumentArgs<ArgsT>) =>
  (...args: ArgsT): NavDocumentHook =>
  (): NavDocument => {
    const { useTitle, navigationBarStyle = 'sticky', toolbarStyle = 'hidden', Body } = useNavDocument(...args);

    const navBarTitle = useTitle(...args);
    const navigationBarItems = useMemo(() => makeNavigationBarItems({ title: navBarTitle }), [navBarTitle]);
    const toolbarItems = useMemo(() => makeToolbarItems({}), []);

    const navigationBarStyleBinding = useBinding(() => navigationBarStyle, { id: 'navigationBarStyle', detectChanges: true });
    const toolbarStyleBinding = useBinding(() => toolbarStyle, { id: 'toolbarStyle', detectChanges: true });

    const WrappedBody: ComponentType<NavDocumentProps> = useCallbackRef((props) => <Body {...props} args={args} />);

    return useStableValue({
      navigationBarItems,
      navigationBarStyle: navigationBarStyleBinding,
      toolbarItems,
      toolbarStyle: toolbarStyleBinding,
      Body: WrappedBody
    });
  };
