import _ from 'lodash';
import { schema } from 'yaschema';

import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../../consts/limits';

/** All of the non-alphanumeric symbols supports by passwords */
export const allowedPasswordSymbols = [
  '-',
  '~',
  '`',
  '!',
  ' ',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '+',
  '=',
  '{',
  '}',
  '[',
  ']',
  '|',
  '\\',
  ':',
  ';',
  '"',
  "'",
  '<',
  '>',
  ',',
  '.',
  '?',
  '/'
];
const allowedPasswordCharPattern = `[A-Za-z0-9${allowedPasswordSymbols
  .map((symbol) => (symbol === '-' ? '\\-' : _.escapeRegExp(symbol)))
  .join('')}]`;

export const passwordPrefix = '$P';
export const passwordSchema = schema
  .regex<`${typeof passwordPrefix}${string}`>(new RegExp(`^${_.escapeRegExp(passwordPrefix)}${allowedPasswordCharPattern}+$`))
  .setAllowedLengthRange(passwordPrefix.length + MIN_PASSWORD_LENGTH, passwordPrefix.length + MAX_PASSWORD_LENGTH);
export type Password = typeof passwordSchema.valueType;

export const nonPrefixedPasswordSchema = schema
  .regex(new RegExp(`^${allowedPasswordCharPattern}+$`))
  .setAllowedLengthRange(MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH);

export const makePassword = (nonPrefixedValue: string): Password => `${passwordPrefix}${nonPrefixedValue}`;
