import isPromise from 'is-promise';
import { useMemo } from 'react';

import { SCREEN_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import type { IHistory } from '../../history/types/IHistory';
import type { ResolvedRouteInfo } from '../../routing/types/ResolvedRouteInfo';
import type { AnyRoutes } from '../../routing/types/Routes';
import { useScreenLocker } from '../screen-locker';
import { useAppTaskContext } from '../useAppTaskContext';
import type { ScreenNavigation } from './types/ScreenNavigation';
import { useCreateDocumentNavigation } from './useCreateDocumentNavigation';

export const useCreateScreenNavigation = <RoutesT extends AnyRoutes>({
  history,
  routes
}: {
  history: IHistory;
  routes: RoutesT;
}): ScreenNavigation => {
  const context = useAppTaskContext();
  const { lockScreen, lockScreenUntilComplete } = useScreenLocker();

  const main = useCreateDocumentNavigation();

  return useMemo((): ScreenNavigation => {
    // When the site is first loaded
    lockScreenUntilComplete(async () => {
      const historyTopChangeUid = history.top.getChangeUid();
      const historyTop = history.top.get();
      // Using search and hash from browser location rather than history for the first page
      const { path, defaultHistory, fixedHistory, useScreen } = await routes.find(context, historyTop.path);
      if (history.top.getChangeUid() !== historyTopChangeUid) {
        return; //  We no longer care about this result
      }

      if (history.isEmpty.get() && defaultHistory !== undefined) {
        // Using default history

        history.batch(() => {
          history.clear();
          for (const item of defaultHistory) {
            history.push(item.path, { search: item.search, hash: item.hash });
          }
          history.push(path, { search: historyTop.search, hash: historyTop.hash });
        });
      } else if (fixedHistory !== undefined) {
        // Using fixed history

        history.batch(() => {
          history.clear();
          for (const item of fixedHistory) {
            history.push(item.path, { search: item.search, hash: item.hash });
          }
          history.push(path, { search: historyTop.search, hash: historyTop.hash });
        });
      } else {
        // Using dynamic history

        history.replace(path, { search: historyTop.search, hash: historyTop.hash });
      }

      // eslint-disable-next-line react-hooks/rules-of-hooks
      main.transition(() => useScreen().main, {
        id: `main-${path}`,
        transitionMode: 'continue',
        transitionDurationMSec: 0
      });
    });

    return {
      history,
      main,
      canPop: history.canPop,
      pop: (options = {}) => {
        if (!history.canPop.get()) {
          return; // Nothing to do
        }

        history.pop();

        const historyTopChangeUid = history.top.getChangeUid();
        const historyTop = history.top.get();

        lockScreenUntilComplete(async () => {
          // Using search and hash from browser location rather than history for the first page
          console.log('finding screen', historyTop.path);
          const { path, search, hash, fixedHistory, useScreen } = await routes.find(context, historyTop.path);
          if (history.top.getChangeUid() !== historyTopChangeUid) {
            return; // We no longer care about this result
          }

          console.log('found screen', historyTop.path, fixedHistory);
          if (fixedHistory !== undefined) {
            // Using fixed history

            history.batch(() => {
              history.clear();
              for (const item of fixedHistory) {
                history.push(item.path, { search: item.search, hash: item.hash });
              }
              history.push(path, { search, hash });
            });
          }

          const transitionDurationMSec =
            options.main?.transitionDurationMSec ?? options.transitionDurationMSec ?? SCREEN_LEVEL_TRANSITION_DURATION_MSEC;

          const timerUnlock = lockScreen();
          try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            main.transition(() => useScreen().main, {
              id: `main-${path}`,
              transitionMode: options.main?.transitionMode ?? options.transitionMode ?? 'pop',
              transitionDurationMSec
            });
          } finally {
            setTimeout(timerUnlock, transitionDurationMSec);
          }
        });
      },
      push: (routeInfoResolver, options = {}) => {
        const onRouteInfoResolved = ({ path, search, hash, fixedHistory, useScreen }: ResolvedRouteInfo) => {
          if (fixedHistory !== undefined) {
            // Using fixed history

            history.batch(() => {
              history.clear();
              for (const item of fixedHistory) {
                history.push(item.path, { search: item.search, hash: item.hash });
              }
              history.push(path, { search, hash });
            });
          } else {
            // Using dynamic history

            history.push(path, { search: options.search ?? search, hash: options.hash ?? hash });
          }

          const transitionDurationMSec =
            options.main?.transitionDurationMSec ?? options.transitionDurationMSec ?? SCREEN_LEVEL_TRANSITION_DURATION_MSEC;

          const timerUnlock = lockScreen();
          try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            main.transition(() => useScreen().main, {
              id: `main-${path}`,
              transitionMode: options.main?.transitionMode ?? options.transitionMode ?? 'push',
              transitionDurationMSec
            });
          } finally {
            setTimeout(timerUnlock, transitionDurationMSec);
          }
        };

        const resolved = routeInfoResolver();
        if (isPromise(resolved)) {
          lockScreenUntilComplete(async () => onRouteInfoResolved(await resolved));
        } else {
          onRouteInfoResolved(resolved);
        }
      },
      replace: (routeInfoResolver, options = {}) => {
        const onRouteInfoResolved = ({ path, search, hash, fixedHistory, useScreen }: ResolvedRouteInfo) => {
          if (fixedHistory !== undefined) {
            // Using fixed history

            history.batch(() => {
              history.clear();
              for (const item of fixedHistory) {
                history.push(item.path, { search: item.search, hash: item.hash });
              }
              history.push(path, { search, hash });
            });
          } else {
            // Using dynamic history

            history.replace(path, { search: options.search ?? search, hash: options.hash ?? hash });
          }

          const transitionDurationMSec =
            options.main?.transitionDurationMSec ?? options.transitionDurationMSec ?? SCREEN_LEVEL_TRANSITION_DURATION_MSEC;

          const timerUnlock = lockScreen();
          try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            main.transition(() => useScreen().main, {
              id: `main-${path}`,
              transitionMode: options.main?.transitionMode ?? options.transitionMode ?? 'push',
              transitionDurationMSec
            });
          } finally {
            setTimeout(timerUnlock, transitionDurationMSec);
          }
        };

        const resolved = routeInfoResolver();
        if (isPromise(resolved)) {
          lockScreenUntilComplete(async () => onRouteInfoResolved(await resolved));
        } else {
          onRouteInfoResolved(resolved);
        }
      }
    };
  }, [context, history, lockScreen, lockScreenUntilComplete, main, routes]);
};
