import type { Logger } from 'react-bindings';
import { makeStringSubtypeArray } from 'yaschema';

export const appEnvironments = makeStringSubtypeArray('offline', 'local', 'development', 'production');
export type AppEnvironment = (typeof appEnvironments)[0];

export interface AppConfig {
  appEnv: AppEnvironment;
  devMode: boolean | 'offline';

  loggers: {
    default: Logger;
    localization: Logger;
    navigation: Logger;
    tasks: Logger;
  };

  api: {
    baseUrls: {
      default: string;
      auth?: string;
    };
  };

  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}
