import type { ChildrenProps } from '../../types/ChildrenProps';
import type { ColProps } from '../layout/Col';
import { Col } from '../layout/Col';

export type ListItemTitlesProps = ColProps;

export const ListItemTitles = ({ children, style, ...props }: ChildrenProps & ListItemTitlesProps) => (
  <Col style={{ flexGrow: 1, ...style }} {...props}>
    {children}
  </Col>
);
