import { schema } from 'yaschema';

import { MAX_PERSONA_ACCESS_REQUIREMENT_GROUPS } from '../../../consts/limits';
import { groupIdentifierSchema } from '../identifiers/GroupIdentifier';

export const personaAccessRequirementsSchema = schema.object({
  autoApproveFollowRequests: schema.oneOf(
    schema.string('public'),
    schema.object({
      groupIds: schema.array({ items: groupIdentifierSchema, minLength: 1, maxLength: MAX_PERSONA_ACCESS_REQUIREMENT_GROUPS })
    })
  ),
  viewDetails: schema.oneOf(
    schema.string('public'),
    schema.object({
      groupIds: schema.array({ items: groupIdentifierSchema, minLength: 1, maxLength: MAX_PERSONA_ACCESS_REQUIREMENT_GROUPS })
    })
  )
});
export type PersonaAccessRequirements = typeof personaAccessRequirementsSchema.valueType;
