/* eslint-disable */
import svg_black from './black.svg';
import svg_white from './white.svg';

export const imageIconsFieldX = {
  isVariantImageSource: true as const,
  widthPx: 24,
  heightPx: 24,
  svgs: { black: svg_black, white: svg_white }
};
