import { schema } from 'yaschema';

import { groupIdentifierSchema } from '../identifiers/GroupIdentifier';
import { personaIdentifierSchema } from '../identifiers/PersonaIdentifier';
import { articlePermissionReasonSchema } from './ArticlePermissionReason';
import { articlePermissionsSchema } from './ArticlePermissions';

export const articlePermissionContextSchema = schema.object({
  permissions: articlePermissionsSchema,
  reason: articlePermissionReasonSchema,
  /** Only used if the reason pertains to a group */
  groupId: groupIdentifierSchema.optional(),
  /** Only used if the reason pertains to a specific persona */
  personaId: personaIdentifierSchema.optional()
});
export type ArticlePermissionContext = typeof articlePermissionContextSchema.valueType;
