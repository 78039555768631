import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { articleIdentifierSchema } from '../../../../types/api-data/identifiers/ArticleIdentifier';
import { articleVersionIdentifierSchema } from '../../../../types/api-data/identifiers/ArticleVersionIdentifier';

export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'content',
  credentials: 'include',
  url: '/article/{id}/draft/{versionId}',
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema,
      params: schema.object({
        id: articleIdentifierSchema,
        versionId: articleVersionIdentifierSchema
      }),
      body: schema.object({
        resolution: schema.string('abandon', 'commit')
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema
    },
    failureResponse: genericFailureSchemas
  }
});
