import type { Schema } from 'yaschema';
import { schema } from 'yaschema';

import { uuidStringSchema } from './UuidString';

export const makePaginatedSchema = <T>(itemSchema: Schema<T>) =>
  schema.object({
    items: schema.array({ items: itemSchema }),
    moreResultsToken: uuidStringSchema.optional()
  });

export interface Paginated<T> {
  items: T[];
  moreResultsToken?: string;
}
