import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { MAX_IMAGE_DIMENSION_PX, MIN_IMAGE_DIMENSION_PX } from '../../consts/images';
import { userImageIdentifierSchema } from '../../types/api-data/identifiers/UserImageIdentifier';
import { supportedImageTypeSchema } from '../../types/api-data/image/SupportedImageType';

export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'content',
  credentials: 'include',
  url: '/image',
  requestType: 'form-data',
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        mimeType: supportedImageTypeSchema,
        widthPx: schema.restrictedNumber([{ min: MIN_IMAGE_DIMENSION_PX, max: MAX_IMAGE_DIMENSION_PX }], { divisibleBy: [1] }),
        heightPx: schema.restrictedNumber([{ min: MIN_IMAGE_DIMENSION_PX, max: MAX_IMAGE_DIMENSION_PX }], { divisibleBy: [1] }),
        imageFile: schema.any().optional()
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        imageId: userImageIdentifierSchema
      })
    },
    failureResponse: genericFailureSchemas
  }
});
