import type { EmptyObject } from 'react-bindings';

import { makeRouteInfo } from '../makeRouteInfo';
import { todayRoute } from './todayRoute';

export const newArticleRoute = makeRouteInfo<EmptyObject, []>({
  path: ['article', 'new'],
  pathSchemas: {},
  defaultHistory: (context) => [todayRoute(context, {})],
  screen: async () => (await import('../../screens/EditArticleScreen')).EditArticleScreen({ articleId: undefined })
});
