import { schema } from 'yaschema';

import { articleDetailsSchema } from './ArticleDetails';
import { articleGeneratedMetaSchema } from './ArticleGeneratedMeta';

export const articleSchema = schema.object({
  details: articleDetailsSchema,
  meta: articleGeneratedMetaSchema,
  score: schema.number().optional()
});
export type Article = typeof articleSchema.valueType;
