import { demoRoutes } from '../dev/routes/demoRoutes';
import { makeRouteFinder } from './makeRouteFinder';
import { accountRoute } from './routes/accountRoute';
import { articlePreviewRoute } from './routes/articlePreviewRoute';
import { articleRoute } from './routes/articleRoute';
import { editArticleRoute } from './routes/editArticleRoute';
import { editPersonaRoute } from './routes/editPersonaRoute';
import { mfaRoute } from './routes/mfaRoute';
import { newArticleRoute } from './routes/newArticleRoute';
import { rootRoute } from './routes/rootRoute';
import { signInRoute } from './routes/signInRoute';
import { signOutRoute } from './routes/signOutRoute';
import { signUpRoute } from './routes/signUpRoute';
import { todayRoute } from './routes/todayRoute';
import type { AnyRoutes } from './types/Routes';

const routes = {
  account: accountRoute,
  article: articleRoute,
  articlePreview: articlePreviewRoute,
  today: todayRoute,
  mfa: mfaRoute,
  editArticle: editArticleRoute,
  editPersona: editPersonaRoute,
  newArticle: newArticleRoute,
  signIn: signInRoute,
  signOut: signOutRoute,
  signUp: signUpRoute,
  ...demoRoutes
};

export const rootRoutes = {
  routes,
  defaultRoute: rootRoute,
  find: makeRouteFinder(routes, { default: rootRoute })
} satisfies AnyRoutes;
