import { VirtualizedCol } from '../../../components/layout/VirtualizedCol';
import { ListHeader } from '../../../components/list/ListHeader';
import { ListItem } from '../../../components/list/ListItem';
import { li } from '../../../components/list/namespace';
import { getConfig } from '../../../config/getConfig';
import { makeStatelessComponent } from '../../../makers/makeStatelessComponent';
import { commit, version } from '../../../version';

export const DevModeEnvironmentInfoSection = makeStatelessComponent(() => (
  <VirtualizedCol>
    <ListHeader>Environment Info</ListHeader>
    <ListItem>
      <li.Title color="textSecondary">Name</li.Title>
      <li.Detail>{getConfig().appEnv}</li.Detail>
    </ListItem>
    <ListItem>
      <li.Title color="textSecondary">Version</li.Title>
      <li.Detail>{version}</li.Detail>
    </ListItem>
    <ListItem>
      <li.Title color="textSecondary">Commit</li.Title>
      <li.Detail>{commit.substring(0, 7)}</li.Detail>
    </ListItem>
  </VirtualizedCol>
));
