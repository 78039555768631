import { schema } from 'yaschema';

import { MAX_PERSONA_AFFILIATION_LENGTH, MAX_PERSONA_NAME_LENGTH, MAX_PERSONA_ROLE_LENGTH } from '../../../consts/limits';

export const personaInfoSchema = schema.object({
  name: schema.string().setAllowedLengthRange(1, MAX_PERSONA_NAME_LENGTH),
  profileImageBaseUrl: schema.string(),
  role: schema.string().setAllowedLengthRange(1, MAX_PERSONA_ROLE_LENGTH).optional(),
  affiliation: schema.string().setAllowedLengthRange(1, MAX_PERSONA_AFFILIATION_LENGTH).optional()
});

export type PersonaInfo = typeof personaInfoSchema.valueType;
