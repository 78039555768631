import { isBinding, makeBinding } from 'react-bindings';

import type { NavigationBarItems, PushableNavigationBarItems } from './types/NavigationBarItems';

export const makeNavigationBarItems = ({ title, leftItems, rightItems }: PushableNavigationBarItems): NavigationBarItems => ({
  title: isBinding(title)
    ? title
    : makeBinding(() => title, {
        id: 'navigationBarItems.title',
        detectChanges: true,
        areEqual: (a, b) => a === b
      }),
  leftItems: isBinding(leftItems)
    ? leftItems
    : makeBinding(() => leftItems, {
        id: 'navigationBarItems.leftItems',
        detectChanges: true,
        areEqual: (a, b) => a === b
      }),
  rightItems: isBinding(rightItems)
    ? rightItems
    : makeBinding(() => rightItems, {
        id: 'navigationBarItems.rightItems',
        detectChanges: true,
        areEqual: (a, b) => a === b
      })
});
