import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas, makePaginatedSchema, uuidStringSchema } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { organizedArticleInfosSchema } from '../../types/api-data/article/OrganizedArticleInfos';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'content',
  credentials: 'include',
  url: '/today',
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema,
      query: schema.object({
        resultsToken: uuidStringSchema.optional()
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        organizedArticles: makePaginatedSchema(organizedArticleInfosSchema)
      })
    },
    failureResponse: genericFailureSchemas
  }
});
