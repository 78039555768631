import type { ScreenNavigation } from '../context/navigation/types/ScreenNavigation';
import { rootRoutes } from '../routing/routes';
import type { AppTaskContext } from '../types/AppTaskContext';

/** Pops if possible.  Otherwise, pushes to the default route using a pop transition */
export const forceNavigationPop = (context: AppTaskContext, navigation: ScreenNavigation) => {
  if (navigation.canPop.get()) {
    navigation.pop();
  } else {
    navigation.push(rootRoutes.defaultRoute(context, {}), { transitionMode: 'pop' });
  }
};
