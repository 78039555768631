import { apiData } from 'lf-blog-schema';

import type { EditArticleScreenArgs } from '../../screens/EditArticleScreen';
import { makeRouteInfo } from '../makeRouteInfo';
import { todayRoute } from './todayRoute';

export const editArticleRoute = makeRouteInfo<{ articleId: apiData.ArticleIdentifier }, [Omit<EditArticleScreenArgs, 'articleId'>] | []>({
  path: ['article', ['articleId'], 'edit'],
  pathSchemas: { articleId: apiData.articleIdentifierSchema },
  defaultHistory: (context, _pathParams, _args = {}) => [todayRoute(context, {})],
  screen: async (_context, { articleId }, args = {}) =>
    (await import('../../screens/EditArticleScreen')).EditArticleScreen({ ...args, articleId })
});
