export type MemCache<KeyT extends string = string, ValueT = any> = (key: KeyT, maker: () => ValueT) => ValueT;

export const makeMemCache = <KeyT extends string = string, ValueT = any>(): MemCache<KeyT, ValueT> => {
  const cache = new Map<KeyT, ValueT>();

  return (cacheKey: KeyT, maker: () => ValueT) => {
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey) as ValueT;
    }

    const made = maker();
    cache.set(cacheKey, made);
    return made;
  };
};

export const makeCachedFunc = <ArgsT extends any[], KeyT extends string, ValueT>(
  func: (...args: ArgsT) => [cacheKey: KeyT, maker: () => ValueT]
) => {
  const cache = makeMemCache<KeyT, ValueT>();

  return (...args: ArgsT) => {
    const [cacheKey, maker] = func(...args);
    return cache(cacheKey, maker);
  };
};
