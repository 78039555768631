export const STD_FIELD_SIZE_PX = 34;
export const STD_SMALL_FIELD_SIZE_PX = 24;
export const STD_ICON_SIZE_PX = 24;
export const STD_SMALL_ICON_SIZE_PX = 16;
export const STD_ROW_SIZE_PX = 44;
export const BR_PX = 8;
export const SP_PX = 8;

export const SMALLEST_SUPPORTED_SIZE_PX = 320;
export const DESKTOP_MODE_MIN_SIZE_PX = 640;
export const MAX_PAGE_HEIGHT_PX = 932; // iPhone 15 Pro Max height

export const br = (multiplier = 1) => px(BR_PX * multiplier);
export const px = (size: number): `${number}px` => `${size}px`;
export const sp = (multiplier = 1) => px(SP_PX * multiplier);
