import type { Binding } from 'react-bindings';
import { BC, ifBinding, resolveTypeOrBindingType, useBindingEffect } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { imageLogosLfToolbar } from '../assets/logos/lf-toolbar';
import { typographyOrContext } from '../components/aliases/typographyOrContext';
import { Icon } from '../components/core/Icon';
import { AppLoadingIndicator } from '../components/feedback/AppLoadingIndicator';
import { Row } from '../components/layout/Row';
import { px } from '../consts/layout';
import { useCenterMaxWidthPx } from '../context/center-max-width';
import { useScrollableParent } from '../context/scrollable-parent';
import { useDomRef } from '../context/useDomRef';
import { useFwdDomRef } from '../context/useFwdDomRef';
import { makeNavDocument } from '../makers/makeNavDocument';
import { makeNavScreen } from '../makers/makeNavScreen';
import type { ChildrenProps } from '../types/ChildrenProps';
import type { RealReactNode } from '../types/RealReactNode';

export interface LoadingScreenArgs {
  message?: RealReactNode | string | Binding<RealReactNode | string>;
}

export const LoadingScreen = makeNavScreen((args: LoadingScreenArgs) => ({
  main: () => MainDocument(args)
}));

// Helpers

const MainDocument = makeNavDocument<[LoadingScreenArgs]>(({ message = null }) => ({
  useTitle: () => <Icon src={imageLogosLfToolbar} variant="black" />,
  toolbarStyle: message !== null ? 'sticky' : 'hidden',
  Body: ({ toolbarItems }) => {
    const scrollableParent = useScrollableParent();

    const domRef = useFwdDomRef<HTMLDivElement>();

    if (message !== null) {
      toolbarItems.centerItems.set([
        <MessageContainer key="message">
          {BC(ifBinding(message), () =>
            typographyOrContext(resolveTypeOrBindingType(message), { variant: 'body2', color: 'textSecondary' })
          )}
        </MessageContainer>
      ]);
    }

    useBindingEffect({ scrollableParentSize: scrollableParent?.scrollableParentSize }, ({ scrollableParentSize }) => {
      if (domRef.current === null) {
        return;
      }

      domRef.current.style.height = px(scrollableParentSize?.heightPx ?? 0);
    });

    return (
      <Row fwdRef={domRef} alignItems="stretch" style={{ height: px(scrollableParent?.scrollableParentSize.get().heightPx ?? 0) }}>
        <AppLoadingIndicator />
      </Row>
    );
  }
}));

// Helpers

const useMessageContainerStyles = createUseStyles({
  message: {
    textAlign: 'center'
  }
});

const MessageContainer = ({ children }: ChildrenProps) => {
  const classNames = useMessageContainerStyles();
  const centerMaxWidthPx = useCenterMaxWidthPx();

  const domRef = useDomRef<HTMLDivElement>();

  useBindingEffect(centerMaxWidthPx, (centerMaxWidthPx) => {
    if (domRef.current === null) {
      return;
    }

    domRef.current.style.width = px(centerMaxWidthPx);
  });

  return (
    <div ref={domRef} className={classNames.message} style={{ width: px(centerMaxWidthPx.get()) }}>
      {children}
    </div>
  );
};
