import type { CSSProperties } from 'react';

import { px } from '../consts/layout';
import type { EdgeInsetsPx } from '../types/EdgeInsetsPx';
import type { Sides } from '../types/Sides';
import { extractSides } from '../types/Sides';

export const makePaddingForSafeArea = ({
  safeAreaInsets,
  needsSafeSides,
  defaultLeftRightPaddingPx,
  defaultLeftPaddingPx,
  defaultRightPaddingPx,
  defaultTopBottomPaddingPx,
  defaultTopPaddingPx,
  defaultBottomPaddingPx,
  addPaddingsFromStyle
}: {
  safeAreaInsets: EdgeInsetsPx;
  needsSafeSides: Sides;
  defaultLeftRightPaddingPx?: number;
  defaultLeftPaddingPx?: number;
  defaultRightPaddingPx?: number;
  defaultTopBottomPaddingPx?: number;
  defaultTopPaddingPx?: number;
  defaultBottomPaddingPx?: number;
  addPaddingsFromStyle?: CSSProperties;
}): Partial<Record<`padding${'Top' | 'Right' | 'Bottom' | 'Left'}`, string>> => {
  const safeSides = extractSides(needsSafeSides);

  const paddingStyle: Partial<Record<`padding${'Top' | 'Right' | 'Bottom' | 'Left'}`, string>> = {
    paddingTop: px((defaultTopPaddingPx ?? defaultTopBottomPaddingPx ?? 0) + (safeSides.top ? safeAreaInsets.topPx : 0)),
    paddingRight: px((defaultRightPaddingPx ?? defaultLeftRightPaddingPx ?? 0) + (safeSides.right ? safeAreaInsets.rightPx : 0)),
    paddingBottom: px((defaultBottomPaddingPx ?? defaultTopBottomPaddingPx ?? 0) + (safeSides.bottom ? safeAreaInsets.bottomPx : 0)),
    paddingLeft: px((defaultLeftPaddingPx ?? defaultLeftRightPaddingPx ?? 0) + (safeSides.left ? safeAreaInsets.leftPx : 0))
  };

  // Note: calc doesn't work with ssr, but ssr doesn't have safe areas, so this is ok
  if (addPaddingsFromStyle !== undefined) {
    if (addPaddingsFromStyle.paddingTop !== undefined) {
      if (paddingStyle.paddingTop === '0px') {
        delete paddingStyle.paddingTop;
      } else {
        paddingStyle.paddingTop = `calc(${paddingStyle.paddingTop} + ${addPaddingsFromStyle.paddingTop})`;
      }
    }
    if (addPaddingsFromStyle.paddingRight !== undefined) {
      if (paddingStyle.paddingRight === '0px') {
        delete paddingStyle.paddingRight;
      } else {
        paddingStyle.paddingRight = `calc(${paddingStyle.paddingRight} + ${addPaddingsFromStyle.paddingRight})`;
      }
    }
    if (addPaddingsFromStyle.paddingBottom !== undefined) {
      if (paddingStyle.paddingBottom === '0px') {
        delete paddingStyle.paddingBottom;
      } else {
        paddingStyle.paddingBottom = `calc(${paddingStyle.paddingBottom} + ${addPaddingsFromStyle.paddingBottom})`;
      }
    }
    if (addPaddingsFromStyle.paddingLeft !== undefined) {
      if (paddingStyle.paddingLeft === '0px') {
        delete paddingStyle.paddingLeft;
      } else {
        paddingStyle.paddingLeft = `calc(${paddingStyle.paddingLeft} + ${addPaddingsFromStyle.paddingLeft})`;
      }
    }
  }

  return paddingStyle;
};
