import { createContext, useContext } from 'react';
import type { ReadonlyBinding } from 'react-bindings';
import { makeConstBinding, useBinding } from 'react-bindings';

import type { ChildrenProps } from '../../types/ChildrenProps';

const ScreenStateContext = createContext<ReadonlyBinding<boolean>>(makeConstBinding(true, { id: 'defaultIsScreenCurrent' }));

export const useIsScreenCurrent = () => useContext(ScreenStateContext);

export interface ScreenStateProviderProps {
  isCurrent: boolean;
}

export const ScreenStateProvider = ({ children, isCurrent }: ChildrenProps & ScreenStateProviderProps) => {
  const value = useBinding(() => isCurrent, { id: 'isScreenCurrent', detectChanges: true });
  value.set(isCurrent);

  return <ScreenStateContext.Provider value={value}>{children}</ScreenStateContext.Provider>;
};
