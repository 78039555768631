import { shortUidStringSchema } from 'linefeedr-basic-schema';
import type { Schema } from 'yaschema';
import { schema } from 'yaschema';

export const makeArticleParagraphSchema = <T extends string, V>(type: T, specializationSchema: Schema<V>) =>
  schema.allOf(
    schema.object({
      id: shortUidStringSchema,
      type: schema.string(type)
    }),
    specializationSchema
  );
