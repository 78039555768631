import { createUseStyles } from 'react-jss';

import { px } from '../../consts/layout';

const HEIGHT_PX = 1024;

export interface PullDownProps {
  color: string;
}

/** Used by NavigationBar to support cases where users scroll past the top */
export const PullDown = ({ color }: PullDownProps) => {
  const classNames = useStyles();

  return <div className={classNames.main} style={{ backgroundColor: color }} />;
};

const useStyles = createUseStyles({
  main: {
    position: 'absolute',
    top: px(-HEIGHT_PX),
    left: 0,
    right: 0,
    height: px(HEIGHT_PX),
    zIndex: 1
  }
});
