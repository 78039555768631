import type { CSSProperties, HTMLAttributeAnchorTarget } from 'react';
import { useCallbackRef } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { px, sp, STD_ROW_SIZE_PX } from '../../consts/layout';
import type { RouteInfoResolver } from '../../routing/types/RouteInfoResolver';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { NavMode } from '../../types/NavMode';
import type { FlexParentStyleField } from '../../types/styles/flex-parent-style';
import { SafeSidesRowLink } from '../layout/SafeSidesRowLink';
import { IsListItemDisabledProvider } from './context/disabled';
import { IsListItemSelectedProvider } from './context/selection';

export interface ListItemProps<ValueT> extends Pick<CSSProperties, Exclude<FlexParentStyleField, 'flexDirection'>> {
  onClick?: (value: ValueT) => void;
  nav?: RouteInfoResolver;
  /** @defaultValue `'only-secondary'` */
  navMode?: NavMode;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
  value?: ValueT;
  /** @defaultValue `false` */
  disabled?: boolean;
  /** @defaultValue `false` */
  selected?: boolean;
  style?: Omit<CSSProperties, 'padding'>;
}

export const ListItem = <ValueT,>({
  children,
  onClick,
  nav,
  navMode = 'only-secondary',
  href,
  target,
  rel,
  value,
  disabled = false,
  selected = false,
  style,
  ...flexProps
}: ChildrenProps & ListItemProps<ValueT>) => {
  const classNames = useStyles();

  const wrappedOnClick = useCallbackRef(() => onClick?.(value as ValueT));
  const isClickable = !disabled && (onClick !== undefined || (navMode !== 'only-secondary' && nav !== undefined) || href !== undefined);

  return (
    <SafeSidesRowLink
      needsSafeSides="rl"
      alignItems="center"
      className={`${classNames.main} ${isClickable ? classNames.clickable : ''}`}
      style={{ ...mainStyle, ...style }}
      href={href}
      target={target}
      rel={rel}
      {...flexProps}
      disabled={disabled}
      onClick={isClickable ? wrappedOnClick : undefined}
      nav={nav}
      navMode={navMode}
    >
      <IsListItemDisabledProvider isDisabled={disabled}>
        <IsListItemSelectedProvider isSelected={selected}>{children}</IsListItemSelectedProvider>
      </IsListItemDisabledProvider>
    </SafeSidesRowLink>
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    flexShrink: 0,
    minHeight: px(STD_ROW_SIZE_PX)
  },
  clickable: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      '&:active': {
        backgroundColor: 'rgba(0,0,0,0.1)'
      }
    }
  }
});

const mainStyle: Omit<CSSProperties, 'padding'> = {
  paddingTop: sp(1),
  paddingRight: sp(2),
  paddingBottom: sp(1),
  paddingLeft: sp(2)
};
