import type { ChildrenProps } from '../../types/ChildrenProps';
import { useNavigation } from '.';
import { DocumentNavigationProvider } from './DocumentNavigationProvider';
import type { ScreenArea } from './types/NavScreen';

export interface DocumentNavigationSelectorProps {
  screenArea: ScreenArea;
}

export const DocumentNavigationSelector = ({ children, screenArea }: ChildrenProps & DocumentNavigationSelectorProps) => {
  const screenNavigation = useNavigation();

  const documentNavigation = screenNavigation[screenArea];

  return <DocumentNavigationProvider navigation={documentNavigation}>{children}</DocumentNavigationProvider>;
};
