import _ from 'lodash';
import { schema } from 'yaschema';

import { MAX_IDENTIFIER_LENGTH, MIN_IDENTIFIER_LENGTH } from '../consts/limits';

export const nonPrefixedIdentifierPattern = '[a-z0-9-]+';
export const nonPrefixedIdentifierRegex = new RegExp(`^${nonPrefixedIdentifierPattern}$`);

export const makeIdentifierSchema = <PrefixT extends string>(prefix: PrefixT) =>
  schema
    .regex<Identifier<PrefixT>>(new RegExp(`^${_.escapeRegExp(prefix)}${nonPrefixedIdentifierPattern}$`))
    .setAllowedLengthRange(prefix.length + MIN_IDENTIFIER_LENGTH, prefix.length + MAX_IDENTIFIER_LENGTH);
export type Identifier<PrefixT extends string> = `${PrefixT}${string}`;

export const nonPrefixedIdentifierSchema = makeIdentifierSchema('');
export type NonPrefixedIdentifier = typeof nonPrefixedIdentifierSchema.valueType;
