import { apiData } from 'lf-blog-schema';

import type { EditPersonaScreenArgs } from '../../screens/EditPersonaScreen';
import { makeRouteInfo } from '../makeRouteInfo';
import { accountRoute } from './accountRoute';
import { todayRoute } from './todayRoute';

export const editPersonaRoute = makeRouteInfo<{ personaId: apiData.PersonaIdentifier }, [Omit<EditPersonaScreenArgs, 'personaId'>] | []>({
  path: ['persona', ['personaId'], 'edit'],
  pathSchemas: { personaId: apiData.personaIdentifierSchema },
  defaultHistory: (context, _pathParams, _args = {}) => [todayRoute(context, {}), accountRoute(context, {})],
  screen: async (_context, { personaId }, args = {}) =>
    (await import('../../screens/EditPersonaScreen')).EditPersonaScreen({ ...args, personaId })
});
