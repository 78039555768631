import { schema } from 'yaschema';

import { MAX_ARTICLE_QUOTE_LENGTH } from '../../../../consts/limits';
import { makeArticleParagraphSchema } from './internal/makeArticleParagraphSchema';

export const articleQuoteParagraphSchema = makeArticleParagraphSchema(
  'quote',
  schema.object({
    content: schema.string().setAllowedLengthRange(1, MAX_ARTICLE_QUOTE_LENGTH)
  })
);
export type ArticleQuoteParagraph = typeof articleQuoteParagraphSchema.valueType;
