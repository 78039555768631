import { createContext, useContext } from 'react';

import type { ChildrenProps } from '../../../types/ChildrenProps';

const DisabledContext = createContext<boolean>(false);

export interface IsListItemDisabledProviderProps {
  isDisabled: boolean;
}

export const IsListItemDisabledProvider = ({ children, isDisabled }: ChildrenProps & IsListItemDisabledProviderProps) => (
  <DisabledContext.Provider value={isDisabled}>{children}</DisabledContext.Provider>
);

export const useIsListItemDisabled = () => useContext(DisabledContext);
