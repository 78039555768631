import isPromise from 'is-promise';
import type { CacheMakerResult, ICache } from 'linefeedr-cache';
import { resolveTypeOrDeferredType, type TypeOrDeferredType } from 'react-bindings';
import type { TypeOrPromisedType, WrappedResult } from 'react-waitables';

import { getConfig } from '../config/getConfig';
import { ONE_SEC_MSEC } from '../consts/time';
import { getDevToolValue } from '../dev/tools/dev-tools-support';
import { slowTasksDevTool } from '../dev/tools/slowTasksDevTool';
import type { UncaughtException } from '../types/UncaughtException';
import { inline } from '../utils/inline';
import { sleep } from '../utils/sleep';

const SLOW_TASK_SLEEP_INTERVAL_MSEC = ONE_SEC_MSEC;

export interface TaskMeta<ArgsT extends any[]> {
  id: string;
  cache?: TypeOrDeferredType<ICache<WrappedResult<any, any>>>;
  cacheKey?: (...args: ArgsT) => string | undefined;
}

export type TaskRunner<ContextT, ArgsT extends any[], SuccessT, FailureT> = (
  context: ContextT,
  ...args: ArgsT
) => TypeOrPromisedType<CacheMakerResult<WrappedResult<SuccessT, FailureT | UncaughtException>>>;

export interface MakeTaskArgs<ContextT, ArgsT extends any[], SuccessT, FailureT> extends TaskMeta<ArgsT> {
  run: TaskRunner<ContextT, ArgsT, SuccessT, FailureT>;
  ifOfflineDev?: CacheMakerResult<WrappedResult<SuccessT, FailureT | UncaughtException>> | TaskRunner<ContextT, ArgsT, SuccessT, FailureT>;
}

export interface Task<ContextT, ArgsT extends any[], SuccessT, FailureT> extends TaskMeta<ArgsT> {
  run: TaskRunner<ContextT, ArgsT, SuccessT, FailureT>;
  clearCache: (...args: ArgsT) => Promise<void>;
}

export type InferTaskArgsType<T> = T extends Task<any, infer ArgsT, any, any> ? ArgsT : never;
export type InferTaskSuccessType<T> = T extends Task<any, any[], infer SuccessT, any> ? SuccessT : never;
export type InferTaskFailureType<T> = T extends Task<any, any[], any, infer FailureT> ? FailureT : never;

// TODO: move to separate lib
export const makeTask = <ContextT, ArgsT extends any[], SuccessT, FailureT = any>(
  makeTaskArgs: MakeTaskArgs<ContextT, ArgsT, SuccessT, FailureT>
) => ({
  ...makeTaskArgs,
  run: (context: ContextT, ...args: ArgsT) => {
    const config = getConfig();

    const getResultWithOfflineSupport = () => {
      if (config.devMode === 'offline' && makeTaskArgs.ifOfflineDev !== undefined) {
        return typeof makeTaskArgs.ifOfflineDev === 'function' ? makeTaskArgs.ifOfflineDev(context, ...args) : makeTaskArgs.ifOfflineDev;
      }

      return makeTaskArgs.run(context, ...args);
    };

    const getResultWithSlowTasksSupport = () => {
      const slowTasks = config.devMode !== false && getDevToolValue(slowTasksDevTool).get();

      if (slowTasks) {
        return inline(async () => {
          await sleep(SLOW_TASK_SLEEP_INTERVAL_MSEC);

          return getResultWithOfflineSupport();
        });
      }

      return getResultWithOfflineSupport();
    };

    if (makeTaskArgs.cache !== undefined && makeTaskArgs.cacheKey !== undefined) {
      const cacheKey = makeTaskArgs.cacheKey(...args);
      if (cacheKey !== undefined) {
        const cache = resolveTypeOrDeferredType(makeTaskArgs.cache);
        return cache.getOrMake(makeTaskCacheKey(makeTaskArgs.id, cacheKey), () => {
          try {
            const result = getResultWithSlowTasksSupport();
            if (isPromise(result)) {
              return inline(async (): Promise<CacheMakerResult<WrappedResult<SuccessT, FailureT | UncaughtException>>> => {
                try {
                  const resolved = await result;
                  return { ...resolved, shouldCache: resolved.shouldCache ?? resolved.ok };
                } catch (e) {
                  config.loggers.tasks.error?.('Uncaught exception', e);
                  return { ok: false, value: { thrown: e }, shouldCache: false };
                }
              });
            } else {
              return { ...result, shouldCache: result.shouldCache ?? result.ok };
            }
          } catch (e) {
            config.loggers.tasks.error?.('Uncaught exception', e);
            return { ok: false, value: { thrown: e }, shouldCache: false };
          }
        }) as TypeOrPromisedType<WrappedResult<SuccessT, FailureT>>;
      }
    }

    return getResultWithSlowTasksSupport();
  },
  clearCache: async (...args: ArgsT) => {
    if (makeTaskArgs.cache === undefined || makeTaskArgs.cacheKey === undefined) {
      return;
    }

    const cache = resolveTypeOrDeferredType(makeTaskArgs.cache);

    const cacheKey = makeTaskArgs.cacheKey(...args);
    if (cacheKey === undefined) {
      return; // Nothing to do
    }

    return cache.remove(makeTaskCacheKey(makeTaskArgs.id, cacheKey));
  }
});

/** Makes a key for accessing the `ICache` instance for the task with the specified ID.  Don't use this to generate keys for `TaskMeta` as
 * this is already handled internally. */
export const makeTaskCacheKey = (taskId: string, cacheKey: string) => `task:${taskId}:${cacheKey}`;
