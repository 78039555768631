import _ from 'lodash';

import { makeLogger } from '../makers/makeLogger';
import { baseConfig } from './base';
import type { AppConfig } from './types';

export const developmentConfig = _.once(
  (): AppConfig =>
    _.merge({}, baseConfig(), {
      appEnv: 'development' as const,
      devMode: true,
      loggers: {
        default: makeLogger('debug', { prefix: '[DEFAULT]' }),
        localization: makeLogger('debug', { prefix: '[LOCALIZATION]' }),
        navigation: makeLogger('debug', { prefix: '[NAVIGATION]' }),
        tasks: makeLogger('debug', { prefix: '[TASKS]' })
      },
      api: {
        baseUrls: {
          default: 'https://api.dev.linefeedr.com'
        }
      },
      firebase: {
        apiKey: 'AIzaSyCe0Z6UwAcwOn8Bwpzyf-_ug2fJ1rgHOck',
        authDomain: 'linefeedr-dev.firebaseapp.com',
        projectId: 'linefeedr-dev',
        storageBucket: 'linefeedr-dev.appspot.com',
        messagingSenderId: '31924542769',
        appId: '1:31924542769:web:654fa4bb70b1743804a410',
        measurementId: 'G-JTR5HHVJDN'
      }
    })
);
