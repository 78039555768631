import type { TypeOrPromisedType } from 'react-waitables';

import type { ResolvedRouteInfo } from './ResolvedRouteInfo';

export interface RouteInfoResolver {
  (): TypeOrPromisedType<ResolvedRouteInfo>;
  path: string;
}

export const makePreResolvedRouteInfoResolver = (resolved: ResolvedRouteInfo): RouteInfoResolver => {
  const output: Partial<RouteInfoResolver> = () => resolved;
  output.path = resolved.path;
  return output as RouteInfoResolver;
};
