import { imageIconsLfSquare } from '../../assets/icons/lf-square';
import { sp } from '../../consts/layout';
import { Icon } from '../core/Icon';
import { Spinner } from '../core/Spinner';
import { Col } from '../layout/Col';

export const AppLoadingIndicator = () => (
  <Col justifyContent="center" alignItems="center" rowGap={sp(2)} style={{ width: '100%' }}>
    <Icon src={imageIconsLfSquare} variant="black" style={{ position: 'absolute', margin: '12px' }} />
    <Spinner variant="large" color="primary" />
  </Col>
);
