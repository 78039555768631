import { shortUidStringSchema } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';

import { MAX_ARTICLE_REVISION_NOTES_LENGTH } from '../../../consts/limits';

export const articleRevisionSchema = schema.object({
  id: shortUidStringSchema,
  publicationDate: schema.date(),
  notes: schema.string().setAllowedLengthRange(1, MAX_ARTICLE_REVISION_NOTES_LENGTH)
});

export type ArticleRevision = typeof articleRevisionSchema.valueType;
