import type { CSSProperties } from 'react';
import { BC } from 'react-bindings';

import { useSafeAreaInsets } from '../../context/safe-area';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { Sides } from '../../types/Sides';
import { omitFlexStyleProps, pickFlexStyleProps } from '../../types/styles/flex-parent-style';
import { makePaddingForSafeArea } from '../../utils/safe-area';
import type { LinkProps } from '../core/Link';
import { Link } from '../core/Link';
import type { RowProps } from './Row';

export interface SafeSidesRowProps extends Omit<RowProps & LinkProps, 'style'> {
  needsSafeSides: Sides;
  style?: Omit<CSSProperties, 'padding'>;
}

/** Special-case combining Row and Link into a single component, used with ListItem */
export const SafeSidesRowLink = ({ children, needsSafeSides, ...props }: ChildrenProps & SafeSidesRowProps) => {
  const safeAreaInsets = useSafeAreaInsets();

  return BC(safeAreaInsets, (safeAreaInsets) => {
    const paddingStyle = makePaddingForSafeArea({ safeAreaInsets, needsSafeSides, addPaddingsFromStyle: props.style });

    return (
      <Link
        {...omitFlexStyleProps(props)}
        style={{ ...props.style, ...paddingStyle, ...pickFlexStyleProps(props), display: 'flex', flexDirection: 'row' }}
      >
        {children}
      </Link>
    );
  });
};
