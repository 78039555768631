import { BC, useCallbackRef } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { imageIconsFieldX } from '../../../assets/icons/field-x';
import { Button } from '../../../components/core/Button';
import { Icon } from '../../../components/core/Icon';
import { Text } from '../../../components/core/Text';
import { Col } from '../../../components/layout/Col';
import { Row } from '../../../components/layout/Row';
import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../../consts/animation';
import { br, px, sp, SP_PX } from '../../../consts/layout';
import {
  contrastingTextPrimaryColorByBackgroundColorClass,
  contrastingTextSecondaryColorByBackgroundColorClass,
  palette,
  resolveColorVariant,
  resolveColorVariantClass
} from '../../../consts/palette';
import { ONE_SEC_MSEC } from '../../../consts/time';
import type { ColorVariant } from '../../../types/ColorVariant';
import { useSafeAreaInsets } from '../../safe-area';
import { useModalPresenter } from '..';
import { useModalInfo } from '../modal-info';

export interface MessageModalProps {
  color: ColorVariant;
  title: string;
  subtitle?: string;
  errorCode?: string | number;
  actionButtonTitle?: string;
  onActionButtonClick?: () => void;
}

export const MessageModal = ({ color, title, subtitle, errorCode, actionButtonTitle, onActionButtonClick }: MessageModalProps) => {
  const modalInfo = useModalInfo();
  const modalPresenter = useModalPresenter();
  const safeAreaInsets = useSafeAreaInsets();
  const classNames = useStyles();

  const onCloseClick = useCallbackRef(() => {
    if (modalInfo?.id !== undefined) {
      modalPresenter.hide(modalInfo?.id);
    }
  });

  const wrappedOnActionButtonClick = useCallbackRef(() => {
    onCloseClick();

    onActionButtonClick?.();
  });

  const colorClass = resolveColorVariantClass(color);

  return BC(safeAreaInsets, (safeAreaInsets) => (
    <Row
      alignItems="center"
      columnGap={sp(4)}
      className={classNames.main}
      style={{
        backgroundColor: resolveColorVariant(color),
        left: px(SP_PX + safeAreaInsets.leftPx),
        // Note: calc doesn't work with ssr, but modals won't be used with ssr, so this is ok
        maxWidth: `calc(100% - ${px(SP_PX * 2 + safeAreaInsets.leftPx + safeAreaInsets.rightPx)})`,
        marginBottom: px(safeAreaInsets.bottomPx)
      }}
    >
      <Col rowGap={sp(1)} className={classNames.titles}>
        <Text color={contrastingTextPrimaryColorByBackgroundColorClass[colorClass]}>{title}</Text>
        {subtitle !== undefined ? <Text color={contrastingTextSecondaryColorByBackgroundColorClass[colorClass]}>{subtitle}</Text> : null}
        {errorCode !== undefined ? (
          <Text color={contrastingTextSecondaryColorByBackgroundColorClass[colorClass]}>{`(${errorCode})`}</Text>
        ) : null}
      </Col>
      {actionButtonTitle !== undefined ? (
        <Button
          color={colorClass === 'light' ? 'textPrimary' : 'contrastTextPrimary'}
          variant="outlined"
          onClick={wrappedOnActionButtonClick}
        >
          {actionButtonTitle}
        </Button>
      ) : null}
      <Button color={color} variant="text" onClick={onCloseClick}>
        <Icon src={imageIconsFieldX} variant={colorClass === 'light' ? 'black' : 'white'} />
      </Button>
    </Row>
  ));
};

// Helpers

const useStyles = createUseStyles({
  '@keyframes messageModalAppear': {
    from: {
      opacity: '0%',
      bottom: '-100px'
    },
    to: {
      opacity: '100%',
      bottom: sp(1)
    }
  },
  main: {
    position: 'absolute',
    pointerEvents: 'initial',
    borderRadius: br(1),
    overflow: 'hidden',
    padding: `${sp(1)} ${sp(2)}`,
    boxShadow: `0 ${sp(0.5)} ${sp(1)} ${resolveColorVariant(palette.shadow)}`,
    animation: `$messageModalAppear ${ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s ease-in-out`,
    animationFillMode: 'forwards'
  },
  titles: {
    flex: 1
  }
});
