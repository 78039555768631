import { useBindingPersistence } from 'linefeedr-binding-persistence';
import type { CSSProperties } from 'react';
import { useBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';
import { makeStringSubtypeArray } from 'yaschema';

import { Scrollable } from '../../components/core/Scrollable';
import { TabSelection } from '../../components/tabs/TabSelection';
import { VirtualizedTabs } from '../../components/tabs/VirtualizedTabs';
import { sessionBindingPersistence } from '../../consts/binding-persistence';
import { palette } from '../../consts/palette';
import type { TabInfo } from '../../types/TabInfo';
import { isDefined } from '../../utils/isDefined';
import { useDevTool } from '../tools/dev-tools-support';
import { deviceSizeDevTool } from '../tools/deviceSizeDevTool';
import { slowTasksDevTool } from '../tools/slowTasksDevTool';
import { DevModeDemosSection } from './dev-mode-menu-sections/DevModeDemosSection';
import { DevModeEnvironmentInfoSection } from './dev-mode-menu-sections/DevModeEnvironmentInfoSection';
import { DevModeSitemapSection } from './dev-mode-menu-sections/DevModeSitemapSection';
import { DevToolsSection } from './dev-mode-menu-sections/DevToolsSection';

export interface DevModeMenuProps {
  isDesktopMode: boolean;
  width: CSSProperties['width'];
}

export const DevModeMenu = ({ isDesktopMode, width }: DevModeMenuProps) => {
  useDevTool(slowTasksDevTool);
  useDevTool(deviceSizeDevTool, { disabled: !isDesktopMode });
  const classNames = useStyles();

  const selectedTab = useBindingPersistence(
    useBinding<TabValue>(() => 'tools', { id: 'devMenuSelectedTab', detectChanges: true }),
    {
      storage: sessionBindingPersistence,
      // For dev tools, just assuming the stored value are always valid and that it's the developers responsibility to clean up their
      // storage manually if ever needed
      isValid: isDefined
    }
  );

  return (
    <Scrollable className={`DevModeMenu ${classNames.main}`} style={{ width }}>
      <TabSelection selectedTab={selectedTab} tabs={tabs} />
      <VirtualizedTabs selectedTab={selectedTab}>
        {{
          tools: <DevToolsSection />,
          sitemap: <DevModeSitemapSection />,
          demos: <DevModeDemosSection />,
          environmentInfo: <DevModeEnvironmentInfoSection />
        }}
      </VirtualizedTabs>
    </Scrollable>
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    backgroundColor: palette.mainBackground(),
    borderRight: `1px solid ${palette.divider()}`,
    minWidth: '280px',
    maxWidth: '360px',
    overflowY: 'auto',
    zIndex: 1
  }
});

const tabValues = makeStringSubtypeArray('tools', 'sitemap', 'demos', 'environmentInfo');
type TabValue = (typeof tabValues)[0];
const tabs: TabInfo<TabValue>[] = [
  {
    value: 'tools',
    title: 'Tools'
  },
  {
    value: 'sitemap',
    title: 'Sitemap'
  },
  {
    value: 'demos',
    title: 'Demos'
  },
  {
    value: 'environmentInfo',
    title: 'Environment Info'
  }
];
