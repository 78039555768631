import * as i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { loadNamespaceForLanguage } from '../../localization/loadNamespaceForLanguage';

export const init = async () => {
  i18next
    .use(LanguageDetector)
    .use(resourcesToBackend(loadNamespaceForLanguage))
    .use(initReactI18next)
    .init({
      defaultNS: 'default',
      supportedLngs: ['en', 'fr'], // TODO: add more
      fallbackLng: 'en',
      nsSeparator: '::',
      react: { useSuspense: false },
      nonExplicitSupportedLngs: true
    });
};
