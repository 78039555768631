import { makeConstBinding, useFlattenedBinding } from 'react-bindings';

import { useDocumentNavigation } from './DocumentNavigationProvider';

const defaultTitleBinding = makeConstBinding('', { id: 'defaultNavigationStackTitle' });

export const useNavigationStackTitle = () => {
  const navigation = useDocumentNavigation();

  return useFlattenedBinding(navigation?.currentMounted, (top) => top?.navigationBarItems.title ?? defaultTitleBinding, {
    id: 'navigationStackTitle',
    detectInputChanges: false,
    detectOutputChanges: true,
    areOutputValuesEqual: (a, b) => a === b
  });
};
