import { createContext, useContext } from 'react';
import type { Binding, BindingArrayDependencies, ReadonlyBinding } from 'react-bindings';
import { useBindingEffect, useCallbackRef, useStableValue } from 'react-bindings';

import { isSsr } from '../config/ssr';
import { SSR_BUSY_DEBOUNCE_INTERVAL_MSEC } from '../consts/ssr';
import type { ChildrenProps } from '../types/ChildrenProps';
import { normalizeAsArray } from '../utils/array-like';

export interface SsrInfo {
  incSsrBusyCount?: () => () => void;
}

const SsrInfoContext = createContext<SsrInfo>({});

export const useIncSsrBusyCount = () => useContext(SsrInfoContext).incSsrBusyCount;

export interface IsSsrProviderProps {
  ssrBusyCount: Binding<number>;
}

export const SsrInfoProvider = ({ children, ssrBusyCount }: ChildrenProps & IsSsrProviderProps) => {
  const incSsrBusyCount = useCallbackRef(() => {
    ssrBusyCount.set(ssrBusyCount.get() + 1);

    let alreadyRemoved = false;
    return () => {
      if (alreadyRemoved) {
        return;
      }
      alreadyRemoved = true;

      setTimeout(() => ssrBusyCount.set(ssrBusyCount.get() - 1), SSR_BUSY_DEBOUNCE_INTERVAL_MSEC);
    };
  });

  const value = useStableValue<SsrInfo>({
    incSsrBusyCount: isSsr() ? incSsrBusyCount : undefined
  });

  return <SsrInfoContext.Provider value={value}>{children}</SsrInfoContext.Provider>;
};

export const useSsrIsBusyUntil = (bindings: ReadonlyBinding | BindingArrayDependencies) => {
  const untilBindings = normalizeAsArray(bindings);

  const incSsrBusyCount = useIncSsrBusyCount();
  let decSsrBusyCount: (() => void) | undefined =
    isSsr() && !untilBindings.every((b) => b === undefined || Boolean(b.get()) === true) ? incSsrBusyCount?.() : undefined;

  useBindingEffect(
    isSsr() ? untilBindings : [],
    (values) => {
      if (!isSsr()) {
        return;
      }

      if (values.every((v) => v === undefined || Boolean(v) === true)) {
        decSsrBusyCount?.();
        decSsrBusyCount = undefined;
      } else if (decSsrBusyCount === undefined) {
        decSsrBusyCount = incSsrBusyCount?.();
      }
    },
    { triggerOnMount: true }
  );
};

export const useSsrIsBusyWhile = (bindings: ReadonlyBinding | BindingArrayDependencies) => {
  const whileBindings = normalizeAsArray(bindings);

  const incSsrBusyCount = useIncSsrBusyCount();
  let decSsrBusyCount: (() => void) | undefined =
    isSsr() && !whileBindings.every((b) => b === undefined || Boolean(b.get()) === false) ? incSsrBusyCount?.() : undefined;

  useBindingEffect(
    isSsr() ? whileBindings : [],
    (values) => {
      if (!isSsr()) {
        return;
      }

      if (values.every((v) => v === undefined || Boolean(v) === false)) {
        decSsrBusyCount?.();
        decSsrBusyCount = undefined;
      } else if (decSsrBusyCount === undefined) {
        decSsrBusyCount = incSsrBusyCount?.();
      }
    },
    { triggerOnMount: true }
  );
};
