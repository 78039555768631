import { schema } from 'yaschema';

import { MAX_ARTICLE_PARAGRAPH_LENGTH, MAX_URL_LENGTH } from '../../consts/limits';

/**
 * - b - bold
 * - i - italic
 * - u - underline
 * - m - monospaced
 */
export type RichTextMod = `${'b' | ''}${'i' | ''}${'u' | ''}${'m' | ''}`;
export const richTextModSchema = schema.regex<RichTextMod>(/^b?i?u?m?$/).setAllowedLengthRange(0, 4);

export const richTextPartSchema = schema.object({
  modifiers: richTextModSchema.optional(),
  url: schema.string().setAllowedLengthRange(1, MAX_URL_LENGTH).optional(),
  text: schema.string().setAllowedLengthRange(1, MAX_ARTICLE_PARAGRAPH_LENGTH)
});
export type RichTextPart = typeof richTextPartSchema.valueType;

export const richTextSchema = schema.object({
  parts: schema.array({ items: richTextPartSchema, maxLength: MAX_ARTICLE_PARAGRAPH_LENGTH })
});
export type RichText = typeof richTextSchema.valueType;
