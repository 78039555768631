import { useCallbackRef, useStableValue } from 'react-bindings';

import { ONE_SEC_MSEC } from '../../../consts/time';
import type { RouteInfoResolver } from '../../../routing/types/RouteInfoResolver';
import { forceNavigationPop } from '../../../utils/forceNavigationPop';
import { useNavigation } from '../../navigation';
import { useAppTaskContext } from '../../useAppTaskContext';
import { useModalPresenter } from '..';
import type { MessageModalProps } from '../components/MessageModal';
import { MessageModal } from '../components/MessageModal';
import { PersistentErrorMessageModal } from '../components/PersistentErrorMessageModal';
import type { ModalInfo } from '../types/ModalInfo';

const DEFAULT_HIDE_AFTER_MSEC = 5 * ONE_SEC_MSEC;

export interface MessageModalPresenterArgs extends Omit<ModalInfo, 'element' | 'id'>, MessageModalProps {}

export const useMessageModalPresenter = () => {
  const context = useAppTaskContext();
  const modalPresenter = useModalPresenter();
  const navigation = useNavigation();

  const showMessage = useCallbackRef(
    ({
      color,
      title,
      subtitle,
      errorCode,
      actionButtonTitle,
      onActionButtonClick,
      hideAfterMSec = DEFAULT_HIDE_AFTER_MSEC,
      ...fwd
    }: MessageModalPresenterArgs) =>
      modalPresenter.show({
        exclusivityGroup: 'message',
        fixed: true,
        ...fwd,
        hideAfterMSec,
        element: (
          <MessageModal
            color={color}
            title={title}
            subtitle={subtitle}
            errorCode={errorCode}
            actionButtonTitle={actionButtonTitle}
            onActionButtonClick={onActionButtonClick}
          />
        )
      })
  );
  const showErrorMessage = useCallbackRef(
    ({
      title,
      subtitle,
      errorCode,
      actionButtonTitle,
      onActionButtonClick,
      hideAfterMSec = DEFAULT_HIDE_AFTER_MSEC,
      ...fwd
    }: Omit<MessageModalPresenterArgs, 'color'>) =>
      modalPresenter.show({
        exclusivityGroup: 'message',
        fixed: true,
        ...fwd,
        hideAfterMSec,
        element: (
          <MessageModal
            color="error"
            title={title}
            subtitle={subtitle}
            errorCode={errorCode}
            actionButtonTitle={actionButtonTitle}
            onActionButtonClick={onActionButtonClick}
          />
        )
      })
  );

  const showPersistentErrorMessage = useCallbackRef(
    ({
      title,
      subtitle,
      errorCode,
      navigateTo,
      actionButtonTitle,
      onActionButtonClick,
      ...fwd
    }: Omit<MessageModalPresenterArgs, 'color' | 'hideAfterMSec' | 'onHide'> & { navigateTo?: RouteInfoResolver | false }) => {
      const onPersistentErrorMessageHide = () => {
        if (navigateTo === false) {
          return; // No navigation desired, just hide the message
        }

        if (navigateTo !== undefined) {
          navigation.history.batch(() => {
            navigation.history.clear();
            navigation.push(navigateTo, { transitionMode: 'pop' });
          });
        } else {
          forceNavigationPop(context, navigation);
        }
      };

      return modalPresenter.show({
        exclusivityGroup: 'persistent-error-message',
        fixed: true,
        ...fwd,
        hideAfterMSec: false,
        onHide: onPersistentErrorMessageHide,
        element: (
          <PersistentErrorMessageModal
            title={title}
            subtitle={subtitle}
            errorCode={errorCode}
            actionButtonTitle={actionButtonTitle}
            onActionButtonClick={onActionButtonClick}
          />
        )
      });
    }
  );

  return useStableValue({ showMessage, showErrorMessage, showPersistentErrorMessage });
};
