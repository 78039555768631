import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { articleIdentifierSchema } from '../../../types/api-data/identifiers/ArticleIdentifier';
import { personaIdentifierSchema } from '../../../types/api-data/identifiers/PersonaIdentifier';
import { personaInfoSchema } from '../../../types/api-data/persona/PersonaInfo';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'content',
  credentials: 'include',
  url: '/article/{id}/author-info',
  isSafeToRetry: true,
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema,
      params: schema.object({
        id: articleIdentifierSchema
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        authors: schema.record(personaIdentifierSchema, personaInfoSchema)
      })
    },
    failureResponse: genericFailureSchemas
  }
});
