import { useRef } from 'react';
import type { Binding } from 'react-bindings';
import { useBinding, useBindingEffect, useCallbackRef } from 'react-bindings';

export interface UseTimerBindingArgs {
  intervalMSec: number;
  enabled: boolean;
}

export const useTimerBinding = (initialArgs: UseTimerBindingArgs) => {
  const intervalMSec = useBinding(() => initialArgs.intervalMSec, { id: 'intervalMSec', detectChanges: true });
  const enabled = useBinding(() => initialArgs.enabled, { id: 'enabled', detectChanges: true });

  const timerBinding = useBinding<number, { intervalMSec: Binding<number>; enabled: Binding<boolean> }>(() => Date.now(), {
    id: 'values',
    addFields: (_b) => ({
      intervalMSec,
      enabled
    })
  });

  const tick = useCallbackRef(() => {
    timerBinding.set(Date.now());

    if (enabled.get()) {
      lastTimeout.current = setTimeout(tick, intervalMSec.get());
    }
  });

  const lastTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  useBindingEffect({ intervalMSec, enabled }, ({ intervalMSec, enabled }) => {
    if (lastTimeout.current !== undefined) {
      clearTimeout(lastTimeout.current);
      lastTimeout.current = undefined;
    }

    if (!enabled) {
      return;
    }

    lastTimeout.current = setTimeout(tick, intervalMSec);
  });

  return timerBinding;
};
