import { App } from './App';
import { HistoryProvider } from './context/history';
import { useCreateBrowserHistory } from './history/useCreateBrowserHistory';

export const WebApp = () => {
  const history = useCreateBrowserHistory();
  return (
    <HistoryProvider history={history}>
      <App />
    </HistoryProvider>
  );
};
