import { makeStringSubtypeArray, schema } from 'yaschema';

export const themeColors = makeStringSubtypeArray(
  'blue1',
  'blue2',
  'blue3',
  'blue4',
  'aqua1',
  'aqua2',
  'aqua3',
  'aqua4',
  'green1',
  'green2',
  'green3',
  'green4',
  'yellow1',
  'yellow2',
  'yellow3',
  'yellow4',
  'red1',
  'red2',
  'red3',
  'red4',
  'pink1',
  'pink2',
  'pink3',
  'pink4',
  'white',
  'gray1',
  'gray2',
  'gray3',
  'black'
);
export const themeColorSchema = schema.string(...themeColors);
export type ThemeColor = typeof themeColorSchema.valueType;
