import type { CSSProperties } from 'react';
import { BC } from 'react-bindings';

import { useSafeAreaInsets } from '../../context/safe-area';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { Sides } from '../../types/Sides';
import { makePaddingForSafeArea } from '../../utils/safe-area';
import type { RowProps } from './Row';
import { Row } from './Row';

export interface SafeSidesRowProps extends Omit<RowProps, 'style'> {
  needsSafeSides: Sides;
  style?: Omit<CSSProperties, 'padding'>;
}

/** Automatically adds padding for safe areas, where the sides are chosen from `needsSafeSides`.  Take care not to apply CSS classes that
 * attempt to override the padding, since these will be overridden. */
export const SafeSidesRow = ({ children, needsSafeSides, ...props }: ChildrenProps & SafeSidesRowProps) => {
  const safeAreaInsets = useSafeAreaInsets();

  return BC(safeAreaInsets, (safeAreaInsets) => {
    const paddingStyle = makePaddingForSafeArea({ safeAreaInsets, needsSafeSides, addPaddingsFromStyle: props.style });

    return (
      <Row {...props} style={{ ...props.style, ...paddingStyle }}>
        {children}
      </Row>
    );
  });
};
