import type { ComponentType } from 'react';
import { useRef } from 'react';
import type { Binding, ReadonlyBinding } from 'react-bindings';
import { BC, useBindingEffect, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { isSsr } from '../../config/ssr';
import { FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { px } from '../../consts/layout';
import { useDomRenderingMode } from '../../context/dom-rendering';
import { useDocumentNavigation } from '../../context/navigation/DocumentNavigationProvider';
import { useDocument } from '../../context/navigation/useDocument';
import { useScreenLocker } from '../../context/screen-locker';
import type { ScrollableControls } from '../../context/scrollable-parent';
import { useScrollableParent } from '../../context/scrollable-parent';
import { useFwdDomRef } from '../../context/useFwdDomRef';
import { ScreenTransitioner } from '../animation/ScreenTransitioner';
import { SafeAreaSpacer } from '../layout/SafeAreaSpacer';
import { MovingNavigationBar } from './MovingNavigationBar';
import { MovingToolbar } from './MovingToolbar';
import { Scrollable } from './Scrollable';
import { ScrollToTopIndicator } from './ScrollToTopIndicator';

export interface DocumentBodyControls extends ScrollableControls {}

export interface DocumentBodyProps {
  topInsetPx: ReadonlyBinding<number>;
  bottomInsetPx: ReadonlyBinding<number>;
  outShowNavigationBarSeparator: Binding<boolean>;
  outShowToolbarSeparator: Binding<boolean>;
  controls?: DocumentBodyControls;
}

export const DocumentBody = ({
  topInsetPx,
  bottomInsetPx,
  outShowNavigationBarSeparator,
  outShowToolbarSeparator,
  controls
}: DocumentBodyProps) => {
  const doc = useDocument();
  const navigation = useDocumentNavigation();
  const mode = useDomRenderingMode();
  const { lockScreen } = useScreenLocker();
  const classNames = useStyles();

  const domRef = useFwdDomRef<HTMLDivElement>();
  const scrollTopCache = useRef<Partial<Record<string, number>>>({});

  const content = useDerivedBinding(
    doc,
    (doc): ComponentType =>
      () =>
        doc !== undefined ? (
          <doc.Body
            navigationBarItems={doc.navigationBarItems}
            navigationBarStyle={doc.navigationBarStyle}
            toolbarItems={doc.toolbarItems}
            toolbarStyle={doc.toolbarStyle}
          />
        ) : (
          <></>
        ),
    {
      id: 'content',
      areInputValuesEqual: (a, b) => a === b,
      detectOutputChanges: false
    }
  );
  const transitionInfo = useDerivedBinding(navigation?.lastTransitionInfo, (info) => info, { id: 'transitionInfo' });

  // Restoring scroll state for the same transition id (based on screen area and path)
  const previousTransitionId = useRef('');
  useBindingEffect(content, () => {
    const elem = domRef.current;
    if (elem === null) {
      return;
    }

    scrollTopCache.current[previousTransitionId.current] = elem.scrollTop;

    const id = navigation?.current.get()?.id ?? '';
    previousTransitionId.current = id;

    scrollableControls.current.scrollToYPx?.(scrollTopCache.current[id] ?? 0);
  });

  // Dynamically updating the margin and padding because we don't want to re-render the component
  useBindingEffect(
    topInsetPx,
    (topInsetPx) => {
      if (mode === 'no-render') {
        return;
      }

      const elem = domRef.current;
      if (elem === null) {
        return;
      }

      elem.style.paddingTop = px(topInsetPx);
    },
    { triggerOnMount: true, deps: [mode] }
  );

  // Dynamically updating the margin and padding because we don't want to re-render the component
  useBindingEffect(
    bottomInsetPx,
    (bottomInsetPx) => {
      if (mode === 'no-render') {
        return;
      }

      const elem = domRef.current;
      if (elem === null) {
        return;
      }

      elem.style.paddingBottom = px(bottomInsetPx);
    },
    { triggerOnMount: true, deps: [mode] }
  );

  const scrollableControls = useRef<ScrollableControls>({});

  const scrollToTop = useCallbackRef(() => scrollableControls.current.scrollToTop?.());

  if (controls !== undefined) {
    controls.scrollToTop = scrollToTop;
  }

  return isSsr() ? (
    <div className={classNames.main}>
      <MovingNavigationBar onClick={scrollToTop} />
      {BC(content, (Content) => (
        <Content />
      ))}
      <MovingToolbar />
    </div>
  ) : (
    <Scrollable
      fwdRef={domRef}
      className={`DocumentBody ${classNames.main}`}
      controls={scrollableControls.current}
      pre={<ScrollToTopIndicator onClick={scrollToTop} />}
      pullToRefresh={true}
    >
      <ScrollableConnector
        outShowNavigationBarSeparator={outShowNavigationBarSeparator}
        outShowToolbarSeparator={outShowToolbarSeparator}
      />

      <MovingNavigationBar onClick={scrollToTop} />
      {BC(content, (Content) => (
        <ScreenTransitioner transitionInfo={transitionInfo} locker={lockScreen}>
          <Content />
        </ScreenTransitioner>
      ))}
      <MovingToolbar />
      <SafeAreaSpacer sides="b" />
    </Scrollable>
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    flexGrow: 1,
    overflowY: 'auto'
  }
});

interface ScrollableConnectorProps {
  outShowNavigationBarSeparator: Binding<boolean>;
  outShowToolbarSeparator: Binding<boolean>;
}

const ScrollableConnector = ({ outShowNavigationBarSeparator, outShowToolbarSeparator }: ScrollableConnectorProps) => {
  const scrollableParent = useScrollableParent();

  useBindingEffect(
    {
      contentSize: scrollableParent?.contentSize,
      scrollableParentSize: scrollableParent?.scrollableParentSize,
      scrollPosition: scrollableParent?.scrollPosition
    },
    ({ contentSize, scrollableParentSize, scrollPosition }) => {
      outShowToolbarSeparator.set(
        (contentSize?.heightPx ?? 0) > (scrollableParentSize?.heightPx ?? 0) &&
          (scrollPosition?.yPx ?? 0) < (contentSize?.heightPx ?? 0) - (scrollableParentSize?.heightPx ?? 0)
      );
    },
    { triggerOnMount: true, limitType: 'throttle', limitMSec: FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC }
  );

  useBindingEffect(
    { scrollPosition: scrollableParent?.scrollPosition },
    ({ scrollPosition }) => {
      outShowNavigationBarSeparator.set((scrollPosition?.yPx ?? 0) > 0);
    },
    { triggerOnMount: true, limitType: 'throttle', limitMSec: FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC }
  );

  return null;
};
