import { createContext, useContext } from 'react';

import type { AppTaskContext } from '../types/AppTaskContext';
import type { ChildrenProps } from '../types/ChildrenProps';
import { assert } from '../utils/assert';
import { useAuthenticationCheck } from './authentication';
import { useLoadingCodeTracker } from './loading-code';

const AppTaskContextContext = createContext<AppTaskContext | undefined>(undefined);

export const useAppTaskContext = () => {
  const output = useContext(AppTaskContextContext);

  assert(output !== undefined, 'AppTaskContextProvider must be used with useAppTaskContext');

  return output;
};

export const AppTaskContextProvider = ({ children, ...overrides }: ChildrenProps & Partial<AppTaskContext>) => {
  const authCheck = useAuthenticationCheck();
  const { markIsLoadingCode } = useLoadingCodeTracker();

  const value: AppTaskContext = { authCheck, markIsLoadingCode, ...overrides };

  return <AppTaskContextContext.Provider value={value}>{children}</AppTaskContextContext.Provider>;
};
