import ReactDOM from 'react-dom/client';

import { WebApp } from './WebApp';

const searchBotRegex = /bot|crawl|spider|Google(?:-Extended|-InspectionTool|Other)|MicrosoftPreview/i;

export const bootstrap = () => {
  const rootElem = document.getElementById('root');
  if (rootElem === null) {
    console.error('Unable to find root element');
    return;
  }

  if (rootElem.childNodes.length !== 0 && searchBotRegex.test(navigator.userAgent)) {
    console.debug('Search bot detected on pre-rendered page, skipping primary JavaScript execution');
    return;
  }

  const root = ReactDOM.createRoot(rootElem);
  root.render(<WebApp />);
};
