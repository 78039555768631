import type { CSSProperties } from 'react';
import type { Binding, ReadonlyBinding } from 'react-bindings';
import { BC, useCallbackRef } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { px, sp, SP_PX, STD_ROW_SIZE_PX } from '../../consts/layout';
import { palette, resolveColorVariant } from '../../consts/palette';
import { ONE_SEC_MSEC } from '../../consts/time';
import { useToolbarStackCenterItems } from '../../context/navigation/useToolbarStackCenterItems';
import { useToolbarStackLeftItems } from '../../context/navigation/useToolbarStackLeftItems';
import { useToolbarStackRightItems } from '../../context/navigation/useToolbarStackRightItems';
import { useFwdDomRef } from '../../context/useFwdDomRef';
import { type Sides } from '../../types/Sides';
import { CrossFade } from '../animation/CrossFade';
import { DomResizeDetector } from '../layout/DomResizeDetector';
import { SafeSidesRow } from '../layout/SafeSidesRow';
import { LCR } from './LCR';

export interface ToolbarProps {
  outHeightPx: Binding<number>;
  needsSafeSides: Sides;
  showSeparator?: ReadonlyBinding<boolean>;
}

export const Toolbar = ({ outHeightPx, needsSafeSides, showSeparator }: ToolbarProps) => {
  const classNames = useStyles();
  const leftItems = useToolbarStackLeftItems();
  const centerItems = useToolbarStackCenterItems();
  const rightItems = useToolbarStackRightItems();

  const domRef = useFwdDomRef<HTMLDivElement>();

  const onResize = useCallbackRef((_width: number | undefined, height: number | undefined) =>
    outHeightPx.set(Math.round(height ?? 0) + SP_PX * 2)
  );

  return BC({ showSeparator }, ({ showSeparator }) => (
    <SafeSidesRow
      fwdRef={domRef}
      needsSafeSides={needsSafeSides}
      alignItems="center"
      className={`Toolbar ${classNames.toolbar} ${showSeparator ?? false ? classNames.showSeparator : ''}`}
      style={mainStyle}
    >
      <DomResizeDetector handleWidth={false} onResize={onResize} targetRef={domRef} />
      <LCR
        left={BC(leftItems, (leftItems, leftItemsBinding) => (
          <CrossFade contentChangeUid={leftItemsBinding.getChangeUid()} transitioningPartStyle={partStyle}>
            {leftItems}
          </CrossFade>
        ))}
        center={({ maxWidthPx }) =>
          BC(centerItems, (centerItems, centerItemsBinding) => (
            <CrossFade
              style={{ maxWidth: px(maxWidthPx) }}
              transitioningPartStyle={{ ...partStyle, maxWidth: px(maxWidthPx) }}
              contentChangeUid={centerItemsBinding.getChangeUid()}
            >
              {centerItems}
            </CrossFade>
          ))
        }
        right={BC(rightItems, (rightItems, rightItemsBinding) => (
          <CrossFade contentChangeUid={rightItemsBinding.getChangeUid()} transitioningPartStyle={partStyle}>
            {rightItems}
          </CrossFade>
        ))}
      />
    </SafeSidesRow>
  ));
};

// Helpers

const useStyles = createUseStyles({
  toolbar: {
    backgroundColor: resolveColorVariant(palette.toolbarBackground),
    minHeight: `${STD_ROW_SIZE_PX}px`,
    width: '100%',
    transition: `box-shadow ${ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s`
  },
  showSeparator: {
    boxShadow: `0 -1px 0 ${resolveColorVariant(palette.divider)}`
  }
});

const mainStyle: Omit<CSSProperties, 'padding'> = {
  paddingTop: sp(0.5),
  paddingRight: sp(2),
  paddingBottom: sp(0.5),
  paddingLeft: sp(2)
};

const partStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  columnGap: sp(1),
  alignItems: 'center'
};
