import { BC, type ReadonlyBinding, useDerivedBinding } from 'react-bindings';

import { DomRenderingModeProvider } from '../../context/dom-rendering';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { RealReactNode } from '../../types/RealReactNode';

export interface VirtualizedTabsProps<T extends string> {
  selectedTab: ReadonlyBinding<T>;
}

export const VirtualizedTabs = <T extends string>({
  children,
  selectedTab
}: { children: Record<T, RealReactNode> } & VirtualizedTabsProps<T>) =>
  (Object.entries(children) as Array<[T, RealReactNode]>).map(([value, element]) => (
    <Tab key={value} selectedTab={selectedTab} value={value}>
      {element}
    </Tab>
  ));

// Helpers

interface TabProps<T extends string> {
  selectedTab: ReadonlyBinding<T>;
  value: T;
}

const Tab = <T extends string>({ children, selectedTab, value }: ChildrenProps & TabProps<T>) => {
  const isSelected = useDerivedBinding(selectedTab, (selectedTab) => selectedTab === value, { id: 'isSelected', deps: [value] });

  return BC(isSelected, (isSelected) => (
    <div style={{ display: isSelected ? 'block' : 'none' }}>
      <DomRenderingModeProvider mode={isSelected ? 'render' : 'no-render'}>{children}</DomRenderingModeProvider>
    </div>
  ));
};
