import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { articlePermissionContextSchema } from '../../../types/api-data/article/ArticlePermissionContext';
import { articleIdentifierSchema } from '../../../types/api-data/identifiers/ArticleIdentifier';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'content',
  credentials: 'include',
  url: '/article/{id}/permissions',
  isSafeToRetry: true,
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema,
      params: schema.object({
        id: articleIdentifierSchema
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: articlePermissionContextSchema
    },
    failureResponse: genericFailureSchemas
  }
});
