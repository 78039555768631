import type { CSSProperties } from 'react';
import { useRef } from 'react';
import { BC, useBinding, useBindingEffect, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { isSsr } from '../../config/ssr';
import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { px } from '../../consts/layout';
import { ONE_SEC_MSEC } from '../../consts/time';
import { useDomRenderingMode } from '../../context/dom-rendering';
import { useFwdDomRef } from '../../context/useFwdDomRef';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { RealReactNode } from '../../types/RealReactNode';
import type { FlexParentStyleField } from '../../types/styles/flex-parent-style';
import { pickFlexStyleProps } from '../../types/styles/flex-parent-style';
import { DomResizeDetector } from '../layout/DomResizeDetector';
import { Flex } from '../layout/Flex';

const defaultTransitionDurationMSec = ELEMENT_LEVEL_TRANSITION_DURATION_MSEC;

export interface CrossFadeProps extends Pick<CSSProperties, FlexParentStyleField> {
  /**
   * The default transition time shared by opacity and size changes
   *
   * @defaultValue `ELEMENT_LEVEL_TRANSITION_DURATION_MSEC`
   */
  transitionDurationMSec?: number;
  /** @defaultValue `false` */
  animateInitialLoad?: boolean;
  /** @defaultValue `true` */
  animateOpacity?: boolean;
  /** @defaultValue `true` */
  animateSizeChanges?: boolean;
  style?: CSSProperties;
  transitioningPartStyle?: CSSProperties;
  opacityTransitionDurationMSec?: number;
  sizeChangeTransitionDurationMSec?: number;
  /** If defined, transitioning is only used if changed */
  contentChangeUid?: string;
}

export const CrossFade = (props: ChildrenProps & CrossFadeProps) => {
  const {
    children,
    transitionDurationMSec = defaultTransitionDurationMSec,
    animateInitialLoad = false,
    animateOpacity = true,
    animateSizeChanges = true,
    style,
    transitioningPartStyle,
    opacityTransitionDurationMSec = transitionDurationMSec,
    sizeChangeTransitionDurationMSec = transitionDurationMSec,
    contentChangeUid
  } = props;

  const mode = useDomRenderingMode();
  const classNames = useStyles({ opacityTransitionDurationMSec, sizeChangeTransitionDurationMSec });

  const domRef = useFwdDomRef<HTMLDivElement>();
  const domRefA = useFwdDomRef<HTMLDivElement>();
  const domRefB = useFwdDomRef<HTMLDivElement>();

  const a = useRef<RealReactNode>(null);
  const b = useRef<RealReactNode>(null);
  const currentSide = useBinding<'a' | 'b'>(() => 'a', { id: 'currentSide', detectChanges: true });

  const requiredSizeA = useBinding<TransitionableSize2DPx>(() => ({ hasKnownSize: false, width: 0, height: 0 }), {
    id: 'requiredSizeA',
    detectChanges: true
  });
  const requiredSizeB = useBinding<TransitionableSize2DPx>(() => ({ hasKnownSize: false, width: 0, height: 0 }), {
    id: 'requiredSizeB',
    detectChanges: true
  });
  const requiredSize = useDerivedBinding(
    { currentSide, requiredSizeA, requiredSizeB },
    ({ currentSide, requiredSizeA, requiredSizeB }) => {
      switch (currentSide) {
        case 'a':
          return requiredSizeA;
        case 'b':
          return requiredSizeB;
      }
    },
    { id: 'requiredSize' }
  );

  const targetSize = useBinding(() => ({ hasKnownSize: false, width: 0, height: 0 }), {
    id: 'targetSize',
    detectChanges: true
  });
  useBindingEffect(
    requiredSize,
    (requiredSize) => {
      if (!requiredSize.hasKnownSize) {
        return; // Not ready
      }

      targetSize.set(requiredSize);
    },
    { triggerOnMount: true }
  );

  const onResizeA = useCallbackRef((width?: number, height?: number) => {
    const resolvedWidth = Math.round(width ?? 0);
    const resolvedHeight = Math.round(height ?? 0);
    const hasKnownSize = resolvedWidth > 0 || resolvedHeight > 0 || (currentSide.get() === 'a' && (a.current ?? null) === null);

    requiredSizeA.set({ hasKnownSize, width: resolvedWidth, height: resolvedHeight });
    if (hasKnownSize && !requiredSizeB.get().hasKnownSize) {
      requiredSizeB.set({ hasKnownSize, width: resolvedWidth, height: resolvedHeight });
    }
  });
  const onResizeB = useCallbackRef((width?: number, height?: number) => {
    const resolvedWidth = Math.round(width ?? 0);
    const resolvedHeight = Math.round(height ?? 0);
    const hasKnownSize = resolvedWidth > 0 || resolvedHeight > 0 || (currentSide.get() === 'b' && (b.current ?? null) === null);

    requiredSizeB.set({ hasKnownSize, width: resolvedWidth, height: resolvedHeight });
    if (hasKnownSize && !requiredSizeA.get().hasKnownSize) {
      requiredSizeA.set({ hasKnownSize, width: resolvedWidth, height: resolvedHeight });
    }
  });

  const isFirstSizeChange = useRef(true);
  const isReadyToAnimate = useBinding(() => animateInitialLoad, { id: 'isReadyToAnimate', detectChanges: true });
  useBindingEffect(
    targetSize,
    (targetSize) => {
      if (mode === 'no-render') {
        return;
      }

      const elem = domRef.current;
      if (elem === null) {
        return;
      }
      if (!targetSize.hasKnownSize) {
        return;
      }

      elem.style.width = String(style?.width ?? px(targetSize.width));
      elem.style.height = String(style?.height ?? px(targetSize.height));

      if (isFirstSizeChange.current) {
        isFirstSizeChange.current = false;
        window.requestAnimationFrame(() => {
          isReadyToAnimate.set(true);
        });
      }
    },
    { triggerOnMount: true, deps: [mode, style?.width, style?.height] }
  );

  const lastClearTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  if (lastClearTimeout.current !== undefined) {
    clearTimeout(lastClearTimeout.current);
    lastClearTimeout.current = undefined;
  }

  const lastContentChangeUid = useRef<string | undefined>();
  if (isReadyToAnimate.get() && (contentChangeUid === undefined || lastContentChangeUid.current !== contentChangeUid)) {
    lastContentChangeUid.current = contentChangeUid;

    switch (currentSide.get()) {
      case 'a':
        lastClearTimeout.current = setTimeout(() => (a.current = null), opacityTransitionDurationMSec);
        currentSide.set('b');
        break;
      case 'b':
        lastClearTimeout.current = setTimeout(() => (b.current = null), opacityTransitionDurationMSec);
        currentSide.set('a');
        break;
    }
  }

  switch (currentSide.get()) {
    case 'a':
      a.current = children;
      break;
    case 'b':
      b.current = children;
      break;
  }

  return isSsr() ? (
    <Flex fwdRef={domRef} className="CrossFade" {...pickFlexStyleProps(props)} style={style}>
      <div ref={domRefA} className={classNames.current} style={transitioningPartStyle}>
        {children}
      </div>
    </Flex>
  ) : (
    BC({ currentSide, isReadyToAnimate }, ({ currentSide, isReadyToAnimate }) => (
      <Flex
        fwdRef={domRef}
        className={`CrossFade ${animateSizeChanges && isReadyToAnimate ? classNames.smoothResize : ''}`}
        {...pickFlexStyleProps(props)}
        style={style}
      >
        <div
          ref={domRefA}
          className={`
          ${animateOpacity && isReadyToAnimate ? classNames.smoothOpacity : ''}
          ${currentSide !== 'a' || isReadyToAnimate ? classNames.positionAbsolute : ''}
          ${currentSide === 'a' ? classNames.current : classNames.notCurrent}
          `}
          style={transitioningPartStyle}
        >
          <DomResizeDetector onResize={onResizeA} targetRef={domRefA} />
          {a.current}
        </div>
        <div
          ref={domRefB}
          className={`
          ${animateOpacity && isReadyToAnimate ? classNames.smoothOpacity : ''}
          ${currentSide !== 'b' || isReadyToAnimate ? classNames.positionAbsolute : ''}
          ${currentSide === 'b' ? classNames.current : classNames.notCurrent}
          `}
          style={transitioningPartStyle}
        >
          <DomResizeDetector onResize={onResizeB} targetRef={domRefB} />
          {b.current}
        </div>
      </Flex>
    ))
  );
};

// Helpers

interface TransitionableSize2DPx {
  hasKnownSize: boolean;
  width: number;
  height: number;
}

interface StylesArgs {
  opacityTransitionDurationMSec: number;
  sizeChangeTransitionDurationMSec: number;
}

const useStyles = createUseStyles({
  smoothResize: ({ sizeChangeTransitionDurationMSec }: StylesArgs) => ({
    transition: `width ${sizeChangeTransitionDurationMSec / ONE_SEC_MSEC}s, height ${sizeChangeTransitionDurationMSec / ONE_SEC_MSEC}s`
  }),
  smoothOpacity: ({ opacityTransitionDurationMSec }: StylesArgs) => ({
    transition: `opacity ${opacityTransitionDurationMSec / ONE_SEC_MSEC}s`
  }),
  positionAbsolute: {
    position: 'absolute'
  },
  current: {
    display: 'block',
    opacity: '100%'
  },
  notCurrent: {
    pointerEvents: 'none',
    opacity: '0%'
  }
});
