import type { MutableRefObject } from 'react';
import { useCallbackRef } from 'react-bindings';

import { useDomRef } from './useDomRef';

export interface FwdDomRef<T> extends MutableRefObject<T | null> {
  (node?: T | null | undefined): void;
}

export const useFwdDomRef = <T>() => {
  const domRef = useDomRef<T>();
  const output = useCallbackRef((node?: T | null | undefined) => {
    domRef.current = node ?? null;
    output.current = domRef.current;
  }) as FwdDomRef<T>;
  output.current = domRef.current;
  return output;
};
