import _ from 'lodash';
import type { CSSProperties } from 'react';
import { makeStringSubtypeArray } from 'yaschema';

export const flexParentStyleFields = makeStringSubtypeArray(
  'alignContent',
  'alignItems',
  'columnGap',
  'flexDirection',
  'flexFlow',
  'flexWrap',
  'gap',
  'justifyContent',
  'rowGap'
);
export type FlexParentStyleField = (typeof flexParentStyleFields)[0];

export const pickFlexStyleProps = (props: Pick<CSSProperties, FlexParentStyleField>) => _.pick(props, flexParentStyleFields);

export const omitFlexStyleProps = (props: Pick<CSSProperties, FlexParentStyleField>) => _.omit(props, flexParentStyleFields);
