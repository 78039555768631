import { StatusCodes } from 'http-status-codes';
import { authApiData } from 'linefeedr-auth-schema';
import { basicApiData, makeFailureWithCodeSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'auth',
  credentials: 'include',
  url: '/auth/sign-up',
  schemas: {
    request: {
      body: schema.object({
        username: authApiData.usernameSchema,
        password: authApiData.passwordSchema,
        authTokenDeliveryMode: authApiData.authTokenDeliveryModeSchema
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        /** If `true`, the auth token isn't verified yet and multi-factor authentication is required before most APIs can be used */
        requiresMfa: schema.boolean()
      })
    },
    failureResponse: makeFailureWithCodeSchemas('username-already-taken')
  }
});
