import type { EmptyObject } from 'react-bindings';
import type { schema } from 'yaschema';

import type { AppTaskContext } from '../types/AppTaskContext';
import { assert } from '../utils/assert';
import type { ResolvedRouteInfo } from './types/ResolvedRouteInfo';
import type { RouteInfo } from './types/RouteInfo';

export type RouteFinder = (context: AppTaskContext, path: string) => Promise<ResolvedRouteInfo>;

export const makeRouteFinder = <RoutesT extends Record<string, any>>(
  routes: RoutesT,
  { default: defaultRoute }: { default: RouteInfo<EmptyObject, []> }
) => {
  const typedRoutes = routes as Record<string, RouteInfo<Record<string, string>, []>>;
  const optimizedRoutes = new OptimizedRoutes(typedRoutes);

  for (const route of Object.values(typedRoutes)) {
    if (route.path.length === 0) {
      assert(route === (defaultRoute as any as RouteInfo<any, []>), 'Only default routes can have empty paths');
    }
  }

  return async (context: AppTaskContext, path: string): Promise<ResolvedRouteInfo> => {
    const pathParts = path.split('/');

    const found = optimizedRoutes.find(pathParts, 0);
    if (found !== undefined) {
      return found.info(context, found.params)();
    }

    return defaultRoute(context, {})();
  };
};

// Helpers

interface Found {
  info: RouteInfo<Record<string, string>, []>;
  params: Record<string, string>;
  level: number;
}

class OptimizedRoutes {
  private staticRouteInfos_: Partial<Record<string, RouteInfo<Record<string, string>, []>>> = {};
  private staticOptimizedRoutes_: Partial<Record<string, OptimizedRoutes>> = {};
  private dynamicRouteInfos_: Array<[string, schema.RegexSchema, RouteInfo<any, []>]> = [];
  private dynamicOptimizedRoutes_: Array<[string, schema.RegexSchema, OptimizedRoutes]> = [];
  private combinedDynamicRouteInfosRegex_: RegExp | undefined;
  private combinedDynamicOptimizedRoutesRegex_: RegExp | undefined;
  private level_: number;

  constructor(routes: Record<string, RouteInfo<Record<string, string>, []>>, level = 0) {
    this.level_ = level;

    for (const route of Object.values(routes)) {
      this.append(route);
    }
  }

  public readonly append = (routeInfo: RouteInfo<Record<string, string>, []>) => {
    const pathPart = routeInfo.path[this.level_];
    if (pathPart === undefined) {
      return;
    }

    if (typeof pathPart === 'string') {
      // Static part

      if (routeInfo.path[this.level_ + 1] === undefined) {
        // Leaf
        assert(this.staticRouteInfos_[pathPart] === undefined, `Path registered more than once ${routeInfo.path.join('/')}`);
        this.staticRouteInfos_[pathPart] = routeInfo;
      } else {
        this.staticOptimizedRoutes_[pathPart] = this.staticOptimizedRoutes_[pathPart] ?? new OptimizedRoutes({}, this.level_ + 1);
        this.staticOptimizedRoutes_[pathPart]!.append(routeInfo);
      }
    } else {
      // Dynamic part

      const [name] = pathPart;

      const partSchema = routeInfo.pathSchemas[name];

      if (routeInfo.path[this.level_ + 1] === undefined) {
        // Leaf
        this.combinedDynamicRouteInfosRegex_ = undefined;
        this.dynamicRouteInfos_.push([name, partSchema, routeInfo]);
      } else {
        this.combinedDynamicOptimizedRoutesRegex_ = undefined;
        const optimizedRoutes = new OptimizedRoutes({}, this.level_ + 1);
        this.dynamicOptimizedRoutes_.push([name, partSchema, optimizedRoutes]);
        optimizedRoutes.append(routeInfo);
      }
    }
  };

  public readonly find = (pathParts: string[], level: number): Found | undefined => {
    const pathPart = pathParts[level];
    if (pathPart === undefined) {
      return undefined;
    }

    const foundOptimized = this.staticOptimizedRoutes_[pathPart]?.find(pathParts, level + 1);
    if (foundOptimized !== undefined) {
      return foundOptimized;
    }

    const foundStatic = this.staticRouteInfos_[pathPart];
    if (foundStatic !== undefined) {
      return { info: foundStatic, params: {}, level };
    }

    if (this.combinedDynamicOptimizedRoutesRegex_ === undefined) {
      this.combinedDynamicOptimizedRoutesRegex_ = new RegExp(
        this.dynamicOptimizedRoutes_.map(([_name, partSchema]) => `(${partSchema.regex.source})`).join('|')
      );
    }
    if (this.combinedDynamicOptimizedRoutesRegex_.test(pathPart)) {
      let bestFound: Found | undefined;
      let bestFoundName: string | undefined;
      for (const [name, partSchema, optimizedRoutes] of this.dynamicOptimizedRoutes_) {
        if (partSchema.regex.test(pathPart)) {
          const found = optimizedRoutes.find(pathParts, level + 1);
          if (found !== undefined && (bestFound === undefined || found.level > bestFound.level)) {
            bestFound = found;
            bestFoundName = name;
          }
        }
      }

      if (bestFound !== undefined && bestFoundName !== undefined) {
        return { info: bestFound.info, params: { ...bestFound.params, [bestFoundName]: pathPart }, level: bestFound.level };
      }
    }

    if (this.combinedDynamicRouteInfosRegex_ === undefined) {
      this.combinedDynamicRouteInfosRegex_ = new RegExp(
        this.dynamicRouteInfos_.map(([_name, partSchema]) => `(${partSchema.regex.source})`).join('|')
      );
    }
    if (this.combinedDynamicRouteInfosRegex_.test(pathPart)) {
      for (const [name, partSchema, routeInfo] of this.dynamicRouteInfos_) {
        if (partSchema.regex.test(pathPart)) {
          return { info: routeInfo, params: { [name]: pathPart }, level };
        }
      }
    }

    return undefined;
  };
}
