import { schema } from 'yaschema';

import { articleVersionIdentifierSchema } from '../identifiers/ArticleVersionIdentifier';
import { articleLengthSchema } from './ArticleLength';

/** Metadata stored per article version */

export const articleGeneratedVersionedMetaSchema = schema.object({
  versionId: articleVersionIdentifierSchema,
  versionDate: schema.date(),
  previousVersionId: articleVersionIdentifierSchema.optional(),
  originalPublicationDate: schema.date().optional(),
  numEdits: schema.restrictedNumber([{ min: 0 }], { divisibleBy: [1] }),
  length: articleLengthSchema
});
export type ArticleGeneratedVersionedMeta = typeof articleGeneratedVersionedMetaSchema.valueType;
