import { makeConstBinding, useFlattenedBinding } from 'react-bindings';

import { useDocumentNavigation } from './DocumentNavigationProvider';

const defaultItemsBinding = makeConstBinding([], { id: 'defaultToolbarStackCenterItems' });

export const useToolbarStackCenterItems = () => {
  const navigation = useDocumentNavigation();

  return useFlattenedBinding(navigation?.currentMounted, (top) => top?.toolbarItems.centerItems ?? defaultItemsBinding, {
    id: 'toolbarStackCenterItems',
    detectInputChanges: false,
    detectOutputChanges: true,
    areOutputValuesEqual: (a, b) => a === b
  });
};
