import { createContext, useContext } from 'react';
import { type ReadonlyBinding, useBinding, useCallbackRef, useDerivedBinding } from 'react-bindings';

import { DomResizeDetector } from '../components/layout/DomResizeDetector';
import { minWidthPxByBreakpoint, nextLargerBreakpoint } from '../consts/breakpoints';
import type { Breakpoint } from '../types/Breakpoint';
import type { ChildrenProps } from '../types/ChildrenProps';
import { assert } from '../utils/assert';

const ContainerWidthContext = createContext<ContainerWidthInfo | undefined>(undefined);

export interface ContainerWidthProviderProps {
  /** If not provided, `ResizeDetector` will be used */
  widthPx?: ReadonlyBinding<number>;
}

export const useContainerWidth = () => {
  const output = useContext(ContainerWidthContext);
  assert(output !== undefined, 'ContainerWidthProvider must be used with useContainerWidth');
  return output;
};

export const ContainerWidthProvider = ({ children, widthPx }: ChildrenProps & ContainerWidthProviderProps) =>
  widthPx !== undefined ? (
    <ExplicitContainerWidthProvider widthPx={widthPx}>{children}</ExplicitContainerWidthProvider>
  ) : (
    <ImplicitContainerWidthProvider>{children}</ImplicitContainerWidthProvider>
  );

// Helpers

const ExplicitContainerWidthProvider = ({
  children,
  widthPx
}: ChildrenProps & ContainerWidthProviderProps & { widthPx: ReadonlyBinding<number> }) => {
  const widthInfo = useContainerWidthInfo(widthPx);

  return <ContainerWidthContext.Provider value={widthInfo}>{children}</ContainerWidthContext.Provider>;
};

const ImplicitContainerWidthProvider = ({ children }: ChildrenProps & ContainerWidthProviderProps & { widthPx?: undefined }) => {
  // Using the parent container width (or `window.innerWidth`) by default
  const parentContainerWidth = useContext(ContainerWidthContext);
  const widthPx = useBinding(() => (parentContainerWidth === undefined ? window.innerWidth : parentContainerWidth.get()), {
    id: 'widthPx',
    detectChanges: true
  });
  const widthInfo = useContainerWidthInfo(widthPx);

  const onResize = useCallbackRef((width: number | undefined, _height: number | undefined) => widthPx.set(Math.round(width ?? 0)));

  return (
    <>
      <DomResizeDetector handleHeight={false} onResize={onResize} />
      <ContainerWidthContext.Provider value={widthInfo}>{children}</ContainerWidthContext.Provider>
    </>
  );
};

// Helpers

type ContainerWidthInfo = ReturnType<typeof useContainerWidthInfo>;

const useContainerWidthInfo = (sizePx: ReadonlyBinding<number>) => {
  const b = useDerivedBinding(sizePx, (sizePx) => sizePx, { id: 'containerWidthInfo' });

  return {
    ...b,
    useIs: (compareTo: number | Breakpoint) =>
      useDerivedBinding(
        b,
        (v) => {
          if (typeof compareTo === 'number') {
            return v === compareTo;
          } else {
            const nextBp = nextLargerBreakpoint[compareTo];
            return v >= minWidthPxByBreakpoint[compareTo] && (nextBp === undefined || v < minWidthPxByBreakpoint[nextBp]);
          }
        },
        { id: 'isEq' }
      ),
    useIsGt: (compareTo: number | Breakpoint) =>
      useDerivedBinding(
        b,
        (v) => {
          if (typeof compareTo === 'number') {
            return v > compareTo;
          } else {
            const nextBp = nextLargerBreakpoint[compareTo];
            return nextBp !== undefined && v >= minWidthPxByBreakpoint[nextBp];
          }
        },
        { id: 'isGt' }
      ),
    useIsGte: (compareTo: number | Breakpoint) =>
      useDerivedBinding(
        b,
        (v) => {
          if (typeof compareTo === 'number') {
            return v >= compareTo;
          } else {
            return v >= minWidthPxByBreakpoint[compareTo];
          }
        },
        { id: 'isGte' }
      ),
    useIsLt: (compareTo: number | Breakpoint) =>
      useDerivedBinding(
        b,
        (v) => {
          if (typeof compareTo === 'number') {
            return v < compareTo;
          } else {
            return v < minWidthPxByBreakpoint[compareTo];
          }
        },
        { id: 'isLt' }
      ),
    useIsLte: (compareTo: number | Breakpoint) =>
      useDerivedBinding(
        b,
        (v) => {
          if (typeof compareTo === 'number') {
            return v <= compareTo;
          } else {
            const nextBp = nextLargerBreakpoint[compareTo];
            return nextBp === undefined || v < minWidthPxByBreakpoint[nextBp];
          }
        },
        { id: 'isLte' }
      )
  };
};
