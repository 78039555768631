export type LogLevel = 'debug' | 'log' | 'info' | 'warn' | 'error';

export const compareLogLevels = (a: LogLevel, b: LogLevel): number => logLevelNumericValues[a] - logLevelNumericValues[b];

// Helpers

const logLevelNumericValues: Record<LogLevel, number> = {
  debug: 0,
  log: 1,
  info: 2,
  warn: 3,
  error: 4
};
