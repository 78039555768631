import { li } from '../../../components/list/namespace';
import { CheckboxListItem } from '../../../components/list/specialized/CheckboxListItem';
import type { DevTool } from '../dev-tools-support';

export interface BooleanDevToolProps {
  devTool: DevTool<boolean>;
}

export const makeBooleanDevTool =
  (title: string) =>
  ({ devTool }: BooleanDevToolProps) => (
    <CheckboxListItem checked={devTool.value}>
      <li.Title>{title}</li.Title>
    </CheckboxListItem>
  );
