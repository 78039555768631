import type { Schema } from 'yaschema';
import { schema } from 'yaschema';

import { sessionIdentifierSchema } from '../identifiers/SessionIdentifier';
import { usernameSchema } from '../identifiers/Username';

export const makeAuthCheckSchema = <T>(userTypeSchema: Schema<T>) =>
  schema.oneOf3(
    schema.object({
      sessionId: sessionIdentifierSchema,
      requiresMfa: schema.boolean(true),
      username: schema.undefinedValue().optional(),
      userType: schema.undefinedValue().optional()
    }),
    schema.object({
      sessionId: sessionIdentifierSchema,
      requiresMfa: schema.boolean(false),
      username: usernameSchema,
      userType: userTypeSchema
    }),
    schema.object({
      sessionId: schema.undefinedValue().optional(),
      requiresMfa: schema.undefinedValue().optional(),
      username: schema.undefinedValue().optional(),
      userType: schema.undefinedValue().optional()
    })
  );
export type AuthCheck<T> = ReturnType<typeof makeAuthCheckSchema<T>>['valueType'];
