import type { CSSProperties } from 'react';

import type { TypographyInfo } from '../types/styles/TypographyInfo';
import { type TypographyVariant, typographyVariants } from '../types/styles/TypographyVariant';
import { px } from './layout';

export const typography: Record<TypographyVariant, TypographyInfo> = {
  articleHeader1: {
    fontSizePx: 21,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 1.4
  },
  articleHeader2: {
    fontSizePx: 17,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 1.4
  },
  body1: {
    fontSizePx: 16,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '18px'
  },
  body2: {
    fontSizePx: 14,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '16px'
  },
  button: {
    fontSizePx: 16,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '18px'
  },
  buttonSmall: {
    fontSizePx: 14,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '16px'
  },
  caption: {
    fontSizePx: 11,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '13px'
  },
  h1: {
    fontSizePx: 24,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '26px'
  },
  h2: {
    fontSizePx: 21,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '23px'
  },
  h3: {
    fontSizePx: 19,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '21px'
  },
  listHeader1: {
    fontSizePx: 17,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '19px'
  },
  listHeader2: {
    fontSizePx: 12,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '14px'
  },
  navigationBarTitle: {
    fontSizePx: 19,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '21px'
  },
  quote: {
    fontSizePx: 24,
    fontStyle: 'italic',
    fontWeight: 'normal',
    lineHeight: '26px'
  }
};

export const typographyStyles = typographyVariants.reduce(
  (out, variant) => {
    out[variant] = {
      fontFamily: typography[variant].fontFamily,
      fontSize: px(typography[variant].fontSizePx),
      fontStyle: typography[variant].fontStyle,
      fontWeight: typography[variant].fontWeight,
      lineHeight: typography[variant].lineHeight
    };
    return out;
  },
  {} as Partial<Record<TypographyVariant, CSSProperties>>
) as Record<TypographyVariant, CSSProperties>;
