import { makeStringSubtypeArray } from 'yaschema';

import { makeChoiceDevTool } from './components/makeChoiceDevTool';
import { createDevTool } from './dev-tools-support';

const choices = makeStringSubtypeArray('success', 'success-needs-mfa', 'error', 'unauthorized');
type Choice = (typeof choices)[0];

export const authStatusDevTool = createDevTool<Choice>('success', {
  Component: makeChoiceDevTool('Auth Status', choices),
  sessionPersistenceKey: 'devToolAuthStatus',
  offlineOnly: true
});
