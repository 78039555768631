export const deleteSsrStyles = () => {
  let found = true;
  while (found) {
    const styleElems = Array.from(document.getElementsByTagName('style')).filter((elem) => elem.dataset.ssr === 'true');
    found = styleElems.length > 0;
    for (const styleElem of styleElems) {
      styleElem.remove();
    }
  }
};
