import { useDomRenderingMode } from '../../context/dom-rendering';
import type { StringChildrenProps } from '../../types/ChildrenProps';

export const RawString = ({ children }: StringChildrenProps) => {
  const mode = useDomRenderingMode();

  switch (mode) {
    case 'no-render':
      return null;
    case 'render':
      return children;
  }
};
