import { useRef } from 'react';
import type { Binding } from 'react-bindings';
import { BC, makeConstBinding, useBinding, useBindingEffect, useDerivedBinding, useFlattenedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { px, STD_ROW_SIZE_PX } from '../../consts/layout';
import { ONE_SEC_MSEC } from '../../consts/time';
import type { NavigationBarStyle } from '../../context/navigation/types/NavigationBarStyle';
import { useDocument } from '../../context/navigation/useDocument';
import { NavigationBar } from './NavigationBar';

export interface StickyNavigationBarProps {
  outHeightPx: Binding<number>;
  showSeparator: Binding<boolean>;
  onClick?: () => void;
}

export const StickyNavigationBar = ({ outHeightPx, showSeparator, onClick }: StickyNavigationBarProps) => {
  const doc = useDocument();
  const classNames = useStyles();

  const navigationBarStyle = useFlattenedBinding(doc, (doc) => doc?.navigationBarStyle ?? defaultNavigationBarStyle, {
    id: 'navigationBarStyle'
  });
  const wantsStickyNavigationBar = useDerivedBinding(navigationBarStyle, (navigationBarStyle): boolean => navigationBarStyle === 'sticky', {
    id: 'wantsStickyNavigationBar'
  });

  const lastNavigationBarStyle = useRef(navigationBarStyle.get());
  const transition = useDerivedBinding(
    navigationBarStyle,
    (navigationBarStyle) => {
      const out =
        navigationBarStyle === 'hidden' || lastNavigationBarStyle.current === 'hidden'
          ? `transform ${ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s, opacity ${
              ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC
            }s`
          : `opacity ${ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s`;
      lastNavigationBarStyle.current = navigationBarStyle;
      return out;
    },
    { id: 'transition' }
  );

  const navigationBarHeightPx = useBinding(() => STD_ROW_SIZE_PX, { id: 'navigationBarHeightPx', detectChanges: true });
  useBindingEffect(
    { navigationBarHeightPx, sticky: wantsStickyNavigationBar },
    ({ navigationBarHeightPx, sticky }) => {
      outHeightPx.set(sticky ? navigationBarHeightPx : 0);
    },
    { triggerOnMount: true }
  );

  return BC({ wantsStickyNavigationBar, transition }, ({ wantsStickyNavigationBar: sticky, transition }) => (
    <div
      className={classNames.main}
      style={{
        transform: sticky ? 'translate(0)' : `translate(0, ${px(-navigationBarHeightPx.get())})`,
        opacity: sticky ? '100%' : 0,
        transition
      }}
    >
      <NavigationBar needsSafeSides="trl" outHeightPx={navigationBarHeightPx} showSeparator={showSeparator} onClick={onClick} />
    </div>
  ));
};

// Helpers

const useStyles = createUseStyles({
  main: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    top: 0
  }
});

const defaultNavigationBarStyle = makeConstBinding<NavigationBarStyle>('hidden', { id: 'defaultNavigationBarStyle' });
