import { makeBindingPersistence } from 'linefeedr-binding-persistence';
import { noop } from 'lodash';
import type { ComponentType } from 'react';
import { createContext, useContext, useEffect, useRef } from 'react';
import type { Binding, ReadonlyBinding } from 'react-bindings';
import { makeBinding, useBindingEffect, useCallbackRef } from 'react-bindings';

import { getConfig } from '../../config/getConfig';
import { sessionBindingPersistence } from '../../consts/binding-persistence';
import { useIsScreenCurrent } from '../../context/navigation/screen-state';
import { DoubleLinkedList } from '../../types/DoubleLinkedList';
import { assert } from '../../utils/assert';
import { isDefined } from '../../utils/isDefined';
import { uuid } from '../../utils/uuid';

export interface DevTool<T> {
  id: string;
  value: Binding<T>;
  offlineOnly: boolean;
}

const globalDevTools: Record<string, DevTool<any>> = {};
const globalDevToolComponents: Record<string, ComponentType<{ devTool: DevTool<any> }>> = {};

export interface CreateDevToolOptions<T> {
  Component: ComponentType<{ devTool: DevTool<T> }>;
  sessionPersistenceKey?: string;
  offlineOnly: boolean;
}

export const createDevTool = <T,>(value: T, { Component, sessionPersistenceKey, offlineOnly }: CreateDevToolOptions<T>) => {
  const uid = uuid();

  const newDevTool: DevTool<T> = {
    id: uid,
    value: makeBindingPersistence(
      makeBinding(() => value, { id: 'devToolValue', detectChanges: true }),
      {
        storage: sessionPersistenceKey !== undefined ? sessionBindingPersistence : undefined,
        // For dev tools, just assuming the stored value are always valid and that it's the developers responsibility to clean up their
        // storage manually if ever needed
        isValid: isDefined,
        key: sessionPersistenceKey,
        initAttached: true
      }
    ),
    offlineOnly
  };
  globalDevTools[uid] = newDevTool;
  globalDevToolComponents[uid] = Component;

  return newDevTool;
};

export const getDevToolValue = <T,>(devTool: DevTool<T>) => {
  assert(getConfig().devMode !== false, "getDevToolValue can only be used when devMode is true or 'offline'");

  const found = globalDevTools[devTool.id];
  assert(found !== undefined, `No dev tool found with id: ${devTool.id}`);
  return found.value as ReadonlyBinding<T>;
};

export const setDevToolValue = <T,>(devTool: DevTool<T>, value: T) => {
  const found = globalDevTools[devTool.id];
  assert(found !== undefined, `No dev tool found with id: ${devTool.id}`);
  found.value.set(value);
};

export const renderDevToolComponent = <T,>(devTool: DevTool<T>) => {
  const Component = globalDevToolComponents[devTool.id];
  return <Component devTool={devTool} />;
};

export const useDevTool = <T,>(devTool: DevTool<T>, { disabled = false }: { disabled?: boolean } = {}) => {
  const config = getConfig();
  const liveDevTools = useLiveDevTools();
  const isCurrent = useIsScreenCurrent();
  const isMounted = useRef(false);
  const lastPop = useRef<() => void>(noop);

  const update = useCallbackRef(() => {
    if (devTool.offlineOnly && config.devMode !== 'offline') {
      return; // Not to be used
    }

    lastPop.current();

    if (disabled || !isMounted.current || !isCurrent.get()) {
      lastPop.current = noop;
    } else {
      lastPop.current = liveDevTools.push(devTool);
    }
  });

  useBindingEffect(isCurrent, update, { triggerOnMount: true });

  useEffect(() => {
    isMounted.current = true;
    update();

    return () => {
      isMounted.current = false;
      update();
    };
  });
};

const LiveDevToolsContext = createContext<
  ReadonlyBinding<DoubleLinkedList<DevTool<any>>> & { push: (devTool: DevTool<any>) => () => void }
>(
  makeBinding(() => new DoubleLinkedList<DevTool<any>>(), {
    id: 'liveDevTools',
    addFields: (b) => ({
      push: (devTool: DevTool<any>) => {
        const newTools = b.get();
        const node = newTools.append(devTool);
        b.set(newTools);

        let alreadyRemoved = false;
        return () => {
          if (alreadyRemoved) {
            return; // Nothing to do
          }
          alreadyRemoved = true;

          newTools.remove(node);
          b.set(newTools);
        };
      }
    })
  })
);

export const useLiveDevTools = () => useContext(LiveDevToolsContext);
