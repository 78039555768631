import type { Breakpoint } from '../types/Breakpoint';

export const minWidthPxByBreakpoint: Record<Breakpoint, number> = {
  'sm-mobile': 0,
  'md-mobile': 320,
  'lg-mobile': 420,
  'sm-desktop': 640,
  'md-desktop': 960,
  'lg-desktop': 1800
};

export const nextSmallerBreakpoint: Record<Breakpoint, Breakpoint | undefined> = {
  'sm-mobile': undefined,
  'md-mobile': 'sm-mobile',
  'lg-mobile': 'md-desktop',
  'sm-desktop': 'lg-mobile',
  'md-desktop': 'sm-desktop',
  'lg-desktop': 'md-desktop'
};

export const nextLargerBreakpoint: Record<Breakpoint, Breakpoint | undefined> = {
  'sm-mobile': 'md-mobile',
  'md-mobile': 'lg-mobile',
  'lg-mobile': 'sm-desktop',
  'sm-desktop': 'md-desktop',
  'md-desktop': 'lg-desktop',
  'lg-desktop': undefined
};
