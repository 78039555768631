import type { Logger } from 'react-bindings';

import type { LogLevel } from '../types/LogLevel';
import { compareLogLevels } from '../types/LogLevel';

export const makeLogger = (logLevel: LogLevel, options: { parentLogger?: Logger; prefix?: string; suffix?: string } = {}): Logger => {
  const { parentLogger = console } = options;

  return {
    debug: compareLogLevels(logLevel, 'debug') >= 0 ? addPrefixAndSuffixSupport(parentLogger.debug, options) : undefined,
    log: compareLogLevels(logLevel, 'log') >= 0 ? addPrefixAndSuffixSupport(parentLogger.log, options) : undefined,
    info: compareLogLevels(logLevel, 'info') >= 0 ? addPrefixAndSuffixSupport(parentLogger.info, options) : undefined,
    warn: compareLogLevels(logLevel, 'warn') >= 0 ? addPrefixAndSuffixSupport(parentLogger.warn, options) : undefined,
    error: compareLogLevels(logLevel, 'error') >= 0 ? addPrefixAndSuffixSupport(parentLogger.error, options) : undefined
  };
};

// Helpers

const addPrefixAndSuffixSupport = (
  logFunc: ((message?: any, ...optionalParams: any[]) => void) | undefined,
  { prefix, suffix }: { prefix?: string; suffix?: string }
) =>
  logFunc !== undefined && (prefix !== undefined || suffix !== undefined)
    ? (message?: any, ...otherParams: any[]) => {
        const args: any[] = [];
        if (prefix !== undefined) {
          args.push(prefix);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        args.push(message, ...otherParams);
        if (suffix !== undefined) {
          args.push(suffix);
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        logFunc(...args);
      }
    : logFunc;
