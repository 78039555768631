import { schema } from 'yaschema';

import { articleIdentifierSchema } from '../identifiers/ArticleIdentifier';
import { themeColorSchema } from '../ThemeColor';
import { articleTitleSchema } from './ArticleTitle';

export const articleInfoSchema = schema.object({
  id: articleIdentifierSchema,
  title: articleTitleSchema,
  subtitle: articleTitleSchema.optional(),
  featureImageBaseUrl: schema.string().optional(),
  themeColor: themeColorSchema,
  iconImageBaseUrl: schema.string(),
  /** This can be a persona name, group name, or some combination */
  by: schema.string()
});

export type ArticleInfo = typeof articleInfoSchema.valueType;
