import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { personaIdentifierSchema } from '../../../types/api-data/identifiers/PersonaIdentifier';
import { personaDetailsSchema } from '../../../types/api-data/persona/PersonaDetails';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'persona',
  credentials: 'include',
  url: '/persona/{id}',
  schemas: {
    request: {
      params: schema.object({
        id: personaIdentifierSchema
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: personaDetailsSchema
    },
    failureResponse: genericFailureSchemas
  }
});
