import { noop } from 'lodash';
import { useRef } from 'react';
import type { Binding, ReadonlyBinding } from 'react-bindings';
import { BC, useBinding, useBindingEffect, useCallbackRef } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { isSsr } from '../../config/ssr';
import { px } from '../../consts/layout';
import { ONE_SEC_MSEC } from '../../consts/time';
import { useDomRenderingMode } from '../../context/dom-rendering';
import { ScreenStateProvider } from '../../context/navigation/screen-state';
import { useDomRef } from '../../context/useDomRef';
import { useFwdDomRef } from '../../context/useFwdDomRef';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { RealReactNode } from '../../types/RealReactNode';
import type { TransitionInfo } from '../../types/transitioning';
import { DomResizeDetector } from '../layout/DomResizeDetector';

export interface ScreenTransitionerProps {
  transitionInfo: ReadonlyBinding<TransitionInfo>;
  locker?: () => () => void;
}

export const ScreenTransitioner = ({ children, transitionInfo, locker }: ChildrenProps & ScreenTransitionerProps) => {
  const mode = useDomRenderingMode();
  const classNames = useStyles();
  const classNamesByTransitionMode = useStylesByTransitionMode({ transitionDurationMSec: 0 });

  const domRef = useDomRef<HTMLDivElement>();

  const a = useRef<RealReactNode>(null);
  const b = useRef<RealReactNode>(null);
  const currentSide = useBinding<AnimationSide>(() => 'a', { id: 'currentSide', detectChanges: true });
  const animationPhase = useBinding<AnimationPhase>(() => 'init', { id: 'animationPhase', detectChanges: true });

  const currentTransitionInfo = transitionInfo.get();
  const currentGlobalCount = currentTransitionInfo.globalCount;
  const lastTransitionGlobalCount = useRef(currentGlobalCount);

  const lastClearTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const lastUnlocker = useRef<() => void>(noop);

  if (lastTransitionGlobalCount.current < currentGlobalCount) {
    lastUnlocker.current();
    lastUnlocker.current = locker?.() ?? noop;
    animationPhase.set('init');

    if (lastClearTimeout.current !== undefined) {
      clearTimeout(lastClearTimeout.current);
      lastClearTimeout.current = undefined;
    }

    switch (currentSide.get()) {
      case 'a':
        lastClearTimeout.current = setTimeout(() => {
          a.current = null;
          animationPhase.set('complete');

          lastUnlocker.current();
          lastUnlocker.current = noop;
        }, currentTransitionInfo.durationMSec);
        currentSide.set('b');
        break;
      case 'b':
        lastClearTimeout.current = setTimeout(() => {
          b.current = null;
          animationPhase.set('complete');

          lastUnlocker.current();
          lastUnlocker.current = noop;
        }, currentTransitionInfo.durationMSec);
        currentSide.set('a');
        break;
    }
  }

  switch (currentSide.get()) {
    case 'a':
      a.current = children;
      break;
    case 'b':
      b.current = children;
      break;
  }

  const heightPx = useBinding(() => 0, { id: 'heightPx', detectChanges: true });

  // Dynamically updating the height because we don't want to re-render the component
  useBindingEffect(
    heightPx,
    (heightPx) => {
      if (mode === 'no-render') {
        return;
      }

      const elem = domRef.current;
      if (elem === null) {
        return;
      }

      elem.style.height = px(heightPx);
    },
    { deps: [mode] }
  );

  return isSsr() ? (
    <div ref={domRef} className={`Transitioner ${classNames.container}`}>
      <ScreenStateProvider isCurrent={true}>
        <div className={`${classNamesByTransitionMode.common} ${classNamesByTransitionMode.push_complete_current}`}>{children}</div>
      </ScreenStateProvider>
    </div>
  ) : (
    BC({ animationPhase, currentSide }, ({ animationPhase, currentSide }, { animationPhase: animationPhaseBinding }) => {
      if (animationPhase === 'init') {
        requestAnimationFrame(() => {
          animationPhaseBinding.set('active');
        });
      }

      return (
        <div ref={domRef} className={`Transitioner ${classNames.container}`}>
          <TransitionRenderer
            {...currentTransitionInfo}
            a={a.current}
            b={b.current}
            animationPhase={animationPhase}
            currentSide={currentSide}
            outHeightPx={heightPx}
          />
        </div>
      );
    })
  );
};

// Helpers

type AnimationPhase = 'init' | 'active' | 'complete';
type AnimationSide = 'a' | 'b';

interface TransitionRendererProps extends TransitionInfo {
  a: RealReactNode;
  b: RealReactNode;

  animationPhase: AnimationPhase;
  currentSide: AnimationSide;

  outHeightPx: Binding<number>;
}

const TransitionRenderer = ({ a, b, animationPhase, currentSide, mode, durationMSec, outHeightPx }: TransitionRendererProps) => {
  // Only getting transition info on renders -- don't want to actively listen to transition info changes since the transition is always
  // updated before the view to be rendered
  const classNames = useStylesByTransitionMode({ transitionDurationMSec: durationMSec });

  const domRefA = useFwdDomRef<HTMLDivElement>();
  const domRefB = useFwdDomRef<HTMLDivElement>();

  const aHeightPx = useBinding(() => 0, { id: 'aHeightPx', detectChanges: true });
  const bHeightPx = useBinding(() => 0, { id: 'bHeightPx', detectChanges: true });

  const onResizeA = useCallbackRef((_width: number | undefined, height: number | undefined) => aHeightPx.set(Math.round(height ?? 0)));
  const onResizeB = useCallbackRef((_width: number | undefined, height: number | undefined) => bHeightPx.set(Math.round(height ?? 0)));

  useBindingEffect({ aHeightPx, bHeightPx }, ({ aHeightPx, bHeightPx }) => outHeightPx.set(Math.max(aHeightPx, bHeightPx)), {
    triggerOnMount: true
  });

  return (
    <>
      <ScreenStateProvider isCurrent={currentSide === 'a'}>
        <div
          ref={domRefA}
          className={`${classNames.common} ${classNames[`${mode}_${animationPhase}_${currentSide === 'a' ? 'current' : 'notCurrent'}`]}`}
        >
          <DomResizeDetector handleWidth={false} onResize={onResizeA} targetRef={domRefA} />
          {a}
        </div>
      </ScreenStateProvider>
      <ScreenStateProvider isCurrent={currentSide === 'b'}>
        <div
          ref={domRefB}
          className={`${classNames.common} ${classNames[`${mode}_${animationPhase}_${currentSide === 'b' ? 'current' : 'notCurrent'}`]}`}
        >
          <DomResizeDetector handleWidth={false} onResize={onResizeB} targetRef={domRefB} />
          {b}
        </div>
      </ScreenStateProvider>
    </>
  );
};

const useStyles = createUseStyles({
  container: {
    width: '100%',
    overflowX: 'hidden'
  }
});

const useStylesByTransitionMode = createUseStyles({
  common: {
    position: 'absolute',
    width: '100%',
    overflow: 'hidden',
    animationFillMode: 'none'
  },

  // Pop
  pop_init_current: {
    transition: 'none',
    transform: 'translate(-100%, 0)'
  },
  pop_active_current: ({ transitionDurationMSec }: { transitionDurationMSec: number }) => ({
    transition: `transform ${transitionDurationMSec / ONE_SEC_MSEC}s`,
    transform: 'translate(0)'
  }),
  pop_complete_current: {
    transition: 'none',
    transform: 'translate(0)'
  },
  pop_init_notCurrent: {
    transition: 'none',
    transform: 'translate(0)'
  },
  pop_active_notCurrent: ({ transitionDurationMSec }: { transitionDurationMSec: number }) => ({
    transition: `transform ${transitionDurationMSec / ONE_SEC_MSEC}s`,
    transform: 'translate(100%, 0)'
  }),
  pop_complete_notCurrent: {
    transition: 'none',
    transform: 'translate(100%, 0)'
  },

  // Push
  push_init_current: {
    transition: 'none',
    transform: 'translate(100%, 0)'
  },
  push_active_current: ({ transitionDurationMSec }: { transitionDurationMSec: number }) => ({
    transition: `transform ${transitionDurationMSec / ONE_SEC_MSEC}s`,
    transform: 'translate(0)'
  }),
  push_complete_current: {
    transition: 'none',
    transform: 'translate(0)'
  },
  push_init_notCurrent: {
    transition: 'none',
    transform: 'translate(0)'
  },
  push_active_notCurrent: ({ transitionDurationMSec }: { transitionDurationMSec: number }) => ({
    transition: `transform ${transitionDurationMSec / ONE_SEC_MSEC}s`,
    transform: 'translate(-100%, 0)'
  }),
  push_complete_notCurrent: {
    transition: 'none',
    transform: 'translate(-100%, 0)'
  }
});
