import { createContext, useContext, useEffect } from 'react';
import { makeBinding, useBinding, useDerivedBinding } from 'react-bindings';

import type { ChildrenProps } from '../types/ChildrenProps';

const HideAllNonModalContentContext = createContext(makeBinding(() => 0, { id: 'defaultHideAllNonModalContent', detectChanges: true }));

export const useShouldHideAllNonModalContent = () => {
  const hideCount = useContext(HideAllNonModalContentContext);

  return useDerivedBinding(hideCount, (clipCount) => clipCount > 0, { id: 'shouldHideAllNonModalContent' });
};

export const ShouldHideAllNonModalContentRoot = ({ children }: ChildrenProps) => (
  <HideAllNonModalContentContext.Provider value={useBinding(() => 0, { id: 'defaultHideAllNonModalContent', detectChanges: true })}>
    {children}
  </HideAllNonModalContentContext.Provider>
);

export const HideAllNonModalContent = () => {
  const hideCount = useContext(HideAllNonModalContentContext);

  useEffect(() => {
    hideCount.set(hideCount.get() + 1);

    return () => {
      hideCount.set(hideCount.get() - 1);
    };
  });

  return null;
};
