// TODO: revisit default
export type VariantImageSource<ColorT extends string = string> = {
  isVariantImageSource: true;
  widthPx: number;
  heightPx: number;
} & (
  | { svgs?: Record<ColorT, string>; pngs?: undefined }
  | { pngs?: Record<ColorT, string>; svgs?: undefined }
  | { svgs?: Record<ColorT, string>; pngs?: Record<ColorT, string> }
);

export const isVariantImageSource = <ColorT extends string = string>(value: any): value is VariantImageSource<ColorT> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  value !== null && typeof value === 'object' && value.isVariantImageSource === true;

export const doesVariantImageSupportColor = (imageSource: VariantImageSource, color: string) =>
  (imageSource.svgs?.[color] ?? imageSource.pngs?.[color]) !== undefined;
