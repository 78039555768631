import { createContext, useContext, useEffect, useMemo } from 'react';
import type { ReadonlyBinding } from 'react-bindings';
import { BC, useBinding, useBindingEffect } from 'react-bindings';

import type { ChildrenProps } from '../types/ChildrenProps';
import type { Position2DPx } from '../types/Position2DPx';
import type { Size2DPx } from '../types/Size2DPx';

export interface ScrollableControls {
  /** Default position = `'nearest'` */
  scrollToElementWithId?: (elementId: string, position?: ScrollLogicalPosition) => void;
  scrollToTop?: () => void;
  scrollToYPx?: (yPx: number) => void;
}

export interface ScrollableParentInfo {
  id: string;
  parent?: ScrollableParentInfo;
  contentSize: ReadonlyBinding<Size2DPx>;
  didPullToRefresh: ReadonlyBinding;
  scrollableParentSize: ReadonlyBinding<Size2DPx>;
  scrollPosition: ReadonlyBinding<Position2DPx>;
  isScrolling: ReadonlyBinding<boolean>;
  controls: ScrollableControls;
}

const ScrollableParentContext = createContext<ScrollableParentInfo | undefined>(undefined);

export interface ScrollableParentProviderProps {
  id: string;
  contentSize: ReadonlyBinding<Size2DPx>;
  didPullToRefresh: ReadonlyBinding;
  isScrolling: ReadonlyBinding<boolean>;
  scrollableParentSize: ReadonlyBinding<Size2DPx>;
  scrollPosition: ReadonlyBinding<Position2DPx>;
  controls: ScrollableControls;
}

export const useScrollableParent = () => useContext(ScrollableParentContext);

export const ScrollableParentProvider = ({
  children,
  id,
  contentSize,
  didPullToRefresh,
  isScrolling,
  scrollableParentSize,
  scrollPosition,
  controls
}: ChildrenProps & ScrollableParentProviderProps) => {
  const parent = useScrollableParent();

  const value = useMemo<ScrollableParentInfo>(
    () => ({ id, parent, contentSize, didPullToRefresh, isScrolling, scrollableParentSize, scrollPosition, controls }),
    [contentSize, controls, didPullToRefresh, id, isScrolling, parent, scrollPosition, scrollableParentSize]
  );

  const isMounted = useBinding(() => false, { id: 'isMounted', detectChanges: true });
  useEffect(() => {
    isMounted.set(true);

    return () => isMounted.set(false);
  });

  return (
    <ScrollableParentContext.Provider value={value}>
      {/* Only rendering the children if the parent is mounted already because the children likely need access to the scrollable element */}
      {BC(isMounted, (isMounted) => (isMounted ? children : null))}
    </ScrollableParentContext.Provider>
  );
};

export const useOnDidPullToRefresh = (callback: () => void) => {
  const scrollableParent = useScrollableParent();

  useBindingEffect(scrollableParent?.didPullToRefresh, callback);
};
