import { StatusCodes } from 'http-status-codes';
import {
  setDefaultShouldRetryEvaluator,
  setOnRequestValidationErrorHandler,
  setOnResponseValidationErrorHandler
} from 'yaschema-api-fetcher';

import { ONE_SEC_MSEC } from '../../consts/time';

export const init = async () => {
  setOnRequestValidationErrorHandler(({ api, invalidPart, validationError }) => {
    console.warn(`HTTP request validation error for ${api.url}: ${invalidPart} - ${validationError}`);
  });

  setOnResponseValidationErrorHandler(({ api, invalidPart, validationError }) => {
    console.warn(`HTTP response validation error for ${api.url}: ${invalidPart} - ${validationError}`);
  });

  setDefaultShouldRetryEvaluator(async ({ api, retryCount, res }) => {
    if (!(api.isSafeToRetry ?? false)) {
      return false;
    }

    const status = res?.status ?? StatusCodes.REQUEST_TIMEOUT;
    if (retryCount >= (maxRetriesByHttpResponseStatus[status] ?? 0)) {
      return false;
    }

    return { afterDelayMSec: Math.min(retryCount * 300, 30 * ONE_SEC_MSEC) };
  });
};

// Helpers

const maxRetriesByHttpResponseStatus: Partial<Record<number, number>> = {
  [StatusCodes.BAD_GATEWAY]: Number.MAX_SAFE_INTEGER,
  [StatusCodes.GATEWAY_TIMEOUT]: Number.MAX_SAFE_INTEGER,
  [StatusCodes.INTERNAL_SERVER_ERROR]: 3,
  [StatusCodes.REQUEST_TIMEOUT]: Number.MAX_SAFE_INTEGER,
  [StatusCodes.SERVICE_UNAVAILABLE]: Number.MAX_SAFE_INTEGER,
  [StatusCodes.TOO_MANY_REQUESTS]: Number.MAX_SAFE_INTEGER
};
