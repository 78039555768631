import { createContext, useContext } from 'react';
import type { ReadonlyBinding } from 'react-bindings';
import { makeConstBinding } from 'react-bindings';

import type { ChildrenProps } from '../types/ChildrenProps';

const CenterMaxWidthPxContext = createContext(makeConstBinding(0, { id: 'defaultCenterMaxWidthPx' }));

export const useCenterMaxWidthPx = () => useContext(CenterMaxWidthPxContext);

export interface CenterMaxWidthPxProviderProps {
  maxWidthPx: ReadonlyBinding<number>;
}

export const CenterMaxWidthPxProvider = ({ children, maxWidthPx }: ChildrenProps & CenterMaxWidthPxProviderProps) => (
  <CenterMaxWidthPxContext.Provider value={maxWidthPx}>{children}</CenterMaxWidthPxContext.Provider>
);
