import type { ScrollableParentInfo } from '../context/scrollable-parent';

export const resolveScrollRoot = (scrollRoot: HTMLElement | string | undefined, scrollableParentId: ScrollableParentInfo | undefined) => {
  let output: HTMLElement | null = null;
  if (scrollRoot === undefined) {
    if (scrollableParentId?.id === undefined) {
      console.error('No scrollable parent found using useScrollableParent and no scrollRoot otherwise provided');
    } else {
      output = document.getElementById(scrollableParentId.id);
      if (output === null) {
        return null;
      }
    }
  } else if (typeof scrollRoot === 'string') {
    output = document.getElementById(scrollRoot);
    if (output === null) {
      return null;
    }
  } else {
    output = scrollRoot;
  }

  return output;
};
