import type { EmptyObject } from 'react-bindings';

import { makeRouteInfo } from '../makeRouteInfo';
import { todayRoute } from './todayRoute';

export const accountRoute = makeRouteInfo<EmptyObject, []>({
  path: ['account'],
  pathSchemas: {},
  defaultHistory: (context, _pathParams, _args = {}) => [todayRoute(context, {})],
  screen: async () => (await import('../../screens/AccountScreen')).AccountScreen()
});
