import { makeStringSubtypeArray, schema } from 'yaschema';

export const articlePermissionsCategories = makeStringSubtypeArray(
  'view',
  /** Make and commit content changes */
  'edit',
  /** Turns ads on/off */
  'change-ad-settings',
  /** I.e. publish/unpublish */
  'change-status',
  'delete',
  /** Confirm / deny / edit suggestions */
  'review-suggestions',
  /** Submit content changes for review */
  'suggest-edit'
);
export const articlePermissionCategorySchema = schema.string(...articlePermissionsCategories);
export type ArticlePermissionsCategory = typeof articlePermissionCategorySchema.valueType;
