import { setDefaultUrlBase, setUrlBaseForRouteType } from 'yaschema-api';

import { getConfig } from '../getConfig';

export const init = async () => {
  const config = getConfig();

  setDefaultUrlBase(config.api.baseUrls.default);

  if (config.api.baseUrls.auth !== undefined) {
    setUrlBaseForRouteType('auth', config.api.baseUrls.auth);
  }
};
