import type { Binding, ReadonlyBinding } from 'react-bindings';
import { BC, makeConstBinding, useBinding, useBindingEffect, useDerivedBinding, useFlattenedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { px, STD_ROW_SIZE_PX } from '../../consts/layout';
import { ONE_SEC_MSEC } from '../../consts/time';
import type { ToolbarStyle } from '../../context/navigation/types/ToolbarStyle';
import { useDocument } from '../../context/navigation/useDocument';
import { Toolbar } from './Toolbar';

export interface StickyToolbarProps {
  outHeightPx: Binding<number>;
  showSeparator: ReadonlyBinding<boolean>;
}

export const StickyToolbar = ({ outHeightPx, showSeparator }: StickyToolbarProps) => {
  const doc = useDocument();
  const classNames = useStyles();

  const toolbarStyle = useFlattenedBinding(doc, (doc) => doc?.toolbarStyle ?? defaultToolbarStyle, { id: 'toolbarStyle' });
  const wantsStickyToolbar = useDerivedBinding(toolbarStyle, (toolbarStyle): boolean => toolbarStyle === 'sticky', {
    id: 'wantsStickyToolbar'
  });

  const toolbarHeightPx = useBinding(() => STD_ROW_SIZE_PX, { id: 'toolbarHeightPx', detectChanges: true });
  useBindingEffect(
    { toolbarHeightPx, sticky: wantsStickyToolbar },
    ({ toolbarHeightPx, sticky }) => {
      outHeightPx.set(sticky ? toolbarHeightPx : 0);
    },
    { triggerOnMount: true }
  );

  return BC(wantsStickyToolbar, (sticky) => (
    <div
      className={classNames.main}
      style={{ transform: sticky ? 'translate(0)' : `translate(0, ${px(toolbarHeightPx.get())})`, opacity: sticky ? '100%' : 0 }}
    >
      <Toolbar outHeightPx={toolbarHeightPx} showSeparator={showSeparator} needsSafeSides="rbl" />
    </div>
  ));
};

// Helpers

const useStyles = createUseStyles({
  main: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    transition: `transform ${ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s, opacity ${
      ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC
    }s`
  }
});

const defaultToolbarStyle = makeConstBinding<ToolbarStyle>('hidden', { id: 'defaultToolbarStyle' });
