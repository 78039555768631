import type { CSSProperties } from 'react';
import { useEffect } from 'react';
import { BC, useBinding } from 'react-bindings';
import { useInView } from 'react-intersection-observer';
import { createUseStyles } from 'react-jss';

import { isSsr } from '../../config/ssr';
import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { ONE_SEC_MSEC } from '../../consts/time';
import { useDomRenderingMode } from '../../context/dom-rendering';
import { useScrollableParent } from '../../context/scrollable-parent';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { FlexParentStyleField } from '../../types/styles/flex-parent-style';
import { pickFlexStyleProps } from '../../types/styles/flex-parent-style';
import { resolveScrollRoot } from '../../utils/resolveScrollRoot';
import { useUuid } from '../../utils/uuid';
import { Flex } from '../layout/Flex';

const defaultTransitionDurationMSec = ELEMENT_LEVEL_TRANSITION_DURATION_MSEC;

export interface PopInProps extends Pick<CSSProperties, FlexParentStyleField> {
  /** @defaultValue `false` */
  immediate?: boolean;

  /**
   * The default transition time shared by opacity and size changes
   *
   * @defaultValue `ELEMENT_LEVEL_TRANSITION_DURATION_MSEC`
   */
  transitionDurationMSec?: number;
  style?: CSSProperties;

  /** An element or the ID of an element.  If `undefined`, `useScrollableParent` is used. */
  scrollRoot?: HTMLElement | string;
}

export const PopIn = (props: ChildrenProps & PopInProps) => {
  const { children, immediate = false, transitionDurationMSec = defaultTransitionDurationMSec, style, scrollRoot } = props;
  const flexProps = pickFlexStyleProps(props);

  const mode = useDomRenderingMode();
  const scrollableParent = useScrollableParent();
  const classNames = useStyles({ transitionDurationMSec });
  const uid = useUuid();

  const scaleIn = useBinding<boolean>(() => false, { id: 'scaleIn', detectChanges: true });

  const skip = mode === 'no-render' || isSsr();
  const resolvedScrollRoot = !immediate ? resolveScrollRoot(scrollRoot, scrollableParent) : undefined;
  const { inView, ref } = useInView({
    initialInView: false,
    root: resolvedScrollRoot,
    threshold: 0.1,
    skip
  });

  useEffect(() => {
    if (!inView && !immediate) {
      return; // Not ready
    }

    switch (mode) {
      case 'no-render':
        return;
      case 'render':
        break;
    }

    if (!scaleIn.get()) {
      window.requestAnimationFrame(() => scaleIn.set(true));
    }
  });

  return BC(scaleIn, (scaleIn) => (
    <Flex
      id={uid}
      fwdRef={ref}
      className={`PopIn ${classNames.common} ${scaleIn ? classNames.scaledIn : classNames.scaledOut}`}
      flexDirection="column"
      {...flexProps}
      style={style}
    >
      {children}
    </Flex>
  ));
};

// Helpers

interface StylesArgs {
  transitionDurationMSec: number;
}

const useStyles = createUseStyles({
  common: ({ transitionDurationMSec }: StylesArgs) => ({
    transition: `transform ${transitionDurationMSec / ONE_SEC_MSEC}s`
  }),
  scaledIn: {
    transform: 'scale(1)'
  },
  scaledOut: {
    transform: 'scale(0)'
  }
});
