import { schema } from 'yaschema';

import { MAX_ARTICLE_PARAGRAPH_LENGTH } from '../../../../consts/limits';
import { codeLanguageSchema } from '../../CodeLanguage';
import { makeArticleParagraphSchema } from './internal/makeArticleParagraphSchema';

export const articleCodeParagraphSchema = makeArticleParagraphSchema(
  'code',
  schema.object({
    language: codeLanguageSchema,
    content: schema.string().setAllowedLengthRange(1, MAX_ARTICLE_PARAGRAPH_LENGTH)
  })
);
export type ArticleCodeParagraph = typeof articleCodeParagraphSchema.valueType;
