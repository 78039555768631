import type { apiData } from 'lf-blog-schema';
import React, { useContext } from 'react';
import { makeBinding, makeConstBinding, useBindingEffect } from 'react-bindings';

import { getConfig } from '../config/getConfig';
import { SCREEN_LEVEL_TRANSITION_DURATION_MSEC } from '../consts/animation';
import { checkAuth } from '../tasks/auth/checkAuth';
import type { AuthLevel } from '../types/AuthLevel';
import type { ChildrenProps } from '../types/ChildrenProps';
import { useIsScreenCurrent } from './navigation/screen-state';

const AuthCheckContext = React.createContext(makeConstBinding<apiData.AuthCheck | undefined>(undefined, { id: 'defaultAuthCheck' }));

const hardRecheckAuthSignal = makeBinding(() => undefined, { id: 'hardRecheckAuthSignal' });
const softRecheckAuthSignal = makeBinding(() => undefined, { id: 'softRecheckAuthSignal' });

/** Call if the auth state has likely changed, ex. after attempting sign in */
export const recheckAuth = (mode: 'hard' | 'soft') => {
  console.log('recheckAuth');
  switch (mode) {
    case 'hard':
      hardRecheckAuthSignal.set(undefined);
      break;
    case 'soft':
      softRecheckAuthSignal.set(undefined);
      break;
  }
};

export const useAuthenticationCheck = () => useContext(AuthCheckContext);

export const useIfAuthLevel = (disallowedAuthLevel: AuthLevel | undefined, callback: () => void) => {
  const config = getConfig();
  const authCheck = useAuthenticationCheck();
  const isCurrent = useIsScreenCurrent();

  useBindingEffect(
    { authCheck, isCurrent },
    ({ authCheck, isCurrent }) => {
      if (!isCurrent || authCheck === undefined || disallowedAuthLevel === undefined) {
        return;
      }

      let authLevel: AuthLevel = 'full';
      if (authCheck.sessionId === undefined) {
        authLevel = 'none';
      } else if (authCheck.requiresMfa === true) {
        authLevel = 'partial';
      }

      if (authLevel === disallowedAuthLevel) {
        config.loggers.default.debug?.(`Session has disallowed auth level. current = ${authLevel}, disallowed = ${disallowedAuthLevel}`);
        callback();
      }
    },
    // Delaying the checks here a bit because if we want to push a new screen as part of an authentication state change, we don't want to
    // pop the old screen at the same time -- this is very evident when using the Sitemap dev tools that force authentication state changes
    { triggerOnMount: true, limitMSec: SCREEN_LEVEL_TRANSITION_DURATION_MSEC }
  );
};

export const useIfNotAuthLevel = (requiredAuthLevel: AuthLevel | 'any', callback: (currentAuthLevel: AuthLevel) => void) => {
  const config = getConfig();
  const authCheck = useAuthenticationCheck();
  const isCurrent = useIsScreenCurrent();

  useBindingEffect(
    { authCheck, isCurrent },
    ({ authCheck, isCurrent }) => {
      if (!isCurrent || authCheck === undefined || requiredAuthLevel === 'any') {
        return;
      }

      let authLevel: AuthLevel = 'full';
      if (authCheck.sessionId === undefined) {
        authLevel = 'none';
      } else if (authCheck.requiresMfa === true) {
        authLevel = 'partial';
      }

      if (authLevel !== requiredAuthLevel) {
        config.loggers.default.debug?.(`Session doesn't have required auth level. current = ${authLevel}, required = ${requiredAuthLevel}`);
        callback(authLevel);
      }
    },
    // Delaying the checks here a bit because if we want to push a new screen as part of an authentication state change, we don't want to
    // pop the old screen at the same time -- this is very evident when using the Sitemap dev tools that force authentication state changes
    { triggerOnMount: true, limitMSec: SCREEN_LEVEL_TRANSITION_DURATION_MSEC }
  );
};

export const AuthCheckProvider = ({ children }: ChildrenProps) => {
  const authCheck = checkAuth.useWaitable({
    context: undefined,
    args: [],
    softResetBindings: softRecheckAuthSignal,
    hardResetBindings: hardRecheckAuthSignal
    // This should never fail since it's always retried internally
  });

  return <AuthCheckContext.Provider value={authCheck.value}>{children}</AuthCheckContext.Provider>;
};
