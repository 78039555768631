import { makeStringSubtypeArray, schema } from 'yaschema';

export const articleSearchSortOptions = makeStringSubtypeArray(
  'highlighted',
  'create-time-asc',
  'create-time-desc',
  'update-time-asc',
  'update-time-desc',
  'score-asc',
  'score-desc'
);
export const articleSearchSortSchema = schema.string(...articleSearchSortOptions);
export type ArticleSearchSort = typeof articleSearchSortSchema.valueType;
