import { makeBinding, useBinding } from 'react-bindings';

import { SCREEN_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import type { TransitionInfo } from '../../types/transitioning';
import type { DocumentNavigation } from './types/DocumentNavigation';
import type { NavDocument, NavDocumentHook } from './types/NavDocument';

export const useCreateDocumentNavigation = (): DocumentNavigation => {
  const lastTransitionInfo = makeBinding<TransitionInfo>(
    () => ({ globalCount: getNextGlobalTransitionCount(), mode: 'push', durationMSec: 0 }),
    { id: 'lastTransitionInfo' }
  );
  const current = useBinding<{ id: string; useDoc: NavDocumentHook } | undefined>(() => undefined, { id: 'current' });
  const currentMounted = useBinding<NavDocument | undefined>(() => undefined, { id: 'currentMounted' });

  return {
    lastTransitionInfo,
    transition: (
      useDoc,
      {
        id,
        transitionMode: pushTransitionMode = 'push',
        transitionDurationMSec: pushTransitionDurationMSec = SCREEN_LEVEL_TRANSITION_DURATION_MSEC
      }
    ) => {
      if (pushTransitionMode !== 'continue') {
        lastTransitionInfo.set({
          globalCount: getNextGlobalTransitionCount(),
          mode: pushTransitionMode,
          durationMSec: pushTransitionDurationMSec
        });
      }

      current.set({ id, useDoc });
    },
    current,
    currentMounted
  };
};

// Helpers

let globalTransitionCount = 0;

const getNextGlobalTransitionCount = () => {
  globalTransitionCount += 1;

  return globalTransitionCount;
};
