import { resolveStringy } from 'linefeedr-localization';
import type { CSSProperties } from 'react';
import { BC, type Binding, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { br, sp } from '../../consts/layout';
import { resolveColorVariant, resolveColorVariantClass } from '../../consts/palette';
import { useT } from '../../context/useT';
import type { ColorVariant, NamedColorVariant } from '../../types/ColorVariant';
import { isNamedColorVariant } from '../../types/ColorVariant';
import type { TabInfo } from '../../types/TabInfo';
import { doesVariantImageSupportColor } from '../../types/VariantImageSource';
import { Button } from '../core/Button';
import { Icon } from '../core/Icon';
import { Text } from '../core/Text';
import { Row } from '../layout/Row';
import { SafeSidesRow } from '../layout/SafeSidesRow';

export type TabSelectionVariant = 'plain' | 'outlined';

export interface TabSelectionProps<T extends string> {
  selectedTab: Binding<T>;
  tabs: TabInfo<T>[];
  /** @defaultValue `'primary'` */
  color?: ColorVariant;
  /** @defaultValue `'plain'` */
  variant?: TabSelectionVariant;
}

export const TabSelection = <T extends string>({ selectedTab, tabs, color = 'primary', variant = 'plain' }: TabSelectionProps<T>) => {
  const classNames = useStyles({ color });

  return (
    <SafeSidesRow needsSafeSides="rl" className={`hideScrollIndicators ${classNames.main}`} style={stylesByVariant[variant]}>
      <Row columnGap={columnGapsByVariant[variant]} className={classNames[`contentRow-${variant}`]}>
        {tabs.map((tab) => (
          <Tab key={tab.value} selectedTab={selectedTab} tab={tab} color={color} />
        ))}
      </Row>
    </SafeSidesRow>
  );
};

// Helpers

const columnGapsByVariant: Record<TabSelectionVariant, CSSProperties['columnGap']> = {
  plain: sp(1),
  outlined: '2px'
};

const useStyles = createUseStyles({
  main: {
    maxWidth: '100%',
    overflowX: 'auto'
  },
  'contentRow-plain': {},
  'contentRow-outlined': ({ color }: { color: ColorVariant }) => ({
    border: `1px solid ${resolveColorVariant(color)}`,
    borderRadius: br(1),
    padding: '3px'
  })
});

const stylesByVariant: Record<TabSelectionVariant, Omit<CSSProperties, 'padding'>> = {
  plain: {
    paddingTop: sp(1),
    paddingRight: sp(1),
    paddingBottom: sp(1),
    paddingLeft: sp(1)
  },
  outlined: {}
};

interface TabProps<T extends string> {
  selectedTab: Binding<T>;
  tab: TabInfo<T>;
  color: ColorVariant;
}

const Tab = <T extends string>({ selectedTab, tab, color }: TabProps<T>) => {
  const t = useT();

  const isSelected = useDerivedBinding(selectedTab, (selectedTab) => selectedTab === tab.value, { id: 'isSelected', deps: [tab.value] });

  const onClick = useCallbackRef(() => selectedTab.set(tab.value));

  const selectedIconVariant: NamedColorVariant | 'black' | 'white' = resolveColorVariantClass(color) === 'dark' ? 'white' : 'black';
  const nonSelectedIconVariant: NamedColorVariant | 'black' | 'white' =
    isNamedColorVariant(color) && tab.icon !== undefined && doesVariantImageSupportColor(tab.icon, color)
      ? color
      : resolveColorVariantClass(color) === 'dark'
      ? 'black'
      : 'white';

  return BC(isSelected, (isSelected) => (
    <Button
      color={color}
      variant={isSelected ? 'contained' : 'padded-text'}
      style={{ columnGap: sp(1), flexShrink: 0, padding: sp(1) }}
      onClick={onClick}
    >
      {tab.icon !== undefined ? (
        <Icon src={tab.icon} variant={isSelected ? selectedIconVariant : nonSelectedIconVariant} alt={tab.alt} />
      ) : undefined}
      {tab.title !== undefined ? <Text>{resolveStringy(tab.title, t)}</Text> : undefined}
    </Button>
  ));
};
