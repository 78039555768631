import { resolveStringy, type Stringy } from 'linefeedr-localization';
import { type ChangeEvent, type CSSProperties, Fragment, useMemo } from 'react';
import { BC, type Binding, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';
import { type Validator } from 'react-validatables';

import { imageIconsSelectArrow } from '../../assets/icons/select-arrow';
import { br, px, sp, SP_PX, STD_ICON_SIZE_PX } from '../../consts/layout';
import { palette, resolveColorVariant } from '../../consts/palette';
import { typographyStyles } from '../../consts/typography';
import { useIsScreenLocked } from '../../context/screen-locker';
import { useT } from '../../context/useT';
import type { RealReactNode } from '../../types/RealReactNode';
import type { RegularImageSource } from '../../types/RegularImageSource';
import type { ValidationErrorInfo } from '../../types/ValidationErrorInfo';
import { isVariantImageSource, type VariantImageSource } from '../../types/VariantImageSource';
import { CrossFade } from '../animation/CrossFade';
import { Icon } from '../core/Icon';
import type { TextProps } from '../core/Text';
import { Text } from '../core/Text';
import { Col } from '../layout/Col';
import { Row } from '../layout/Row';
import { ValidationError } from './ValidationError';

export interface SelectFieldChoiceGroup<T> {
  title?: Stringy;
  choices: Array<SelectFieldChoice<T>>;
}

export type SelectFieldChoice<T> = T extends string
  ? T | { value: T; title: Stringy; icon?: RegularImageSource | VariantImageSource<'black'> }
  : { value: T; title: Stringy; icon?: RegularImageSource | VariantImageSource<'black'> };

export interface SelectFieldProps<T> {
  id?: string;
  value: Binding<T>;
  valueToString?: (value: T) => string;
  choiceGroups: SelectFieldChoiceGroup<T>[];
  title?: string;

  validator?: Validator;

  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  /** @defaultValue `false` */
  flexHeight?: boolean;

  fieldStyle?: CSSProperties;
  titleProps?: TextProps;
  validationErrorProps?: TextProps;
  /** @defaultValue `false` */
  hidePopupIndicator?: boolean;

  /** Content drawn to the left of the input field */
  pre?: RealReactNode;
  /** Content drawn to the right of the input field */
  post?: RealReactNode;

  onBlur?: () => void;
  onFocus?: () => void;
}

export const SelectField = <T,>({
  id,
  value,
  valueToString = String,
  choiceGroups,
  title,
  validator,
  width,
  height,
  flexHeight = false,
  fieldStyle,
  titleProps,
  validationErrorProps,
  hidePopupIndicator = false,
  pre,
  post,
  onBlur,
  onFocus
}: SelectFieldProps<T>) => {
  const isScreenLocked = useIsScreenLocked();
  const classNames = useStyles();
  const t = useT();

  const valuesByValueString = useMemo(
    () =>
      choiceGroups.reduce((out: Record<string, T>, choiceGroup) => {
        for (const choice of choiceGroup.choices) {
          if (typeof choice === 'string') {
            out[valueToString(choice as T)] = choice as T;
          } else {
            out[valueToString(choice.value)] = choice.value;
          }
        }
        return out;
      }, {}),
    [choiceGroups, valueToString]
  );

  const iconsByValueString = useMemo(
    () =>
      choiceGroups.reduce((out: Record<string, RegularImageSource | VariantImageSource<'black'> | undefined>, choiceGroup) => {
        for (const choice of choiceGroup.choices) {
          if (typeof choice === 'string') {
            out[valueToString(choice as T)] = undefined;
          } else {
            out[valueToString(choice.value)] = choice.icon;
          }
        }
        return out;
      }, {}),
    [choiceGroups, valueToString]
  );

  const onChange = useCallbackRef((event: ChangeEvent<HTMLSelectElement>) => {
    if (isScreenLocked.get()) {
      return;
    }

    value.set(valuesByValueString[event.currentTarget.value]);
  });

  const shouldShowTitle = (title?.length ?? 0) > 0;
  const validationError = useDerivedBinding(
    { value, result: validator?.value },
    ({ result }): ValidationErrorInfo => ({
      error: result?.validationError,
      level: result?.validationError !== undefined && value.isModified() ? 'error' : 'warning'
    }),
    { id: 'shouldShowValidationError', detectInputChanges: false }
  );

  const optionNodes = useMemo(
    () =>
      choiceGroups.map((choiceGroup, groupIndex) => {
        const choiceNodes = choiceGroup.choices.map((choice) => {
          if (typeof choice === 'string') {
            const valueString = valueToString(choice as T);
            return (
              <option key={valueString} value={valueString}>
                {choice}
              </option>
            );
          } else {
            const valueString = valueToString(choice.value);
            return (
              <option key={valueString} value={valueString}>
                {resolveStringy(choice.title, t)}
              </option>
            );
          }
        });

        return (
          <Fragment key={groupIndex}>
            {groupIndex > 0 ? <option disabled={true} /> : null}
            {choiceGroup.title !== undefined ? <option disabled={true}>{resolveStringy(choiceGroup.title, t)}</option> : null}
            {choiceNodes}
          </Fragment>
        );
      }),
    [choiceGroups, t, valueToString]
  );

  return (
    <Col style={{ width, height, flex: flexHeight ? 1 : undefined }}>
      <CrossFade style={{ width: '100%', height: shouldShowTitle ? undefined : '0px' }} transitioningPartStyle={{ width: '100%' }}>
        {shouldShowTitle ? (
          <Text component="div" color="textSecondary" variant="body2" style={{ marginBottom: sp(0.5) }} {...titleProps}>
            {title}
          </Text>
        ) : null}
      </CrossFade>

      <Row
        columnGap={sp(1)}
        alignItems="center"
        style={{
          width: (width ?? 'auto') !== 'auto' ? '100%' : undefined,
          flex: flexHeight || (height ?? 'auto') !== 'auto' ? 1 : undefined
        }}
      >
        {pre ?? null}
        {BC(value, (value) => {
          const icon = iconsByValueString[valueToString(value)];
          return icon !== undefined ? (
            <CrossFade>
              {isVariantImageSource<'black'>(icon) ? (
                <Icon src={icon} variant="black" />
              ) : (
                <Icon src={icon} width={px(STD_ICON_SIZE_PX)} height={px(STD_ICON_SIZE_PX)} />
              )}
            </CrossFade>
          ) : null;
        })}
        <div
          style={{
            display: 'inline-block',
            width: (width ?? 'auto') !== 'auto' ? '100%' : undefined,
            flex: flexHeight || (height ?? 'auto') !== 'auto' ? 1 : undefined
          }}
        >
          {!hidePopupIndicator ? (
            <Row alignItems="center" className={classNames.iconContainer}>
              <Icon src={imageIconsSelectArrow} variant="textSecondary" />
            </Row>
          ) : null}
          {BC({ value, validationError }, ({ value, validationError }) => (
            <select
              id={id}
              value={valueToString(value)}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              className={classNames.field}
              style={{
                borderColor: validationError.level === 'error' ? resolveColorVariant('error') : undefined,
                width: (width ?? 'auto') !== 'auto' ? '100%' : undefined,
                flex: flexHeight || (height ?? 'auto') !== 'auto' ? 1 : undefined,
                paddingRight: hidePopupIndicator ? sp(1) : sp(3),
                ...fieldStyle
              }}
            >
              {optionNodes}
            </select>
          ))}
        </div>
        {post ?? null}
      </Row>

      <ValidationError validationError={validationError} {...validationErrorProps} />
    </Col>
  );
};

// Helpers

const useStyles = createUseStyles({
  field: {
    appearance: 'none',
    backgroundColor: palette.inputBackground,
    border: `1px solid ${resolveColorVariant('inputBorder')}`,
    borderRadius: br(0.5),
    color: palette.textPrimary,
    padding: `${px(SP_PX - 1)} ${sp(1)}`,
    ...typographyStyles.body1,
    '&::placeholder': {
      color: resolveColorVariant('placeholderText')
    }
  },
  iconContainer: {
    position: 'absolute',
    right: 0,
    width: px(imageIconsSelectArrow.widthPx),
    height: '100%',
    zIndex: 1,
    pointerEvents: 'none'
  }
});
