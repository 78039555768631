import React, { useMemo, useRef } from 'react';
import type { TypeOrDeferredType } from 'react-bindings';
import { resolveTypeOrDeferredType, useCallbackRef } from 'react-bindings';
import { useInView } from 'react-intersection-observer';

import { isSsr } from '../../config/ssr';
import { px, STD_ROW_SIZE_PX } from '../../consts/layout';
import { useContainerHeight } from '../../context/container-height';
import { DomRenderingModeProvider, useDomRenderingMode } from '../../context/dom-rendering';
import { useScrollableParent } from '../../context/scrollable-parent';
import { useDomRef } from '../../context/useDomRef';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { RealReactNode } from '../../types/RealReactNode';
import { resolveScrollRoot } from '../../utils/resolveScrollRoot';
import { DomResizeDetector } from './DomResizeDetector';

const DEFAULT_ESTIMATED_HEIGHT_PX = STD_ROW_SIZE_PX;
const DEFAULT_MAX_TOTAL_ELEMENTS = 64;
const DEFAULT_OVERSCROLL_PX = 320;
const MIN_CHUNK_SIZE = 1;

export interface VirtualizedColProps<_T> {
  /** An element or the ID of an element.  If `undefined`, `useScrollableParent` is used. */
  scrollRoot?: HTMLElement | string;

  /**
   * This will only be considered once per component instance
   *
   * @defaultValue `Math.ceil((containerHeight ?? window.innerHeight) / estimatedHeightPx)`
   */
  estimatedCountAboveTheFold?: number;
  /** @defaultValue `44` */
  estimatedHeightPx?: number;
  /** @defaultValue `100` */
  maxTotalElements?: number;
  /** @defaultValue `320` */
  overscrollPx?: number;
}

/** While this orients contents vertically, sub-elements may be arbitrarily wrapped to optimize and postpone rendering */
export const VirtualizedCol = <T,>({
  children,
  scrollRoot,
  estimatedCountAboveTheFold,
  estimatedHeightPx = DEFAULT_ESTIMATED_HEIGHT_PX,
  maxTotalElements = DEFAULT_MAX_TOTAL_ELEMENTS,
  overscrollPx = DEFAULT_OVERSCROLL_PX
}: ChildrenProps & VirtualizedColProps<T>) => {
  const containerHeight = useContainerHeight();
  const scrollableParent = useScrollableParent();

  const preferredChunkSize = useMemo(
    () => Math.max(MIN_CHUNK_SIZE, Math.ceil((containerHeight?.get() ?? window.innerHeight) / estimatedHeightPx)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const allChildren = React.Children.toArray(children) as RealReactNode[];

  // Only considered once per component instance
  const firstChunkSize = useMemo(
    () => estimatedCountAboveTheFold ?? preferredChunkSize,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preferredChunkSize]
  );

  const firstChunk = firstChunkSize > 0 ? allChildren.slice(0, firstChunkSize) : [];
  const restChildren = firstChunkSize > 0 ? allChildren.slice(firstChunkSize) : allChildren;

  const resolvedScrollRoot = resolveScrollRoot(scrollRoot, scrollableParent);

  return isSsr() ? (
    <div className="VirtualizedCol">{children}</div>
  ) : (
    <div className="VirtualizedCol">
      {firstChunk.length > 0 ? (
        <VirtualizedChunk
          scrollRoot={resolvedScrollRoot!}
          chunk={firstChunk}
          estimatedElemHeightPx={estimatedHeightPx}
          assumeVisible={true}
          overscrollPx={overscrollPx}
        />
      ) : null}
      {restChildren.length > 0 ? (
        <VirtualizedElem
          scrollRoot={resolvedScrollRoot!}
          estimatedHeightPx={restChildren.length * estimatedHeightPx}
          assumeVisible={false}
          overscrollPx={overscrollPx}
        >
          {() => (
            <BelowTheFold
              scrollRoot={resolvedScrollRoot!}
              maxTotalElements={maxTotalElements - firstChunk.length}
              preferredChunkSize={preferredChunkSize}
              estimatedElemHeightPx={estimatedHeightPx}
              overscrollPx={overscrollPx}
            >
              {restChildren}
            </BelowTheFold>
          )}
        </VirtualizedElem>
      ) : null}
    </div>
  );
};

// Helpers

const BelowTheFold = ({
  children,
  scrollRoot,
  maxTotalElements,
  preferredChunkSize,
  estimatedElemHeightPx,
  overscrollPx
}: {
  children: RealReactNode[];
  scrollRoot: HTMLElement;
  maxTotalElements: number;
  preferredChunkSize: number;
  estimatedElemHeightPx: number;
  overscrollPx: number;
}) => {
  if (maxTotalElements <= preferredChunkSize) {
    return (
      <VirtualizedElem
        scrollRoot={scrollRoot}
        estimatedHeightPx={children.length * estimatedElemHeightPx}
        assumeVisible={false}
        overscrollPx={overscrollPx}
      >
        {() => (
          <VirtualizedChunk
            scrollRoot={scrollRoot}
            chunk={children}
            estimatedElemHeightPx={estimatedElemHeightPx}
            assumeVisible={false}
            overscrollPx={overscrollPx}
          />
        )}
      </VirtualizedElem>
    );
  } else {
    const splitSize = Math.ceil(maxTotalElements / 2);
    const frontChildren = children.length > splitSize ? children.slice(0, splitSize) : children;
    const backChildren = children.length > splitSize ? children.slice(splitSize) : [];

    return (
      <>
        <BelowTheFold
          scrollRoot={scrollRoot}
          maxTotalElements={splitSize}
          preferredChunkSize={preferredChunkSize}
          estimatedElemHeightPx={estimatedElemHeightPx}
          overscrollPx={overscrollPx}
        >
          {frontChildren}
        </BelowTheFold>
        {backChildren.length > 0 ? (
          <VirtualizedElem
            scrollRoot={scrollRoot}
            estimatedHeightPx={backChildren.length * estimatedElemHeightPx}
            assumeVisible={false}
            overscrollPx={overscrollPx}
          >
            {() => (
              <BelowTheFold
                scrollRoot={scrollRoot}
                maxTotalElements={maxTotalElements - splitSize}
                preferredChunkSize={preferredChunkSize}
                estimatedElemHeightPx={estimatedElemHeightPx}
                overscrollPx={overscrollPx}
              >
                {backChildren}
              </BelowTheFold>
            )}
          </VirtualizedElem>
        ) : null}
      </>
    );
  }
};

const VirtualizedChunk = ({
  scrollRoot,
  chunk,
  estimatedElemHeightPx,
  assumeVisible,
  overscrollPx
}: {
  scrollRoot: HTMLElement;
  chunk: RealReactNode[];
  estimatedElemHeightPx: number;
  assumeVisible: boolean;
  overscrollPx: number;
}) =>
  chunk.map((child, index) => (
    <VirtualizedElem
      key={index}
      scrollRoot={scrollRoot}
      estimatedHeightPx={estimatedElemHeightPx}
      assumeVisible={assumeVisible}
      overscrollPx={overscrollPx}
    >
      {child}
    </VirtualizedElem>
  ));

const VirtualizedElem = ({
  children,
  scrollRoot,
  estimatedHeightPx,
  assumeVisible,
  overscrollPx
}: {
  children: TypeOrDeferredType<RealReactNode>;
  scrollRoot: HTMLElement;
  estimatedHeightPx: number;
  assumeVisible: boolean;
  overscrollPx: number;
}) => {
  const mode = useDomRenderingMode();

  const domRef = useDomRef<HTMLDivElement>();
  const domRefContent = useDomRef<HTMLDivElement>();

  const lastKnownHeight = useRef(Math.max(1, estimatedHeightPx));
  const hasEverBeenInView = useRef(false);

  const skip = mode === 'no-render' || isSsr();
  const { inView, ref } = useInView({
    initialInView: isSsr() ? assumeVisible : assumeVisible && !skip,
    root: scrollRoot,
    rootMargin: `${px(hasEverBeenInView.current ? overscrollPx : 0)} 0px`,
    skip
  });

  if (inView) {
    hasEverBeenInView.current = true;
  }

  const onResize = useCallbackRef((_width: number | undefined, height: number | undefined) => {
    const newHeight = Math.max(1, Math.round(height ?? 0));
    if (newHeight === lastKnownHeight.current) {
      return;
    }

    lastKnownHeight.current = newHeight;

    const elem = domRef.current;
    if (elem === null) {
      return;
    }

    requestAnimationFrame(() => {
      elem.style.height = px(newHeight);
    });
  });

  const innerMode = mode === 'render' && inView ? 'render' : 'no-render';

  const updateRefs = useCallbackRef((node?: HTMLDivElement | null | undefined) => {
    ref(node);
    domRef.current = node ?? null;
  });

  return (
    <div ref={updateRefs} style={{ height: px(lastKnownHeight.current) }}>
      {inView || hasEverBeenInView.current ? (
        <DomRenderingModeProvider mode={innerMode}>
          <div ref={domRefContent} className={`VirtualizedColElem-${innerMode}`}>
            <DomResizeDetector handleWidth={false} onResize={onResize} targetRef={domRefContent} />
            {resolveTypeOrDeferredType(children)}
          </div>
        </DomRenderingModeProvider>
      ) : null}
    </div>
  );
};
