import type { EmptyObject } from 'react-bindings';

import { makeRouteInfo } from '../makeRouteInfo';

export const todayRoute = makeRouteInfo<EmptyObject, []>({
  path: ['today'],
  pathSchemas: {},
  fixedHistory: [],
  screen: async () => (await import('../../screens/TodayScreen')).TodayScreen()
});
