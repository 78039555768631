import { BC, useBinding, useBindingEffect, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { Button } from '../../components/core/Button';
import { Text } from '../../components/core/Text';
import { Row } from '../../components/layout/Row';
import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC, SCREEN_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { br, DESKTOP_MODE_MIN_SIZE_PX, px, sp, SP_PX } from '../../consts/layout';
import { palette, resolveColorVariant } from '../../consts/palette';
import { ONE_SEC_MSEC } from '../../consts/time';
import { DEV_BUTTON_Z_INDEX, DEV_SIDEBAR_GLASSPANE_Z_INDEX, DEV_SIDEBAR_Z_INDEX } from '../../consts/z-index';
import { ContainerProvider } from '../../context/ContainerProvider';
import { SafeAreaInsetsEliminator, SafeAreaInsetsProvider, useSafeAreaInsets } from '../../context/safe-area';
import { useBrowserSize } from '../../context/useBrowserSize';
import { VisualViewportSizeProvider } from '../../context/visual-viewport-size';
import type { ChildrenProps } from '../../types/ChildrenProps';
import { getDevToolValue } from '../tools/dev-tools-support';
import { deviceSizeDevTool } from '../tools/deviceSizeDevTool';
import { DevModeMenu } from './DevModeMenu';

export const DevModeContainer = ({ children }: ChildrenProps) => {
  const browserSize = useBrowserSize();
  const classNames = useStyles();

  const deviceSize = getDevToolValue(deviceSizeDevTool);

  const isDesktopMode = useDerivedBinding(
    browserSize,
    (browserSize) => Math.min(browserSize.widthPx, browserSize.heightPx) >= DESKTOP_MODE_MIN_SIZE_PX,
    { id: 'isDesktopMode' }
  );
  const showMobileSidebar = useBinding(() => false, { id: 'showMobileSidebar', detectChanges: true });
  const toggleMobileSidebar = useCallbackRef(() => {
    console.log('clicked');
    showMobileSidebar.set(!showMobileSidebar.get());
  });

  useBindingEffect(isDesktopMode, () => showMobileSidebar.set(false));

  return (
    <ContainerProvider>
      {BC(isDesktopMode, (isDesktopMode) =>
        !isDesktopMode ? (
          <VisualViewportSizeProvider>
            <SafeAreaInsetsProvider>
              <DevButton onClick={toggleMobileSidebar} />
              {BC(showMobileSidebar, (show) => (
                <>
                  <Row className={`${classNames.mobileSidebar} ${show ? classNames.mobileSidebarShow : classNames.mobileSidebarHide}`}>
                    <SafeAreaInsetsEliminator eliminateSides="r">
                      <DevModeMenu isDesktopMode={false} width="100%" />
                    </SafeAreaInsetsEliminator>
                  </Row>
                  {/* TODO: animate */}
                  {show ? <div className={classNames.mobileSidebarGlassPane} onClick={toggleMobileSidebar} /> : null}
                </>
              ))}
            </SafeAreaInsetsProvider>
          </VisualViewportSizeProvider>
        ) : null
      )}
      <Row className={`DevModeContainer ${classNames.main}`}>
        {BC(isDesktopMode, (isDesktopMode) => (isDesktopMode ? <DevModeMenu isDesktopMode={true} width="40%" /> : null))}
        <Row className={classNames.appContainer} justifyContent="center" alignItems="center">
          {BC(
            { isDesktopMode, deviceSize },
            ({
              isDesktopMode,
              deviceSize: {
                size: { widthPx, heightPx },
                landscapeMode
              }
            }) => {
              const defaultWidth = !isDesktopMode || widthPx === 0 ? '100%' : px(widthPx);
              const defaultHeight = !isDesktopMode || heightPx === 0 ? '100%' : px(heightPx);
              const width = landscapeMode ? defaultHeight : defaultWidth;
              const height = landscapeMode ? defaultWidth : defaultHeight;

              return (
                <Row
                  className={classNames.app}
                  justifyContent="stretch"
                  alignItems="stretch"
                  style={{ width, height, flex: widthPx === 0 ? 1 : undefined }}
                >
                  <VisualViewportSizeProvider disabled={isDesktopMode}>
                    <SafeAreaInsetsProvider disabled={isDesktopMode}>{children}</SafeAreaInsetsProvider>
                  </VisualViewportSizeProvider>
                </Row>
              );
            }
          )}
        </Row>
      </Row>
    </ContainerProvider>
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    alignItems: 'stretch',
    flex: 1
  },
  mobileSidebar: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '40%',
    minWidth: '280px',
    maxWidth: '360px',
    zIndex: DEV_SIDEBAR_Z_INDEX,
    transition: `transform ${ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s`
  },
  mobileSidebarGlassPane: {
    backgroundColor: resolveColorVariant(palette.glassPane),
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: DEV_SIDEBAR_GLASSPANE_Z_INDEX
  },
  mobileSidebarHide: {
    transform: 'translate(-100%)'
  },
  mobileSidebarShow: {
    transform: 'translate(0px)'
  },
  appContainer: {
    backgroundColor: palette.devModeBackground,
    flex: 1
  },
  app: {
    backgroundColor: palette.mainBackground,
    width: '320px',
    height: '480px',
    overflow: 'hidden',
    boxShadow: `0 ${sp(1)} ${sp(2)} ${resolveColorVariant(palette.shadow)}`,
    transition: `flex ${SCREEN_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s, width ${
      SCREEN_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC
    }s, height ${SCREEN_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s`
  }
});

interface DevButtonProps {
  onClick: () => void;
}

const DevButton = ({ onClick }: DevButtonProps) => {
  const safeAreaInsets = useSafeAreaInsets();

  return BC(safeAreaInsets, (safeAreaInsets) => (
    <Button
      color="primary"
      variant="contained"
      style={{
        position: 'absolute',
        zIndex: DEV_BUTTON_Z_INDEX,
        bottom: safeAreaInsets.bottomPx,
        right: px((safeAreaInsets.bottomPx === 0 || safeAreaInsets.rightPx !== 0 ? 2 : SP_PX) + safeAreaInsets.rightPx),
        padding: '1px 4px',
        minHeight: 0,
        borderRadius: safeAreaInsets.bottomPx === 0 ? `${br(0.5)} ${br(0.5)} 0 0` : undefined
      }}
      onClick={onClick}
    >
      <Text color="contrastTextPrimary" variant="caption">
        DEV
      </Text>
    </Button>
  ));
};
