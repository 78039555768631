import { useCallbackRef } from 'react-bindings';

import { useIsScreenLocked, useScreenLocker } from './screen-locker';

export const useScreenLockingCallbackRef = <ArgsT extends any[], ReturnT>(func: (...args: ArgsT) => ReturnT) => {
  const isScreenLocked = useIsScreenLocked();
  const { lockScreenUntilComplete } = useScreenLocker<ReturnT>();

  return useCallbackRef((...args: ArgsT): ReturnT | undefined => {
    if (isScreenLocked.get()) {
      return undefined;
    }

    return lockScreenUntilComplete(func(...args));
  });
};

export const useNeverIgnoredScreenLockingCallbackRef = <ArgsT extends any[], ReturnT>(func: (...args: ArgsT) => ReturnT) => {
  const { lockScreenUntilComplete } = useScreenLocker<ReturnT>();

  return useCallbackRef((...args: ArgsT): ReturnT | undefined => lockScreenUntilComplete(func(...args)));
};
