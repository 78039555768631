import { LOCALIZE } from 'linefeedr-localization';
import { BC, useCallbackRef } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { imageLargeIconsError } from '../../../assets/large-icons/error';
import { imageLogosLfToolbar } from '../../../assets/logos/lf-toolbar';
import { Button } from '../../../components/core/Button';
import { Icon } from '../../../components/core/Icon';
import { Text } from '../../../components/core/Text';
import { Col } from '../../../components/layout/Col';
import { Row } from '../../../components/layout/Row';
import { SCREEN_LEVEL_TRANSITION_DURATION_MSEC } from '../../../consts/animation';
import { px, sp, SP_PX } from '../../../consts/layout';
import { ONE_SEC_MSEC } from '../../../consts/time';
import { useSafeAreaInsets } from '../../safe-area';
import { useT } from '../../useT';
import { useModalPresenter } from '..';
import { useModalInfo } from '../modal-info';
import type { MessageModalProps } from './MessageModal';

const $clickAnywhere = LOCALIZE('Click anywhere to dismiss')({ ns: 'error' });

export type PersistentErrorMessageModalProps = Omit<MessageModalProps, 'color'>;

export const PersistentErrorMessageModal = ({
  title,
  subtitle,
  errorCode,
  actionButtonTitle,
  onActionButtonClick
}: PersistentErrorMessageModalProps) => {
  const modalInfo = useModalInfo();
  const modalPresenter = useModalPresenter();
  const safeAreaInsets = useSafeAreaInsets();
  const classNames = useStyles();
  const t = useT();

  const onGlassPaneClick = useCallbackRef(() => {
    if (modalInfo?.id !== undefined) {
      modalPresenter.hide(modalInfo?.id);
    }
  });

  const wrappedOnActionButtonClick = useCallbackRef(() => {
    onGlassPaneClick();

    onActionButtonClick?.();
  });

  return (
    <>
      {BC(safeAreaInsets, (safeAreaInsets) => (
        <Row
          justifyContent="center"
          alignItems="center"
          columnGap={sp(4)}
          className={classNames.main}
          style={{
            top: px(SP_PX * 2 + safeAreaInsets.topPx),
            left: px(SP_PX * 2 + safeAreaInsets.leftPx),
            // Note: calc doesn't work with ssr, but modals won't be used with ssr, so this is ok
            width: `calc(100% - ${px(SP_PX * 4 + safeAreaInsets.leftPx + safeAreaInsets.rightPx)})`,
            marginBottom: px(safeAreaInsets.bottomPx)
          }}
        >
          <Col rowGap={sp(4)} alignItems="center" className={classNames.content}>
            <Col rowGap={sp(2)} alignItems="center">
              <Icon src={imageLargeIconsError} variant="error" />
              <Icon src={imageLogosLfToolbar} variant="black" />
            </Col>
            <Col rowGap={sp(1)} className={classNames.titles}>
              <Text color="error" variant="h2">
                {title}
              </Text>
              {subtitle !== undefined ? <Text color="error">{subtitle}</Text> : null}
              {errorCode !== undefined ? <Text color="textSecondary" variant="body2">{`(${errorCode})`}</Text> : null}
            </Col>
            {actionButtonTitle !== undefined ? (
              <Button color="error" variant="outlined" onClick={wrappedOnActionButtonClick} style={{ pointerEvents: 'initial' }}>
                {actionButtonTitle}
              </Button>
            ) : null}
            <Text color="textSecondary" variant="caption">
              {$clickAnywhere(t)}
            </Text>
          </Col>
        </Row>
      ))}
      <div className={classNames.glassPane} onClick={onGlassPaneClick} />
    </>
  );
};

// Helpers

const useStyles = createUseStyles({
  '@keyframes persistentErrorMessageModalAppear': {
    from: {
      opacity: '0%'
    },
    to: {
      opacity: '100%'
    }
  },
  '@keyframes persistentErrorMessageModalGlasspaneAppear': {
    from: {
      backdropFilter: 'blur(0px) grayscale(0%)'
    },
    to: {
      backdropFilter: 'blur(16px) grayscale(100%)'
    }
  },
  main: {
    position: 'absolute',
    animation: `$persistentErrorMessageModalAppear ${SCREEN_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s ease-in-out`,
    animationFillMode: 'forwards',
    zIndex: 1
  },
  content: {
    flex: 1,
    maxWidth: '640px'
  },
  glassPane: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    animation: `$persistentErrorMessageModalGlasspaneAppear ${SCREEN_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s ease-in-out`,
    animationFillMode: 'forwards',
    pointerEvents: 'initial'
  },
  titles: {
    textAlign: 'center'
  }
});
