import { useMemo } from 'react';

import { VirtualizedCol } from '../../../components/layout/VirtualizedCol';
import { ListHeader } from '../../../components/list/ListHeader';
import { ListItem } from '../../../components/list/ListItem';
import { li } from '../../../components/list/namespace';
import { DisclosureArrowAccessory } from '../../../components/list/specialized/DisclosureArrowAccessory';
import { useAppTaskContext } from '../../../context/useAppTaskContext';
import { inlineStateless } from '../../../makers/makeStatelessComponent';
import { rootRoutes } from '../../../routing/routes';
import type { RouteInfoResolver } from '../../../routing/types/RouteInfoResolver';

export const DevModeDemosSection = () => {
  const entries = useEntries();

  return inlineStateless(() => (
    <VirtualizedCol>
      <ListHeader>Demos</ListHeader>
      {entries.map((entry) => (
        <ListItem key={entry.title} nav={entry.nav} navMode="push" onClick={entry.onClick}>
          <li.Title>{entry.title}</li.Title>
          <DisclosureArrowAccessory />
        </ListItem>
      ))}
    </VirtualizedCol>
  ));
};

// Helpers

interface Entry {
  title: string;
  nav: RouteInfoResolver;
  onClick?: () => void;
}

const useEntries = () => {
  const context = useAppTaskContext();

  return useMemo(
    (): Entry[] => [
      { title: 'Button', nav: rootRoutes.routes.demoButton(context, {}) },
      { title: 'CrossFade', nav: rootRoutes.routes.demoCrossFade(context, {}) },
      { title: 'Message Modal', nav: rootRoutes.routes.demoMessageModal(context, {}) },
      { title: 'Localization', nav: rootRoutes.routes.demoLocalization(context, {}) },
      { title: 'Spinner', nav: rootRoutes.routes.demoSpinner(context, {}) }
    ],
    [context]
  );
};
