import { makeStringSubtypeArray } from 'yaschema';

import { determineColorClass } from '../utils/colors';
import type { ColorClass } from './ColorClass';
import { makeMemCache } from './mem-cache';

export const namedColorVariants = makeStringSubtypeArray(
  'contrastTextPrimary',
  'contrastTextSecondary',
  'devModeBackground',
  'disabledText',
  'divider',
  'error',
  'glassPane',
  'highlightBackground',
  'inputBackground',
  'inputBorder',
  'inputFocusOutline',
  'mainBackground',
  'placeholderBackground',
  'placeholderText',
  'primary',
  'scrollToolBackground',
  'shadow',
  'textPrimary',
  'textSecondary',
  'toolbarBackground',
  'transientModalBackground'
);
export type NamedColorVariant = (typeof namedColorVariants)[0];

export const isNamedColorVariant = (colorVariant: ColorVariant): colorVariant is NamedColorVariant =>
  typeof colorVariant === 'string' && namedColorVariants.has(colorVariant);

export type ColorVariant = NamedColorVariant | CustomColorVariant;

export type CustomColorVariant = (() => string) & { color: string; colorClass: ColorClass };

const globalCustomColorVariantCache = makeMemCache<`=${string}:${ColorClass}`, CustomColorVariant>();

export const makeCustomColorVariant = (color: string, colorClass: ColorClass = determineColorClass(color)) =>
  globalCustomColorVariantCache(`=${color}:${colorClass}`, () => {
    const output: Partial<CustomColorVariant> = () => color;
    output.color = color;
    output.colorClass = colorClass;
    return output as CustomColorVariant;
  });

export const isCustomColorVariant = (color: ColorVariant): color is CustomColorVariant => typeof color === 'function';
