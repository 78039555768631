import { noop } from 'lodash';
import { createContext, useContext, useMemo, useRef } from 'react';
import type { ReadonlyBinding } from 'react-bindings';
import { BC, useBinding, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import type { ChildrenProps } from '../../types/ChildrenProps';
import { DoubleLinkedList } from '../../types/DoubleLinkedList';
import { assert } from '../../utils/assert';
import { uuid } from '../../utils/uuid';
import { ShouldHideAllNonModalContentRoot, useShouldHideAllNonModalContent } from '../hide-all-non-modal-content';
import { Modals } from './components/Modals';
import type { ModalInfo } from './types/ModalInfo';

export interface ModalPresenter {
  modals: ReadonlyBinding<DoubleLinkedList<ModalInfo>>;
  visibleModalIds: ReadonlyBinding<Set<string>>;
  hide: (modalId: string) => void;
  show: (modal: Omit<ModalInfo, 'id'>) => () => void;
}

const ModalPresenterContext = createContext<ModalPresenter | undefined>(undefined);

export const ModalPresenterProvider = ({ children }: ChildrenProps) => {
  const usedUniquenessKeys = useRef(new Set<string>());

  const modals = useBinding(() => new DoubleLinkedList<InternalModalInfo>(), { id: 'modals' });
  const sortedModals = useDerivedBinding(modals, (modals) => [...modals.toArray()].sort(modalComparator), {
    id: 'sortedModals',
    detectInputChanges: false
  });
  const visibleModalIds = useDerivedBinding(
    sortedModals,
    (sortedModals) => {
      const out = new Set<string>();

      const usedGroups = new Set<string>();
      for (const modal of sortedModals) {
        if (modal.exclusivityGroup !== undefined) {
          if (usedGroups.has(modal.exclusivityGroup)) {
            continue; // Skipping, group already represented
          } else {
            usedGroups.add(modal.exclusivityGroup);
          }
        }

        out.add(modal.id);
      }

      return out;
    },
    { id: 'visibleModalIds' }
  );

  const fixedSortedModals = useDerivedBinding(sortedModals, (sortedModals) => sortedModals.filter((m) => m.fixed ?? false).reverse(), {
    id: 'fixedSortedModals'
  });
  const unfixedSortedModals = useDerivedBinding(sortedModals, (sortedModals) => sortedModals.filter((m) => !(m.fixed ?? false)).reverse(), {
    id: 'unfixedSortedModals'
  });

  const hidersByModalId = useRef<Partial<Record<string, () => void>>>({});
  const hidersByUniquenessKey = useRef<Partial<Record<string, () => void>>>({});

  const hide = useCallbackRef((modalId: string) => {
    hidersByModalId.current[modalId]?.();
    delete hidersByModalId.current[modalId];
  });

  const show = useCallbackRef((modal: Omit<ModalInfo, 'id'>) => {
    if (modal.uniquenessKey !== undefined) {
      if (usedUniquenessKeys.current.has(modal.uniquenessKey)) {
        return hidersByUniquenessKey.current[modal.uniquenessKey] ?? noop;
      }

      usedUniquenessKeys.current.add(modal.uniquenessKey);
    }

    const newModals = modals.get();
    const newModalInfo: InternalModalInfo = { id: uuid(), insertionTimeMSec: performance.now(), ...modal };
    const node = newModals.append(newModalInfo);
    modals.set(newModals);

    let alreadyRemoved = false;

    let hideTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
    const hider = () => {
      if (alreadyRemoved) {
        return; // Nothing to do
      }
      alreadyRemoved = true;

      if (hideTimeout !== undefined) {
        clearTimeout(hideTimeout);
        hideTimeout = undefined;
      }

      newModals.remove(node);
      modals.set(newModals);
      if (modal.uniquenessKey !== undefined) {
        usedUniquenessKeys.current.delete(modal.uniquenessKey);
        delete hidersByUniquenessKey.current[modal.uniquenessKey];
      }

      modal.onHide?.();
    };

    hidersByModalId.current[newModalInfo.id] = hider;
    if (modal.uniquenessKey !== undefined) {
      hidersByUniquenessKey.current[modal.uniquenessKey] = hider;
    }

    const hideAfterMSec = modal.hideAfterMSec;
    if (typeof hideAfterMSec === 'number') {
      hideTimeout = setTimeout(hider, hideAfterMSec);
    }

    return hider;
  });

  const presenter = useMemo(
    () => ({
      modals: modals as any as ReadonlyBinding<DoubleLinkedList<ModalInfo>>,
      visibleModalIds,
      hide,
      show
    }),
    [hide, modals, show, visibleModalIds]
  );

  return (
    <ModalPresenterContext.Provider value={presenter}>
      <ShouldHideAllNonModalContentRoot>
        <NonModalContent>{children}</NonModalContent>
        <Modals fixedSortedModals={fixedSortedModals} unfixedSortedModals={unfixedSortedModals} />
      </ShouldHideAllNonModalContentRoot>
    </ModalPresenterContext.Provider>
  );
};

export const useModalPresenter = () => {
  const output = useContext(ModalPresenterContext);
  assert(output !== undefined, 'ModalPresenterProvider must be used with useModalPresenter');
  return output;
};

// Helpers

interface InternalModalInfo extends ModalInfo {
  insertionTimeMSec: number;
}

const modalComparator = (a: InternalModalInfo, b: InternalModalInfo) => {
  const output = (b.priority ?? 0) - (a.priority ?? 0);
  if (output !== 0) {
    return output;
  }

  return b.insertionTimeMSec - a.insertionTimeMSec;
};

const NonModalContent = ({ children }: ChildrenProps) => {
  const classNames = useStyles();
  const shouldHideAllNonModalContent = useShouldHideAllNonModalContent();

  return BC(shouldHideAllNonModalContent, (shouldHideAllNonModalContent) => (
    <div className={classNames.main} style={{ visibility: shouldHideAllNonModalContent ? 'hidden' : undefined }}>
      {children}
    </div>
  ));
};

const useStyles = createUseStyles({
  main: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    width: '100%',
    height: '100%'
  }
});
