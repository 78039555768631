import { apiData } from 'lf-blog-schema';
import { useMemo } from 'react';

import { VirtualizedCol } from '../../../components/layout/VirtualizedCol';
import { ListHeader } from '../../../components/list/ListHeader';
import { ListItem } from '../../../components/list/ListItem';
import { li } from '../../../components/list/namespace';
import { DisclosureArrowAccessory } from '../../../components/list/specialized/DisclosureArrowAccessory';
import { useAppTaskContext } from '../../../context/useAppTaskContext';
import { inlineStateless } from '../../../makers/makeStatelessComponent';
import { rootRoutes } from '../../../routing/routes';
import type { RouteInfoResolver } from '../../../routing/types/RouteInfoResolver';
import { authStatusDevTool } from '../../tools/authStatusDevTool';
import { setDevToolValue } from '../../tools/dev-tools-support';

export const DevModeSitemapSection = () => {
  const entries = useEntries();

  return inlineStateless(() => (
    <VirtualizedCol>
      <ListHeader>Sitemap</ListHeader>
      {entries.map((entry) => (
        <ListItem key={entry.title} nav={entry.nav} navMode="push" onClick={entry.onClick}>
          <li.Title>{entry.title}</li.Title>
          <DisclosureArrowAccessory />
        </ListItem>
      ))}
    </VirtualizedCol>
  ));
};

// Helpers

interface Entry {
  title: string;
  nav: RouteInfoResolver;
  onClick?: () => void;
}

const useEntries = () => {
  const context = useAppTaskContext();

  return useMemo(
    (): Entry[] => [
      {
        title: 'Today',
        nav: rootRoutes.routes.today(context, {}),
        onClick: () => setDevToolValue(authStatusDevTool, 'success')
      },
      {
        title: 'Sign In',
        nav: rootRoutes.routes.signIn(context, {}),
        onClick: () => setDevToolValue(authStatusDevTool, 'unauthorized')
      },
      {
        title: 'Sign Up',
        nav: rootRoutes.routes.signUp(context, {}),
        onClick: () => setDevToolValue(authStatusDevTool, 'unauthorized')
      },
      {
        title: 'MFA',
        nav: rootRoutes.routes.mfa(context, {}),
        onClick: () => setDevToolValue(authStatusDevTool, 'success-needs-mfa')
      },
      {
        title: 'Account',
        nav: rootRoutes.routes.account(context, {})
      },
      {
        title: 'Edit Persona',
        nav: rootRoutes.routes.editPersona(context, { personaId: apiData.makePersonaIdentifier('demo-persona-1') })
      },
      {
        title: 'Article',
        nav: rootRoutes.routes.article(context, { articleId: apiData.makeArticleIdentifier('dev-demo-1') })
      },
      {
        title: 'New Article',
        nav: rootRoutes.routes.newArticle(context, {}),
        onClick: () => setDevToolValue(authStatusDevTool, 'success')
      },
      {
        title: 'Edit Article',
        nav: rootRoutes.routes.editArticle(context, { articleId: apiData.makeArticleIdentifier('dev-demo-1') }),
        onClick: () => setDevToolValue(authStatusDevTool, 'success')
      },
      {
        title: 'Article Preview',
        nav: rootRoutes.routes.articlePreview(context, { articleId: apiData.makeArticleIdentifier('dev-demo-1') }),
        onClick: () => setDevToolValue(authStatusDevTool, 'success')
      }
    ],
    [context]
  );
};
