import type { CSSProperties } from 'react';

import { FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { ONE_SEC_MSEC } from '../../consts/time';
import { TypographyContextProvider } from '../../context/typography';
import type { ChildrenProps, StringChildrenProps } from '../../types/ChildrenProps';
import type { BasicTextProps } from '../core/Text';
import { Text } from '../core/Text';
import { useIsListItemSelected } from './context/selection';

export interface ListItemTitleProps extends BasicTextProps {
  style?: CSSProperties;
}

export const ListItemTitle = ({ children, style, ...props }: (ChildrenProps | StringChildrenProps) & ListItemTitleProps) => {
  const isSelected = useIsListItemSelected();

  const commonProps: BasicTextProps = {
    variant: 'body1',
    color: isSelected ? 'primary' : 'textPrimary',
    fontWeight: isSelected ? 'bold' : undefined,
    ...props
  };

  return typeof children === 'string' ? (
    <Text
      component="div"
      {...commonProps}
      style={{
        flexGrow: 1,
        transition: `color ${FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s, font-weight ${
          FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC
        }s`,
        ...style
      }}
    >
      {children}
    </Text>
  ) : (
    <TypographyContextProvider {...commonProps}>{children}</TypographyContextProvider>
  );
};
