import isPromise from 'is-promise';
import { resolveTypeOrDeferredTypeWithArgs } from 'react-bindings';
import type { TypeOrPromisedType } from 'react-waitables';

import type { NavScreenHook } from '../context/navigation/types/NavScreen';
import { inline } from '../utils/inline';
import type { ResolvedRouteInfo } from './types/ResolvedRouteInfo';
import type { RouteInfo } from './types/RouteInfo';
import type { RouteInfoResolver } from './types/RouteInfoResolver';
import type { RouteInfoResolverMaker } from './types/RouteInfoResolverMaker';
import type { SimpleRouteInfo } from './types/SimpleRouteInfo';

export const makeRouteInfo = <ParamT extends Record<string, string>, ArgsT extends any[] = []>(
  routeInfo: SimpleRouteInfo<ParamT, ArgsT>
): RouteInfo<ParamT, ArgsT> => {
  const resolver: RouteInfoResolverMaker<ParamT, ArgsT> = (context, paramsAndHistoryStateOptions, ...args) => {
    const resolvedPath = routeInfo.path
      .map((part) => {
        return typeof part === 'string' ? part : paramsAndHistoryStateOptions[part[0]];
      })
      .join('/');

    const output: Partial<RouteInfoResolver> = (): TypeOrPromisedType<ResolvedRouteInfo> => {
      const { search, hash, ...params } = paramsAndHistoryStateOptions;

      const defaultHistory =
        routeInfo.defaultHistory !== undefined
          ? resolveTypeOrDeferredTypeWithArgs(routeInfo.defaultHistory, [context, params as ParamT, ...args])
          : undefined;
      const fixedHistory =
        routeInfo.fixedHistory !== undefined
          ? resolveTypeOrDeferredTypeWithArgs(routeInfo.fixedHistory, [context, params as ParamT, ...args])
          : undefined;
      const onScreenHookResolved = ({
        defaultHistory,
        fixedHistory,
        useScreen
      }: {
        defaultHistory: ResolvedRouteInfo[] | undefined;
        fixedHistory: ResolvedRouteInfo[] | undefined;
        useScreen: NavScreenHook;
      }): ResolvedRouteInfo => ({
        path: resolvedPath,
        defaultHistory,
        fixedHistory,
        search,
        hash,
        useScreen
      });

      const onDoneLoadingCode = context.markIsLoadingCode();
      try {
        const resolvedScreen = routeInfo.screen(
          context,
          {
            ...(params as ParamT),
            path: resolvedPath,
            search,
            hash
          },
          ...args
        );
        if ((defaultHistory?.length ?? 0) > 0 || (fixedHistory?.length ?? 0) > 0 || isPromise(resolvedScreen)) {
          const resolvedDefaultHistory = defaultHistory?.map((item) => item());
          const resolvedFixedHistory = fixedHistory?.map((item) => item());
          return inline(async () => {
            try {
              return onScreenHookResolved({
                defaultHistory: resolvedDefaultHistory !== undefined ? await Promise.all(resolvedDefaultHistory) : undefined,
                fixedHistory: resolvedFixedHistory !== undefined ? await Promise.all(resolvedFixedHistory) : undefined,
                useScreen: await resolvedScreen
              });
            } finally {
              onDoneLoadingCode();
            }
          });
        } else {
          try {
            return onScreenHookResolved({
              defaultHistory: defaultHistory !== undefined ? [] : undefined,
              fixedHistory: fixedHistory !== undefined ? [] : undefined,
              useScreen: resolvedScreen
            });
          } finally {
            onDoneLoadingCode();
          }
        }
      } catch (e) {
        onDoneLoadingCode();
        throw e;
      }
    };

    output.path = resolvedPath;

    return output as RouteInfoResolver;
  };

  const output = resolver as RouteInfo<ParamT, ArgsT>;
  output.path = routeInfo.path;
  output.pathSchemas = routeInfo.pathSchemas;
  output.defaultHistory = routeInfo.defaultHistory;
  output.fixedHistory = routeInfo.fixedHistory;
  output.screen = routeInfo.screen;
  return output;
};
