import type { HistoryStateOptions } from '../history/types/HistoryStateOptions';

export const makeUrl = (path: string, { search, hash }: HistoryStateOptions): URL => {
  const url = new URL(path, document.location.origin);

  if (search !== undefined) {
    for (const [key, value] of Object.entries(search)) {
      if (value !== undefined) {
        url.searchParams.append(key, value);
      }
    }
  }

  if (hash !== undefined) {
    url.hash = hash;
  }

  return url;
};
