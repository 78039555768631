import { schema } from 'yaschema';

import { MAX_ARTICLE_AUTHORS, MAX_ARTICLE_PARAGRAPHS } from '../../../consts/limits';
import { groupIdentifierSchema } from '../identifiers/GroupIdentifier';
import { personaIdentifierSchema } from '../identifiers/PersonaIdentifier';
import { articleDesignSchema } from './ArticleDesign';
import { articleProvidedMetaSchema } from './ArticleProvidedMeta';
import { articleTitleSchema } from './ArticleTitle';
import { articleParagraphSchema } from './paragraphs/ArticleParagraph';

export const articleDetailsSchema = schema.object({
  title: articleTitleSchema,
  subtitle: articleTitleSchema.optional(),
  design: articleDesignSchema,
  meta: articleProvidedMetaSchema,
  groupId: groupIdentifierSchema.optional(),
  authorIds: schema.array({ items: personaIdentifierSchema, minLength: 1, maxLength: MAX_ARTICLE_AUTHORS }),
  paragraphs: schema.array({ items: articleParagraphSchema, minLength: 1, maxLength: MAX_ARTICLE_PARAGRAPHS })
});

export type ArticleDetails = typeof articleDetailsSchema.valueType;
