import { createContext, useContext } from 'react';
import { useStableValue } from 'react-bindings';

import type { BasicTextProps } from '../components/core/Text';
import { typography } from '../consts/typography';
import type { ChildrenProps } from '../types/ChildrenProps';
import type { ColorVariant } from '../types/ColorVariant';
import type { TypographyInfo } from '../types/styles/TypographyInfo';

interface TypographyContextValue extends TypographyInfo {
  color: ColorVariant;
}

const TypographyContext = createContext<TypographyContextValue>({
  ...typography.body1,
  color: 'textPrimary'
});

export type TypographyContextProviderProps = BasicTextProps;

export const useTypographyContext = () => useContext(TypographyContext);

export const TypographyContextProvider = ({ children, variant, ...props }: ChildrenProps & TypographyContextProviderProps) => {
  const contextValue = useTypographyContext();

  const value = useStableValue({
    ...contextValue,
    ...(variant !== undefined ? typography[variant] : undefined),
    ...props
  });

  return <TypographyContext.Provider value={value}>{children}</TypographyContext.Provider>;
};
