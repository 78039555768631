import type { ComponentType } from 'react';

import { useDomRenderingMode } from '../context/dom-rendering';
import type { RealReactNode } from '../types/RealReactNode';

export const makeStatelessComponent =
  <PropsT,>(Component: ComponentType<PropsT>) =>
  (props: PropsT) =>
    inlineStateless(() => <Component {...(props as any)} />);

export const inlineStateless = (render: () => RealReactNode) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const mode = useDomRenderingMode();

  switch (mode) {
    case 'render':
      return render();
    case 'no-render':
      return null;
  }
};
