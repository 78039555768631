import { schema } from 'yaschema';

import {
  MAX_ADDRESS_CITY_LENGTH,
  MAX_ADDRESS_COUNTRY_LENGTH,
  MAX_ADDRESS_POSTAL_CODE_LENGTH,
  MAX_ADDRESS_REGION_LENGTH,
  MAX_ADDRESS_STREET_LENGTH
} from '../../../consts/limits';

export const physicalAddressSchema = schema.object({
  street: schema.string().setAllowedLengthRange(1, MAX_ADDRESS_STREET_LENGTH).optional(),
  city: schema.string().setAllowedLengthRange(1, MAX_ADDRESS_CITY_LENGTH),
  region: schema.string().setAllowedLengthRange(1, MAX_ADDRESS_REGION_LENGTH),
  country: schema.string().setAllowedLengthRange(1, MAX_ADDRESS_COUNTRY_LENGTH),
  postalCode: schema.string().setAllowedLengthRange(1, MAX_ADDRESS_POSTAL_CODE_LENGTH).optional()
});
export type PhysicalAddress = typeof physicalAddressSchema.valueType;
