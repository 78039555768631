import { createElement, type CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

import { px } from '../../consts/layout';
import { resolveColorVariant } from '../../consts/palette';
import { typography, typographyStyles } from '../../consts/typography';
import { TypographyContextProvider, useTypographyContext } from '../../context/typography';
import type { ChildrenProps, StringChildrenProps } from '../../types/ChildrenProps';
import { type ColorVariant } from '../../types/ColorVariant';
import type { TypographyInfo } from '../../types/styles/TypographyInfo';
import type { TypographyVariant } from '../../types/styles/TypographyVariant';
import { RawString } from './RawString';

export interface BasicTextProps extends Partial<TypographyInfo> {
  variant?: TypographyVariant;
  color?: ColorVariant;
}

export interface TextProps extends BasicTextProps {
  /** @defaultValue `'span'` */
  component?: 'div' | 'span';
  style?: Omit<CSSProperties, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color'>;
}

export const Text = ({
  children,
  component = 'span',
  variant,
  color,
  style,
  fontFamily,
  fontSizePx,
  fontWeight,
  lineHeight
}: (ChildrenProps | StringChildrenProps) & TextProps) => {
  const classNames = useStyles();
  const typographyContext = useTypographyContext();

  const typographyForVariant = variant !== undefined ? typography[variant] : undefined;

  const basicTypographyProps: BasicTextProps = {
    color: color ?? typographyContext.color,
    fontFamily: fontFamily ?? typographyForVariant?.fontFamily ?? typographyContext.fontFamily,
    fontSizePx: fontSizePx ?? typographyForVariant?.fontSizePx ?? typographyContext.fontSizePx,
    fontWeight: fontWeight ?? typographyForVariant?.fontWeight ?? typographyContext.fontWeight,
    lineHeight: lineHeight ?? typographyForVariant?.lineHeight ?? typographyContext.lineHeight
  };

  const typographyStyle: CSSProperties = {
    color: basicTypographyProps.color !== undefined ? resolveColorVariant(basicTypographyProps.color) : undefined,
    fontFamily: basicTypographyProps.fontFamily,
    fontSize: basicTypographyProps.fontSizePx !== undefined ? px(basicTypographyProps.fontSizePx) : undefined,
    fontWeight: basicTypographyProps.fontWeight,
    lineHeight: basicTypographyProps.lineHeight
  };

  return (
    <TypographyContextProvider {...basicTypographyProps}>
      {createElement(
        component,
        { className: variant !== undefined ? classNames[variant] : undefined, style: { ...style, ...typographyStyle } },
        typeof children === 'string' ? <RawString>{children}</RawString> : children
      )}
    </TypographyContextProvider>
  );
};

// Helpers

const useStyles = createUseStyles(typographyStyles);
