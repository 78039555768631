import { type Binding, useCallbackRef } from 'react-bindings';

import type { ChildrenProps } from '../../../types/ChildrenProps';
import type { CheckboxProps } from '../../fields/Checkbox';
import { Checkbox } from '../../fields/Checkbox';
import type { ListItemProps } from '../ListItem';
import { ListItem } from '../ListItem';
import { li } from '../namespace';

export interface CheckboxListItemProps extends ListItemProps<void> {
  checked: Binding<boolean>;
  checkboxProps?: Omit<CheckboxProps, 'disabled' | 'value'>;
}

export const CheckboxListItem = ({ children, checked, disabled, checkboxProps, ...fwd }: ChildrenProps & CheckboxListItemProps) => {
  const toggleChecked = useCallbackRef(() => checked.set(!checked.get()));

  return (
    <ListItem {...fwd} disabled={disabled} onClick={toggleChecked}>
      <li.Icon>
        <Checkbox value={checked} disabled={disabled} {...checkboxProps} />
      </li.Icon>
      {children}
    </ListItem>
  );
};
