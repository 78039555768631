import { useDerivedBinding } from 'react-bindings';

import { useDocumentNavigation } from './DocumentNavigationProvider';

export const useDocument = () => {
  const navigation = useDocumentNavigation();

  return useDerivedBinding(navigation?.currentMounted, (current) => current, {
    id: 'documentContent',
    areInputValuesEqual: (a, b) => a === b,
    detectOutputChanges: false
  });
};
