import { schema } from 'yaschema';

import { MAX_COPYRIGHT_LENGTH } from '../../../consts/limits';
import { localeCodeSchema } from '../LocaleCode';
import { articleCategorySchema } from './ArticleCategory';

export const articleProvidedMetaSchema = schema.object({
  minAge: schema.restrictedNumber([{ min: 0 }], { divisibleBy: [1] }),
  language: localeCodeSchema,
  categories: schema.array({ items: articleCategorySchema }),
  copyright: schema.string().setAllowedLengthRange(1, MAX_COPYRIGHT_LENGTH)
});

export type ArticleProvidedMeta = typeof articleProvidedMetaSchema.valueType;
