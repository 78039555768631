import _ from 'lodash';

import { assert } from '../utils/assert';
import { developmentConfig } from './development';
import { localConfig } from './local';
import { offlineConfig } from './offline';
import type { AppConfig, AppEnvironment } from './types';
import { appEnvironments } from './types';

export const getConfig = _.once((): AppConfig => {
  try {
    const appEnv = appEnvironments.checked(process.env.REACT_APP_ENV ?? 'production');
    assert(appEnv !== undefined, 'Unexpected value encountered for REACT_APP_ENV');

    return configs[appEnv]();
  } catch (e) {
    console.error('Failed to get app config', e);
    throw e;
  }
});

// Helpers

const configs: Record<AppEnvironment, () => AppConfig> = {
  development: developmentConfig,
  local: localConfig,
  offline: offlineConfig,
  production: developmentConfig // TODO: TEMP
};
