import { BC, makeConstBinding, useBinding, useDerivedBinding, useFlattenedBinding } from 'react-bindings';

import { STD_ROW_SIZE_PX } from '../../consts/layout';
import type { NavigationBarStyle } from '../../context/navigation/types/NavigationBarStyle';
import { useDocument } from '../../context/navigation/useDocument';
import { CrossFade } from '../animation/CrossFade';
import { NavigationBar } from './NavigationBar';

export interface MovingNavigationBarProps {
  onClick?: () => void;
}

export const MovingNavigationBar = ({ onClick }: MovingNavigationBarProps) => {
  const doc = useDocument();
  const navigationBarStyle = useFlattenedBinding(doc, (doc) => doc?.navigationBarStyle ?? defaultNavigationBarStyle, {
    id: 'navigationBarStyle'
  });
  const wantsMovingNavigationBar = useDerivedBinding(navigationBarStyle, (navigationBarStyle): boolean => navigationBarStyle === 'moving', {
    id: 'wantsMovingNavigationBar'
  });

  const navigationBarHeightPx = useBinding(() => STD_ROW_SIZE_PX, { id: 'navigationBarHeightPx', detectChanges: true });

  return BC(wantsMovingNavigationBar, (moving) => (
    <CrossFade
      animateOpacity={false}
      style={{ width: '100%', height: moving ? undefined : '0px' }}
      transitioningPartStyle={{ width: '100%' }}
    >
      {moving ? <NavigationBar needsSafeSides="rl" outHeightPx={navigationBarHeightPx} onClick={onClick} /> : null}
    </CrossFade>
  ));
};

// Helpers

const defaultNavigationBarStyle = makeConstBinding<NavigationBarStyle>('hidden', { id: 'defaultNavigationBarStyle' });
