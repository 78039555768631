import { useBindingEffect, useDerivedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { useDomRef } from '../../useDomRef';
import { useModalPresenter } from '..';
import { ModalInfoProvider } from '../modal-info';
import type { ModalInfo } from '../types/ModalInfo';

export interface ModalProps {
  modal: ModalInfo;
}

export const Modal = ({ modal }: ModalProps) => {
  const { visibleModalIds } = useModalPresenter();
  const classNames = useStyles();

  const domRef = useDomRef<HTMLDivElement>();

  const isVisibleModal = useDerivedBinding(visibleModalIds, (visibleModalIds) => visibleModalIds.has(modal.id), { id: 'isVisibleModal' });

  useBindingEffect(isVisibleModal, (isVisibleModal) => {
    const elem = domRef.current;
    if (elem === null) {
      return;
    }

    elem.style.display = isVisibleModal ? 'block' : 'none';
  });

  return (
    <ModalInfoProvider modal={modal}>
      <div ref={domRef} className={classNames.modal} style={{ display: isVisibleModal.get() ? 'block' : 'none' }}>
        {modal.element}
      </div>
    </ModalInfoProvider>
  );
};

// Helpers

const useStyles = createUseStyles({
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    pointerEvents: 'none'
  }
});
