import { makeStringSubtypeArray } from 'yaschema';

export const typographyVariants = makeStringSubtypeArray(
  'articleHeader1',
  'articleHeader2',
  'body1',
  'body2',
  'button',
  'buttonSmall',
  'caption',
  'h1',
  'h2',
  'h3',
  'listHeader1',
  'listHeader2',
  'navigationBarTitle',
  'quote'
);
export type TypographyVariant = (typeof typographyVariants)[0];
