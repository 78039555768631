import type { ReadonlyBinding } from 'react-bindings';
import { BC, useDerivedBinding } from 'react-bindings';
import type { Validator } from 'react-validatables';
import { resolveValidationError } from 'react-validatables';
import type { ValidationErrorLevel } from 'yaschema';

import { sp } from '../../consts/layout';
import { useT } from '../../context/useT';
import type { ColorVariant } from '../../types/ColorVariant';
import type { TypographyVariant } from '../../types/styles/TypographyVariant';
import type { ValidationErrorInfo } from '../../types/ValidationErrorInfo';
import { CrossFade } from '../animation/CrossFade';
import type { TextProps } from '../core/Text';
import { Text } from '../core/Text';

export type ValidationErrorProps = TextProps &
  (
    | {
        validationError: ReadonlyBinding<ValidationErrorInfo>;
        validator?: undefined;
      }
    | { validator: Validator; validationError?: undefined }
  );

export const ValidationError = ({ validationError, validator, ...validationErrorProps }: ValidationErrorProps) => {
  const t = useT();

  const validatorValidationError = useDerivedBinding(
    { result: validator?.value },
    ({ result }): ValidationErrorInfo => ({
      error: result?.validationError,
      level: 'error'
    }),
    { id: 'validationError' }
  );

  if (validator !== undefined) {
    validationError = validatorValidationError;
  }

  return BC(validationError, (validationError) => (
    <CrossFade
      style={{ height: validationError.error !== undefined ? undefined : '0px', width: '100%' }}
      transitioningPartStyle={{ width: '100%' }}
    >
      {validationError.error !== undefined ? (
        <Text
          component="div"
          color={colorsByErrorLevel[validationError.level]}
          variant={variantByErrorLevel[validationError.level]}
          style={{ marginTop: sp(0.5) }}
          {...validationErrorProps}
        >
          {resolveValidationError(validationError.error, t)}
        </Text>
      ) : null}
    </CrossFade>
  ));
};

// Helpers

const colorsByErrorLevel: Record<ValidationErrorLevel, ColorVariant> = {
  error: 'error',
  warning: 'textSecondary'
};

const variantByErrorLevel: Record<ValidationErrorLevel, TypographyVariant> = {
  error: 'body2',
  warning: 'caption'
};
