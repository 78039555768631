import { createContext, useContext } from 'react';
import { useBinding } from 'react-bindings';

import type { ChildrenProps } from '../types/ChildrenProps';

export type DomRenderingMode = 'render' | 'no-render';

const DomRenderingModeContext = createContext<DomRenderingMode>('render');

export const useDomRenderingMode = () => useContext(DomRenderingModeContext);

export const useIsDomInRenderMode = () => {
  const mode = useDomRenderingMode();
  const output = useBinding(() => mode === 'render', { id: 'isInRenderMode' });
  output.set(mode === 'render');
  return output;
};

export interface DomRenderingModeProviderProps {
  mode: DomRenderingMode;
}

export const DomRenderingModeProvider = ({ children, mode }: ChildrenProps & DomRenderingModeProviderProps) => (
  <DomRenderingModeContext.Provider value={mode}>{children}</DomRenderingModeContext.Provider>
);
