import type { UserType } from '../types/api-data/user/UserType';

export const MAX_ADDRESS_STREET_LENGTH = 200;
export const MAX_ADDRESS_CITY_LENGTH = 100;
export const MAX_ADDRESS_REGION_LENGTH = 100;
export const MAX_ADDRESS_COUNTRY_LENGTH = 100;
export const MAX_ADDRESS_POSTAL_CODE_LENGTH = 20;

export const MAX_ARTICLE_AUTHORS = 10;
export const MAX_ARTICLE_HEADING_LENGTH = 150;
export const MAX_ARTICLE_PARAGRAPH_LENGTH = 2500;
export const MAX_ARTICLE_PARAGRAPHS = 1000;
export const MAX_ARTICLE_QUOTE_LENGTH = 300;
export const MAX_ARTICLE_REVISION_NOTES_LENGTH = 500;
/** The maximum number of people who can be granted special access to an article (usually these are authors, editors, and managers) */
export const MAX_ARTICLE_SPECIAL_ACCESSORS = 10;
export const MAX_ARTICLE_TITLE_LENGTH = 150;

export const MAX_COPYRIGHT_LENGTH = 100;
export const MAX_EMAIL_LENGTH = 320;

export const MAX_GROUP_SPECIAL_ACCESS_BY_USER_TYPE: Record<UserType, number> = {
  anonymous: 0,
  default: 0,
  pro: 2,
  boss: 5,
  executive: 100,
  'site-admin': Number.MAX_SAFE_INTEGER
};

export const MAX_PERSONA_ACCESS_REQUIREMENT_GROUPS = 10;
export const MAX_PERSONA_AFFILIATION_LENGTH = 100;
export const MAX_PERSONA_BIO_LENGTH = 1000;
export const MAX_PERSONA_LINKS = 10;
export const MAX_PERSONA_NAME_LENGTH = 100;
export const MAX_PERSONA_ROLE_LENGTH = 100;

export const MAX_PERSONAS_BY_USER_TYPE: Record<UserType, number> = {
  anonymous: 0,
  default: 1,
  pro: 2,
  boss: 3,
  executive: 8,
  'site-admin': Number.MAX_SAFE_INTEGER
};

export const MAX_PHONE_LENGTH = 15;
export const MAX_URL_LENGTH = 1024;

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 256;

export const MIN_PERSONA_LINK_TITLE_LENGTH = 3;
export const MAX_PERSONA_LINK_TITLE_LENGTH = 100;
