import type { CSSProperties } from 'react';

import type { ChildrenProps } from '../../types/ChildrenProps';
import type { RefForwardingProps } from '../../types/RefForwardingProps';
import type { FlexParentStyleField } from '../../types/styles/flex-parent-style';
import { omitFlexStyleProps, pickFlexStyleProps } from '../../types/styles/flex-parent-style';

export type FlexProps = Pick<CSSProperties, FlexParentStyleField> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  RefForwardingProps<HTMLDivElement>;

export const Flex = ({ fwdRef, ...props }: ChildrenProps & FlexProps) => (
  <div ref={fwdRef} {...omitFlexStyleProps(props)} style={{ ...props.style, ...pickFlexStyleProps(props), display: 'flex' }}>
    {props.children}
  </div>
);
