import { useRef } from 'react';
import type { EmptyObject } from 'react-bindings';
import { useBindingEffect } from 'react-bindings';

import { SCREEN_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { useAuthenticationCheck } from '../../context/authentication';
import { useNavigation } from '../../context/navigation';
import { useIsScreenCurrent } from '../../context/navigation/screen-state';
import { useIncSsrBusyCount } from '../../context/ssr';
import { useAppTaskContext } from '../../context/useAppTaskContext';
import { makeRoutingScreen } from '../../makers/makeRoutingScreen';
import { makeRouteInfo } from '../makeRouteInfo';
import { rootRoutes } from '../routes';

export const rootRoute = makeRouteInfo<EmptyObject, []>({
  path: [],
  pathSchemas: {},
  screen: () =>
    makeRoutingScreen(() => ({
      useHook: () => {
        const context = useAppTaskContext();
        const authCheck = useAuthenticationCheck();
        const isCurrent = useIsScreenCurrent();
        const navigation = useNavigation();
        const incSsrBusyCount = useIncSsrBusyCount();

        const isFirst = useRef(true);
        const decSsrBusyCount = useRef<(() => void) | undefined>(undefined);
        if (isFirst.current) {
          isFirst.current = false;
          decSsrBusyCount.current = incSsrBusyCount?.();
        }

        useBindingEffect(
          { authCheck, isCurrent },
          ({ authCheck, isCurrent }) => {
            if (!isCurrent || authCheck === undefined) {
              return;
            }

            if (authCheck.requiresMfa === true) {
              navigation.push(rootRoutes.routes.mfa(context, {}));
            } else {
              navigation.push(rootRoutes.routes.today(context, {}));
            }

            decSsrBusyCount.current?.();
            decSsrBusyCount.current = undefined;
          },
          // Delaying the checks here a bit because if we want to push a new screen as part of an authentication state change, we don't want to
          // pop the old screen at the same time -- this is very evident when using the Sitemap dev tools that force authentication state changes
          { triggerOnMount: true, limitMSec: SCREEN_LEVEL_TRANSITION_DURATION_MSEC }
        );
      }
    }))()
});
