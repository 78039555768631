import type { EmptyObject } from 'react-bindings';

import type { MfaScreenArgs } from '../../screens/MfaScreen';
import { makeRouteInfo } from '../makeRouteInfo';
import { signOutRoute } from './signOutRoute';

export const mfaRoute = makeRouteInfo<EmptyObject, [MfaScreenArgs] | []>({
  path: ['mfa'],
  pathSchemas: {},
  fixedHistory: (context, _pathParams, _args = {}) => [signOutRoute(context, {})],
  screen: async (_context, _params, args = {}) => (await import('../../screens/MfaScreen')).MfaScreen(args)
});
