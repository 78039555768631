import type { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

import { px, sp } from '../../consts/layout';
import type { ChildrenProps } from '../../types/ChildrenProps';
import { Row } from '../layout/Row';

export interface ListItemIconProps {
  style?: CSSProperties;
}

export const ListItemIcon = ({ children, style }: ChildrenProps & ListItemIconProps) => {
  const classNames = useStyles();

  return (
    <Row justifyContent="center" alignItems="center" className={classNames.main} style={style}>
      {children}
    </Row>
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    padding: `${sp(0.5)} 0`,
    marginRight: sp(1),
    minWidth: px(24)
  }
});
