import { useStableValue } from 'react-bindings';

import { useIfAuthLevel, useIfNotAuthLevel } from '../context/authentication';
import { useNavigation } from '../context/navigation';
import type { NavDocumentHook } from '../context/navigation/types/NavDocument';
import type { NavScreen, NavScreenHook } from '../context/navigation/types/NavScreen';
import { useScreenLocker } from '../context/screen-locker';
import { useAppTaskContext } from '../context/useAppTaskContext';
import { rootRoutes } from '../routing/routes';
import { makePreResolvedRouteInfoResolver } from '../routing/types/RouteInfoResolver';
import type { AuthLevel } from '../types/AuthLevel';

export interface MakeNavScreenArgs<ArgsT extends any[]> {
  disallowedAuthLevel?: AuthLevel;
  /** @defaultValue `'any'` */
  requiredAuthLevel?: AuthLevel | 'any';
  main: (args: { args: ArgsT }) => NavDocumentHook;
}

export const makeNavScreen =
  <ArgsT extends any[] = []>(useNavScreen: (...args: ArgsT) => MakeNavScreenArgs<ArgsT>) =>
  (...args: ArgsT): NavScreenHook =>
  (): NavScreen => {
    const context = useAppTaskContext();
    const navigation = useNavigation();
    const { disallowedAuthLevel, requiredAuthLevel = 'any', main } = useNavScreen(...args);
    const { lockScreenUntilComplete } = useScreenLocker();

    useIfAuthLevel(disallowedAuthLevel, () => {
      if (navigation.canPop.get()) {
        navigation.pop({ transitionMode: 'continue' });
      } else {
        navigation.replace(rootRoutes.defaultRoute(context, {}), { transitionMode: 'continue' });
      }
    });

    useIfNotAuthLevel(requiredAuthLevel, (currentAuthLevel) => {
      const top = navigation.history.top.get();

      navigation.history.batch(() => {
        if (navigation.canPop.get()) {
          navigation.pop({ transitionMode: 'continue' });
        } else {
          navigation.replace(rootRoutes.defaultRoute(context, {}), { transitionMode: 'continue' });
        }

        if (requiredAuthLevel === 'full') {
          lockScreenUntilComplete(async () => {
            const resolvedRouteInfo = await rootRoutes.find(context, top.path);
            const continueTo = makePreResolvedRouteInfoResolver(resolvedRouteInfo);

            if (currentAuthLevel === 'partial') {
              navigation.push(rootRoutes.routes.mfa(context, {}, { continueTo }));
            } else {
              navigation.push(rootRoutes.routes.signIn(context, {}, { continueTo }));
            }
          });
        }
      });
    });

    return useStableValue({
      main: main({ args })()
    });
  };
