import type { TypeOrDeferredTypeWithArgs } from 'react-bindings';
import { BC, resolveTypeOrDeferredTypeWithArgs, useBinding, useBindingEffect, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { isSsr } from '../../config/ssr';
import { px, sp, SP_PX } from '../../consts/layout';
import { CenterMaxWidthPxProvider } from '../../context/center-max-width';
import { useDomRenderingMode } from '../../context/dom-rendering';
import { useFwdDomRef } from '../../context/useFwdDomRef';
import type { RealReactNode } from '../../types/RealReactNode';
import { DomResizeDetector } from '../layout/DomResizeDetector';
import { Row } from '../layout/Row';

export interface LCRProps {
  left?: RealReactNode;
  center?: TypeOrDeferredTypeWithArgs<RealReactNode, [{ maxWidthPx: number }]>;
  right?: RealReactNode;
}

export const LCR = ({ left, center, right }: LCRProps) => {
  const mode = useDomRenderingMode();
  const classNames = useStyles();

  const domRefLeft = useFwdDomRef<HTMLDivElement>();
  const domRefCenter = useFwdDomRef<HTMLDivElement>();
  const domRefRight = useFwdDomRef<HTMLDivElement>();

  const containerWidthPx = useBinding(() => 0, { id: 'containerWidthPx', detectChanges: true });
  const leftWidthPx = useBinding(() => 0, { id: 'leftWidthPx', detectChanges: true });
  const rightWidthPx = useBinding(() => 0, { id: 'rightWidthPx', detectChanges: true });

  const onContainerResize = useCallbackRef((width: number | undefined, _height: number | undefined) =>
    containerWidthPx.set(Math.round(width ?? 0))
  );
  const onLeftResize = useCallbackRef((width: number | undefined, _height: number | undefined) => leftWidthPx.set(Math.round(width ?? 0)));
  const onRightResize = useCallbackRef((width: number | undefined, _height: number | undefined) =>
    rightWidthPx.set(Math.round(width ?? 0))
  );

  const maxCenterWidthPx = useDerivedBinding(
    { containerWidthPx, leftWidthPx, rightWidthPx },
    ({ containerWidthPx, leftWidthPx, rightWidthPx }) => Math.max(0, containerWidthPx - SP_PX * 2 - leftWidthPx - rightWidthPx),
    { id: 'maxCenterWidthPx' }
  );

  useBindingEffect(
    maxCenterWidthPx,
    (maxCenterWidthPx) => {
      if (mode === 'no-render') {
        return;
      }

      const elem = domRefCenter.current;
      if (elem === null) {
        return;
      }

      // TODO: TEMP 220
      elem.style.maxWidth = px(isSsr() ? 220 : Math.round(maxCenterWidthPx));
    },
    { triggerOnMount: true, deps: [mode] }
  );

  return isSsr() ? (
    <div className={`LCR ${classNames.container}`}>
      <Row className={classNames.main} alignItems="center">
        <Row className={`Left ${classNames.left}`}>
          <div ref={domRefLeft}>{left ?? null}</div>
        </Row>
        <CenterMaxWidthPxProvider maxWidthPx={maxCenterWidthPx}>
          <Row fwdRef={domRefCenter} className={`Center ${classNames.center}`}>
            {/* // TODO: TEMP 220 */}
            {resolveTypeOrDeferredTypeWithArgs(center ?? null, [{ maxWidthPx: 220 }])}
          </Row>
        </CenterMaxWidthPxProvider>
        <Row className={`Right ${classNames.right}`}>
          <div ref={domRefRight}>{right ?? null}</div>
        </Row>
      </Row>
    </div>
  ) : (
    <div className={`LCR ${classNames.container}`}>
      <DomResizeDetector handleHeight={false} onResize={onContainerResize} />
      <Row className={classNames.main} alignItems="center">
        <Row className={`Left ${classNames.left}`}>
          <div ref={domRefLeft}>
            <DomResizeDetector handleHeight={false} onResize={onLeftResize} targetRef={domRefLeft} />
            {left ?? null}
          </div>
        </Row>
        <CenterMaxWidthPxProvider maxWidthPx={maxCenterWidthPx}>
          <Row fwdRef={domRefCenter} className={`Center ${classNames.center}`} style={{ maxWidth: px(maxCenterWidthPx.get()) }}>
            {BC(maxCenterWidthPx, (maxCenterWidthPx) =>
              resolveTypeOrDeferredTypeWithArgs(center ?? null, [{ maxWidthPx: maxCenterWidthPx }])
            )}
          </Row>
        </CenterMaxWidthPxProvider>
        <Row className={`Right ${classNames.right}`}>
          <div ref={domRefRight}>
            <DomResizeDetector handleHeight={false} onResize={onRightResize} targetRef={domRefRight} />
            {right ?? null}
          </div>
        </Row>
      </Row>
    </div>
  );
};

// Helpers

const useStyles = createUseStyles({
  container: {
    width: '100%'
  },
  main: {
    justifyContent: 'space-between',
    width: '100%',
    columnGap: sp(1)
  },
  left: {
    flex: 0.5,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  right: {
    flex: 0.5,
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
});
