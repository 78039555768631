import { useEffect } from 'react';
import { useBinding } from 'react-bindings';

import type { Size2DPx } from '../types/Size2DPx';

export const useBrowserSize = () => {
  const output = useBinding<Size2DPx>(
    () => ({
      widthPx: window.innerWidth,
      heightPx: window.innerHeight
    }),
    {
      id: 'browserSize',
      detectChanges: true
    }
  );

  useEffect(() => {
    const listener = () => {
      output.set({
        widthPx: window.innerWidth,
        heightPx: window.innerHeight
      });
    };
    window.addEventListener('resize', listener);
    listener();

    return () => {
      window.removeEventListener('resize', listener);
    };
  });

  return output;
};
