import { schema } from 'yaschema';

export const size2DPxSchema = schema.object({
  widthPx: schema.number(),
  heightPx: schema.number()
});

export type Size2DPx = typeof size2DPxSchema.valueType;

export const size2DPxToString = ({ widthPx, heightPx }: Size2DPx) => `${widthPx}x${heightPx}`;
