import { BC, makeConstBinding, useBinding, useDerivedBinding, useFlattenedBinding } from 'react-bindings';

import { STD_ROW_SIZE_PX } from '../../consts/layout';
import type { ToolbarStyle } from '../../context/navigation/types/ToolbarStyle';
import { useDocument } from '../../context/navigation/useDocument';
import { CrossFade } from '../animation/CrossFade';
import { Toolbar } from './Toolbar';

export const MovingToolbar = () => {
  const doc = useDocument();
  const toolbarStyle = useFlattenedBinding(doc, (doc) => doc?.toolbarStyle ?? defaultToolbarStyle, { id: 'toolbarStyle' });
  const wantsMovingToolbar = useDerivedBinding(toolbarStyle, (toolbarStyle): boolean => toolbarStyle === 'moving', {
    id: 'wantsMovingToolbar'
  });

  // Not really used
  const toolbarHeightPx = useBinding(() => STD_ROW_SIZE_PX, { id: 'toolbarHeightPx', detectChanges: true });

  return BC(wantsMovingToolbar, (moving) => (
    <CrossFade
      animateOpacity={false}
      style={{ width: '100%', height: moving ? undefined : '0px' }}
      transitioningPartStyle={{ width: '100%' }}
    >
      {moving ? <Toolbar outHeightPx={toolbarHeightPx} needsSafeSides="rl" /> : null}
    </CrossFade>
  ));
};

// Helpers

const defaultToolbarStyle = makeConstBinding<ToolbarStyle>('hidden', { id: 'defaultToolbarStyle' });
