import { makeStringSubtypeArray, schema } from 'yaschema';

export const articlePermissionReasons = makeStringSubtypeArray(
  /** Owners can view, edit, change the status, and delete their articles */
  'article-owner',
  /** Editors can view, edit, and change the status of their articles */
  'article-editor',
  /** Authors who don't own their articles can generally view (and, in the future, make suggestions about) their articles */
  'article-collaborator',
  /** Groups owners can generally edit, view, change the status, and delete all articles in the group */
  'group-owner',
  /** Groups editors can generally edit, view, and change the status of all articles in the group */
  'group-editor',
  /** Groups members can generally view all articles published in the group */
  'group-member',
  /** The content is viewable by the public */
  'public',
  /** Site admins have further reaching access for maintaining the site and its quality */
  'site-admin'
);
export const articlePermissionReasonSchema = schema.string(...articlePermissionReasons);
export type ArticlePermissionReason = typeof articlePermissionReasonSchema.valueType;
