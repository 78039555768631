import { createContext, useContext } from 'react';

import type { ChildrenProps } from '../../../types/ChildrenProps';

const SelectedContext = createContext<boolean>(false);

export interface IsListItemSelectedProviderProps {
  isSelected: boolean;
}

export const IsListItemSelectedProvider = ({ children, isSelected }: ChildrenProps & IsListItemSelectedProviderProps) => (
  <SelectedContext.Provider value={isSelected}>{children}</SelectedContext.Provider>
);

export const useIsListItemSelected = () => useContext(SelectedContext);
