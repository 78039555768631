import { createContext, useContext } from 'react';

import type { IHistory } from '../history/types/IHistory';
import type { ChildrenProps } from '../types/ChildrenProps';
import { assert } from '../utils/assert';

const HistoryContext = createContext<IHistory | undefined>(undefined);

export const useHistory = () => {
  const output = useContext(HistoryContext);
  assert(output !== undefined, 'HistoryProvider must be used with useHistory');
  return output;
};

export interface HistoryProviderProps {
  history: IHistory;
}

export const HistoryProvider = ({ children, history }: ChildrenProps & HistoryProviderProps) => {
  return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>;
};
