import { useNavigation } from '../../context/navigation';
import type { RouteInfoResolver } from '../../routing/types/RouteInfoResolver';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { NavMode } from '../../types/NavMode';
import type { RefForwardingProps } from '../../types/RefForwardingProps';

export type LinkElement = HTMLAnchorElement | HTMLDivElement;

export interface LinkProps extends React.AnchorHTMLAttributes<LinkElement>, RefForwardingProps<LinkElement> {
  disabled?: boolean;
  nav?: RouteInfoResolver;
  /** @defaultValue `'only-secondary'` */
  navMode?: NavMode;
}

export const Link = ({
  children,
  fwdRef,
  disabled = false,
  onClick,
  nav,
  navMode = 'only-secondary',
  ...fwd
}: ChildrenProps & LinkProps) => {
  const navigation = useNavigation();

  const wrappedOnClick = (event: React.MouseEvent<LinkElement, MouseEvent>) => {
    if (event.button !== 0 || event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) {
      return;
    }

    if (disabled) {
      return;
    }

    event.stopPropagation();

    onClick?.(event);

    if (nav !== undefined && navMode !== 'only-secondary') {
      navigation[navMode](nav);
    }

    if (fwd.href === undefined) {
      event.preventDefault();
    }
  };
  const isClickable = !disabled && (onClick !== undefined || (navMode !== 'only-secondary' && nav !== undefined) || fwd.href !== undefined);

  const href = nav?.path ?? fwd.href;
  const Component = href === undefined ? 'div' : 'a';

  return (
    <Component
      ref={fwdRef}
      onClick={wrappedOnClick}
      href={fwd.href ?? `/${nav?.path}`}
      {...fwd}
      target={fwd.target ?? (fwd.href !== undefined ? '_blank' : undefined)}
      rel={fwd.rel ?? (fwd.href !== undefined ? 'noopener noreferrer' : undefined)}
      style={{ cursor: isClickable ? 'pointer' : undefined, ...fwd.style }}
    >
      {children}
    </Component>
  );
};
