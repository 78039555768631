import { LOCALIZE } from 'linefeedr-localization';
import type { EmptyObject } from 'react-bindings';
import { useBindingEffect } from 'react-bindings';

import { SCREEN_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { recheckAuth, useAuthenticationCheck } from '../../context/authentication';
import { useMessageModalPresenter } from '../../context/modals/specializations/message-modal-presenter';
import { useNavigation } from '../../context/navigation';
import { useIsScreenCurrent } from '../../context/navigation/screen-state';
import { useAppTaskContext } from '../../context/useAppTaskContext';
import { useNeverIgnoredScreenLockingCallbackRef } from '../../context/useScreenLockingCallbackRef';
import { useT } from '../../context/useT';
import { makeRoutingScreen } from '../../makers/makeRoutingScreen';
import { signOut } from '../../tasks/auth/signOut';
import { makeRouteInfo } from '../makeRouteInfo';
import { rootRoutes } from '../routes';

const ns = 'sign-out';
const $messageSignedOut = LOCALIZE('You are now signed out')({ ns });
const $signingOut = LOCALIZE('Signing Out')({ ns });

export const signOutRoute = makeRouteInfo<EmptyObject, []>({
  path: ['sign-out'],
  pathSchemas: {},
  fixedHistory: [],
  screen: () =>
    makeRoutingScreen(() => ({
      useMessage: () => $signingOut(useT()),
      useHook: () => {
        const context = useAppTaskContext();
        const authCheck = useAuthenticationCheck();
        const isCurrent = useIsScreenCurrent();
        const { showMessage } = useMessageModalPresenter();
        const navigation = useNavigation();
        const t = useT();

        const doSignOut = useNeverIgnoredScreenLockingCallbackRef(async () => {
          await signOut.run(context); // This should never fail since it's always retried internally

          recheckAuth('hard');

          showMessage({ uniquenessKey: 'signed-out', color: 'primary', title: $messageSignedOut(t) });

          navigation.push(rootRoutes.defaultRoute(context, {}));
        });

        useBindingEffect(
          { authCheck, isCurrent },
          ({ authCheck, isCurrent }) => {
            if (!isCurrent || authCheck === undefined) {
              return;
            }

            if (authCheck.sessionId === undefined) {
              // If already signed out
              navigation.push(rootRoutes.defaultRoute(context, {}));
            } else {
              doSignOut();
            }
          },
          // Delaying the checks here a bit because if we want to push a new screen as part of an authentication state change, we don't want to
          // pop the old screen at the same time -- this is very evident when using the Sitemap dev tools that force authentication state changes
          { triggerOnMount: true, limitMSec: SCREEN_LEVEL_TRANSITION_DURATION_MSEC }
        );
      }
    }))()
});
