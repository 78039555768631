import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { MAX_ARTICLE_SPECIAL_ACCESSORS } from '../../../../consts/limits';
import { articleAccessorInfoSchema } from '../../../../types/api-data/article/ArticleAccessorInfo';
import { articleDetailsSchema } from '../../../../types/api-data/article/ArticleDetails';
import { articleGeneratedVersionedMetaSchema } from '../../../../types/api-data/article/ArticleGeneratedVersionedMeta';
import { articleIdentifierSchema } from '../../../../types/api-data/identifiers/ArticleIdentifier';

export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'content',
  credentials: 'include',
  url: '/article/{id}/draft',
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema,
      params: schema.object({
        id: articleIdentifierSchema
      }),
      body: schema.object({
        details: articleDetailsSchema,
        accessors: schema.array({ items: articleAccessorInfoSchema, maxLength: MAX_ARTICLE_SPECIAL_ACCESSORS }).optional()
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        meta: articleGeneratedVersionedMetaSchema
      })
    },
    failureResponse: genericFailureSchemas
  }
});
