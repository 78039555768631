import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import { getConfig } from '../getConfig';

export const init = async () => {
  const config = getConfig();

  const app = initializeApp(config.firebase);
  getAnalytics(app);
  // TODO: review
};
