import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

export const DEL = makeHttpApi({
  method: 'DELETE',
  routeType: 'auth',
  credentials: 'include',
  url: '/auth',
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema
    },
    successResponse: {
      status: schema.number(StatusCodes.OK)
    },
    failureResponse: genericFailureSchemas
  }
});
