import { BC, type ReadonlyBinding, useBindingEffect } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { px } from '../../../consts/layout';
import { MODAL_Z_INDEX } from '../../../consts/z-index';
import { useDomRef } from '../../useDomRef';
import { useWindowScrollTop } from '../../useWindowScrollTop';
import { useVisualViewportSize } from '../../visual-viewport-size';
import type { ModalInfo } from '../types/ModalInfo';
import { Modal } from './Modal';

export interface ModalsProps {
  fixedSortedModals: ReadonlyBinding<ModalInfo[]>;
  unfixedSortedModals: ReadonlyBinding<ModalInfo[]>;
}

export const Modals = ({ fixedSortedModals, unfixedSortedModals }: ModalsProps) => {
  const classNames = useStyles();
  const visualViewportSize = useVisualViewportSize();
  const windowScrollTop = useWindowScrollTop();

  const domRefFixed = useDomRef<HTMLDivElement>();
  const domRefUnfixed = useDomRef<HTMLDivElement>();

  useBindingEffect(
    { visualViewportSize, windowScrollTop },
    ({ visualViewportSize, windowScrollTop }) => {
      const elem = domRefFixed.current;
      if (elem === null) {
        return;
      }

      elem.style.height = px(visualViewportSize.heightPx + windowScrollTop);
    },
    { triggerOnMount: true }
  );

  useBindingEffect(
    visualViewportSize,
    (visualViewportSize) => {
      const elem = domRefUnfixed.current;
      if (elem === null) {
        return;
      }

      elem.style.height = px(visualViewportSize.heightPx);
    },
    { triggerOnMount: true }
  );

  return (
    <>
      <div
        ref={domRefFixed}
        className={classNames.modalRoot}
        style={{ height: px(visualViewportSize.get().heightPx + windowScrollTop.get()) }}
      >
        {BC(fixedSortedModals, (modals) => modals.map((modal) => <Modal key={modal.id} modal={modal} />))}
      </div>
      <div ref={domRefUnfixed} className={classNames.modalRoot} style={{ height: px(visualViewportSize.get().heightPx) }}>
        {BC(unfixedSortedModals, (modals) => modals.map((modal) => <Modal key={modal.id} modal={modal} />))}
      </div>
    </>
  );
};

// Helpers

const useStyles = createUseStyles({
  modalRoot: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: MODAL_Z_INDEX,
    pointerEvents: 'none'
  }
});
