import { createContext, useContext } from 'react';

import type { ChildrenProps } from '../../types/ChildrenProps';
import type { ModalInfo } from './types/ModalInfo';

const ModalInfoContext = createContext<ModalInfo | undefined>(undefined);

export interface ModalInfoProviderProps {
  modal: ModalInfo;
}

export const ModalInfoProvider = ({ children, modal }: ChildrenProps & ModalInfoProviderProps) => (
  <ModalInfoContext.Provider value={modal}>{children}</ModalInfoContext.Provider>
);

export const useModalInfo = () => useContext(ModalInfoContext);
