import _ from 'lodash';
import { schema } from 'yaschema';

import { TOTP_PASSCODE_LENGTH } from '../../../consts/limits';

const allowedTotpPasscodeCharPattern = '[0-9]';

export const totpPasscodePrefix = '$T';
export const totpPasscodeRegex = new RegExp(`^${_.escapeRegExp(totpPasscodePrefix)}${allowedTotpPasscodeCharPattern}+$`);
export const totpPasscodeSchema = schema
  .regex<`${typeof totpPasscodePrefix}${string}`>(totpPasscodeRegex)
  .setAllowedLengthRange(totpPasscodePrefix.length + TOTP_PASSCODE_LENGTH, totpPasscodePrefix.length + TOTP_PASSCODE_LENGTH);
export type TotpPasscode = typeof totpPasscodeSchema.valueType;

export const nonPrefixedTotpPasscodeSchema = schema
  .regex(new RegExp(`^${allowedTotpPasscodeCharPattern}+$`))
  .setAllowedLengthRange(TOTP_PASSCODE_LENGTH, TOTP_PASSCODE_LENGTH);

export const makeTotpPasscode = (nonPrefixedValue: string): TotpPasscode => `${totpPasscodePrefix}${nonPrefixedValue}`;
