import _ from 'lodash';
import { useEffect } from 'react';
import { BC, makeBinding } from 'react-bindings';

import { Presenter } from './components/core/Presenter';
import { AppLoadingIndicator } from './components/feedback/AppLoadingIndicator';
import { LoadingCodeIndicator } from './components/feedback/LoadingCodeIndicator';
import { getConfig } from './config/getConfig';
import { init } from './config/init';
import { isSsr } from './config/ssr';
import { AuthCheckProvider } from './context/authentication';
import { ContainerProvider } from './context/ContainerProvider';
import { useHistory } from './context/history';
import { LoadingCodeTrackerProvider } from './context/loading-code';
import { NavigationProvider } from './context/navigation';
import { useCreateScreenNavigation } from './context/navigation/useCreateScreenNavigation';
import { SafeAreaInsetsProvider } from './context/safe-area';
import { useIncSsrBusyCount } from './context/ssr';
import { ThemeColorConnector } from './context/theme-color';
import { AppTaskContextProvider } from './context/useAppTaskContext';
import { VisualViewportSizeProvider } from './context/visual-viewport-size';
import { DevModeContainer } from './dev/components/DevModeContainer';
import { authStatusDevTool } from './dev/tools/authStatusDevTool';
import { useDevTool } from './dev/tools/dev-tools-support';
import { rootRoutes } from './routing/routes';
import { useGlobalStyles } from './useGlobalStyles';
import { deleteSsrStyles } from './utils/deleteSsrStyles';
import { inline } from './utils/inline';

const globalDidInit = makeBinding<boolean | 'pending'>(() => false, { id: 'didInit', detectChanges: true });

export const App = () => {
  useGlobalStyles();
  const incSsrBusyCount = useIncSsrBusyCount();

  useEffect(() => {
    inline(async () => {
      if (globalDidInit.get() !== false) {
        return;
      }
      globalDidInit.set('pending');
      const decSsrBusyCount = incSsrBusyCount?.();

      // During SSR, stylesheets are created that might overlap name-wise
      if (!isSsr()) {
        deleteSsrStyles();
      }

      await init();

      globalDidInit.set(true);
      decSsrBusyCount?.();
    });
  });

  return (
    <LoadingCodeTrackerProvider>
      <ThemeColorConnector />

      {BC(globalDidInit, (isReady) =>
        isReady === true ? (
          <>
            <LoadingCodeIndicator />
            <InitializedApp />
          </>
        ) : (
          <AppLoadingIndicator />
        )
      )}
    </LoadingCodeTrackerProvider>
  );
};

// Helpers

const shouldUseDevModeContainer = _.once(() => !isSsr() && getConfig().devMode !== false);

const InitializedApp = () => (
  <AuthCheckProvider>
    <AppTaskContextProvider>
      <ContextualizedApp />
    </AppTaskContextProvider>
  </AuthCheckProvider>
);

const ContextualizedApp = () => {
  const history = useHistory();
  const navigation = useCreateScreenNavigation({ history, routes: rootRoutes });

  return (
    <NavigationProvider navigation={navigation}>
      {shouldUseDevModeContainer() ? (
        <DevModeContainer>
          <RunnableApp />
        </DevModeContainer>
      ) : (
        <VisualViewportSizeProvider>
          <SafeAreaInsetsProvider>
            <RunnableApp />
          </SafeAreaInsetsProvider>
        </VisualViewportSizeProvider>
      )}
    </NavigationProvider>
  );
};

const RunnableApp = () => {
  useDevTool(authStatusDevTool);

  return (
    <ContainerProvider>
      <Presenter />
    </ContainerProvider>
  );
};
