import { BC } from 'react-bindings';

import { px } from '../../consts/layout';
import { useSafeAreaInsets } from '../../context/safe-area';
import type { Sides } from '../../types/Sides';
import { extractSides } from '../../types/Sides';

export interface SafeAreaSpacerProps {
  sides: Sides;
}

export const SafeAreaSpacer = ({ sides }: SafeAreaSpacerProps) => {
  const safeAreaInsets = useSafeAreaInsets();

  const { top, right, bottom, left } = extractSides(sides);

  return BC(safeAreaInsets, (safeAreaInsets) => (
    <div
      style={{
        paddingTop: top ? px(safeAreaInsets.topPx) : 0,
        paddingRight: right ? px(safeAreaInsets.rightPx) : 0,
        paddingBottom: bottom ? px(safeAreaInsets.bottomPx) : 0,
        paddingLeft: left ? px(safeAreaInsets.leftPx) : 0
      }}
    />
  ));
};
