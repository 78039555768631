import React, { useRef } from 'react';
import { BC, useBinding, useBindingEffect, useCallbackRef, useDerivedBinding, useStableValue } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC, FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { PULL_TO_REFRESH_MIN_DISTANCE_PX, PULL_TO_REFRESH_MIN_DURATION_MSEC } from '../../consts/pull-to-refresh';
import { SCROLL_END_THRESHOLD_MSEC } from '../../consts/time';
import type { ScrollableControls } from '../../context/scrollable-parent';
import { ScrollableParentProvider } from '../../context/scrollable-parent';
import { useDomRef } from '../../context/useDomRef';
import type { ChildrenProps } from '../../types/ChildrenProps';
import type { Position2DPx } from '../../types/Position2DPx';
import type { RealReactNode } from '../../types/RealReactNode';
import type { RefForwardingProps } from '../../types/RefForwardingProps';
import type { Size2DPx } from '../../types/Size2DPx';
import { useUuid } from '../../utils/uuid';
import { DomResizeDetector } from '../layout/DomResizeDetector';
import { Row } from '../layout/Row';
import { Spinner } from './Spinner';

export interface ScrollableProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    RefForwardingProps<HTMLDivElement> {
  controls?: ScrollableControls;
  /** Content drawn above the scrollable parent but in the context of the scrollable parent */
  pre?: RealReactNode;
  /** Content drawn below the scrollable parent but in the context of the scrollable parent */
  post?: RealReactNode;
  /** @defaultValue `false` */
  pullToRefresh?: boolean;
}

export const Scrollable = ({
  children,
  id,
  onScroll,
  fwdRef,
  controls,
  pre,
  post,
  pullToRefresh = false,
  ...fwdProps
}: ChildrenProps & ScrollableProps) => {
  const classNames = useStyles();
  const uid = useUuid();

  id = id ?? uid;

  const lastScrollTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const scrollPosition = useBinding<Position2DPx>(() => ({ xPx: 0, yPx: 0 }), { id: 'scrollPosition', detectChanges: true });
  const isScrolling = useBinding<boolean>(() => false, { id: 'isScrolling', detectChanges: true });

  const domRef = useDomRef<HTMLDivElement>();
  const domRefContent = useDomRef<HTMLDivElement>();

  const pullToRefreshCount = useBinding(() => 0, { id: 'pullToRefreshCount', detectChanges: true });
  const pullToRefreshScale = useBinding(() => 0, { id: 'pullToRefreshScale', detectChanges: true });
  const isPullToRefreshAtThreshold = useDerivedBinding(pullToRefreshScale, (scale) => scale >= 1, { id: 'isPullToRefreshAtThreshold' });

  const lastPullToRefreshTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  useBindingEffect(isPullToRefreshAtThreshold, (isPullToRefreshAtThreshold) => {
    if (lastPullToRefreshTimeout.current !== undefined) {
      clearTimeout(lastPullToRefreshTimeout.current);
      lastPullToRefreshTimeout.current = undefined;
    }

    if (isPullToRefreshAtThreshold) {
      lastPullToRefreshTimeout.current = setTimeout(() => {
        pullToRefreshCount.set(pullToRefreshCount.get() + 1);

        const elem = domRef.current;
        if (elem !== null) {
          elem.scrollTop = 0;

          elem.style.overflowY = 'hidden';
          setTimeout(() => (elem.style.overflowY = 'auto'), 0);
        }
      }, PULL_TO_REFRESH_MIN_DURATION_MSEC);
    }
  });

  const wrappedOnScroll = useCallbackRef((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    isScrolling.set(true);

    if (lastScrollTimeout.current !== undefined) {
      clearTimeout(lastScrollTimeout.current);
    }
    lastScrollTimeout.current = setTimeout(() => isScrolling.set(false), SCROLL_END_THRESHOLD_MSEC);

    const elem = domRef.current;
    if (elem !== null) {
      scrollPosition.set({ xPx: Math.round(elem.scrollLeft), yPx: Math.round(elem.scrollTop) });

      if (pullToRefresh) {
        if (elem.scrollTop < 0) {
          pullToRefreshScale.set(Math.min(1, -elem.scrollTop / PULL_TO_REFRESH_MIN_DISTANCE_PX));
        } else {
          pullToRefreshScale.set(0);
        }
      }
    }

    onScroll?.(event);
  });

  const contentSize = useBinding<Size2DPx>(() => ({ widthPx: 0, heightPx: 0 }), { id: 'contentSize', detectChanges: true });
  const onContentResize = useCallbackRef((width: number | undefined, height: number | undefined) =>
    contentSize.set({ widthPx: Math.round(width ?? 0), heightPx: Math.round(height ?? 0) })
  );

  const onResize = useCallbackRef((width: number | undefined, height: number | undefined) =>
    scrollableParentSize.set({ widthPx: Math.round(width ?? 0), heightPx: Math.round(height ?? 0) })
  );
  const scrollableParentSize = useBinding<Size2DPx>(() => ({ widthPx: 0, heightPx: 0 }), {
    id: 'scrollableParentSize',
    detectChanges: true
  });

  const updateRefs = useCallbackRef((node?: HTMLDivElement | null | undefined) => {
    domRef.current = node ?? null;
    fwdRef?.(node);
  });

  const scrollToElementWithId = useCallbackRef((elementId: string, position: ScrollLogicalPosition = 'nearest') => {
    const elem = domRef.current;
    if (elem === null) {
      return;
    }

    // Because pages don't load all at once, we keep trying to scroll and if more content has loaded, we keep trying -- but this is still
    // best effort
    const tryScroll = () => {
      const foundElem = document.getElementById(elementId);
      if (foundElem !== null) {
        foundElem.scrollIntoView({ behavior: 'smooth', block: position });
      } else {
        // ScrollTo isn't available for SSR
        if (elem.scrollTo !== undefined) {
          elem.scrollTo({ behavior: 'smooth', top: elem.scrollHeight });
          setTimeout(() => {
            if (elem.scrollHeight > elem.scrollTop + elem.clientHeight) {
              tryScroll();
            }
          }, ELEMENT_LEVEL_TRANSITION_DURATION_MSEC);
        }
      }
    };
    setTimeout(tryScroll, FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC);
  });

  const scrollToTop = useCallbackRef(() => {
    const elem = domRef.current;
    if (elem === null) {
      return;
    }

    // ScrollTo isn't available for SSR
    elem.scrollTo?.({ behavior: 'smooth', top: 0 });
  });

  const scrollToYPx = useCallbackRef((yPx: number) => {
    const elem = domRef.current;
    if (elem === null) {
      return;
    }

    // Because pages don't load all at once, we keep trying to scroll and if more content has loaded, we keep trying -- but this is still
    // best effort
    const tryScroll = () => {
      // ScrollTo isn't available for SSR
      if (elem.scrollTo !== undefined) {
        elem.scrollTo({ behavior: 'smooth', top: yPx });
        setTimeout(() => {
          if (elem.scrollHeight > elem.scrollTop + elem.clientHeight && elem.scrollTop < yPx) {
            tryScroll();
          }
        }, ELEMENT_LEVEL_TRANSITION_DURATION_MSEC);
      }
    };
    setTimeout(tryScroll, FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC);
  });

  const scrollableControls = useStableValue<ScrollableControls>({ scrollToElementWithId, scrollToTop, scrollToYPx });

  if (controls !== undefined) {
    controls.scrollToElementWithId = scrollToElementWithId;
    controls.scrollToTop = scrollToTop;
    controls.scrollToYPx = scrollToYPx;
  }

  return (
    <ScrollableParentProvider
      id={id}
      contentSize={contentSize}
      didPullToRefresh={pullToRefreshCount}
      isScrolling={isScrolling}
      scrollableParentSize={scrollableParentSize}
      scrollPosition={scrollPosition}
      controls={scrollableControls}
    >
      {pullToRefresh
        ? BC(pullToRefreshScale, (scale) =>
            scale > 0 ? (
              <Row justifyContent="center" className={classNames.pullToRefreshIndicator} style={{ transform: `scale(${scale})` }}>
                <Spinner color={scale < 1 ? 'black' : 'primary'} variant={scale < 1 ? 'large' : 'pull-to-refresh'} />
              </Row>
            ) : null
          )
        : null}
      {pre}
      <div id={id} ref={updateRefs} onScroll={wrappedOnScroll} {...fwdProps}>
        <DomResizeDetector onResize={onResize} targetRef={domRef} />
        <div ref={domRefContent}>
          <DomResizeDetector onResize={onContentResize} targetRef={domRefContent} />
          {children}
        </div>
        {pullToRefresh ? <div className={classNames.pullToRefreshOverscrollForcer} /> : null}
      </div>
      {post}
    </ScrollableParentProvider>
  );
};

// Helpers

const useStyles = createUseStyles({
  pullToRefreshIndicator: {
    position: 'absolute',
    top: '66px',
    left: 0,
    right: 0,
    zIndex: 2
  },
  pullToRefreshOverscrollForcer: {
    position: 'absolute',
    bottom: '-1px',
    width: '1px',
    height: '1px'
  }
});
