import { makeStringSubtypeArray, schema } from 'yaschema';

/** Selected from most-common.  Non-exhaustive */
export const codeLanguages = makeStringSubtypeArray(
  'bash',
  'c',
  'clojure',
  'coffeescript',
  'cpp',
  'csharp',
  'css',
  'dart',
  'dockerfile',
  'go',
  'gradle',
  'groovy',
  'handlebars',
  'java',
  'javascript',
  'json',
  'kotlin',
  'latex',
  'lisp',
  'lua',
  'makefile',
  'markdown',
  'objectivec',
  'ocaml',
  'perl',
  'pgsql',
  'php',
  'plaintext',
  'properties',
  'protobuf',
  'puppet',
  'python',
  'ruby',
  'rust',
  'scala',
  'shell',
  'sql',
  'swift',
  'tcl',
  'typescript',
  'xml',
  'yaml'
);
export const codeLanguageSchema = schema.string(...codeLanguages);
export type CodeLanguage = typeof codeLanguageSchema.valueType;
