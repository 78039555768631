import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { totpAuthenticatorNameSchema } from '../../../types/api-data/auth/TotpAuthenticatorName';
import { totpPasscodeSchema } from '../../../types/api-data/auth/TotpPasscode';
import { totpAuthenticatorIdentifierSchema } from '../../../types/api-data/identifiers/TotpAuthenticatorIdentifier';

export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'auth',
  credentials: 'include',
  url: '/auth/mfa/activate',
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        totpId: totpAuthenticatorIdentifierSchema,
        name: totpAuthenticatorNameSchema,
        passcode: totpPasscodeSchema
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema
    },
    failureResponse: genericFailureSchemas
  }
});
