import type { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

import { FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { px, sp } from '../../consts/layout';
import { ONE_SEC_MSEC } from '../../consts/time';
import { TypographyContextProvider } from '../../context/typography';
import type { ChildrenProps, StringChildrenProps } from '../../types/ChildrenProps';
import type { BasicTextProps } from '../core/Text';
import { Text } from '../core/Text';
import { Row } from '../layout/Row';
import { useIsListItemSelected } from './context/selection';

export interface ListItemDetailProps extends BasicTextProps {
  style?: CSSProperties;
}

export const ListItemDetail = ({ children, style, ...props }: (ChildrenProps | StringChildrenProps) & ListItemDetailProps) => {
  const isSelected = useIsListItemSelected();
  const classNames = useStyles();

  const commonProps: BasicTextProps = {
    variant: 'body1',
    color: isSelected ? 'primary' : 'textPrimary',
    fontWeight: isSelected ? 'bold' : undefined,
    ...props
  };

  return (
    <Row justifyContent="flex-end" alignItems="center" className={classNames.main}>
      {typeof children === 'string' ? (
        <Text
          component="div"
          {...commonProps}
          style={{
            flexGrow: 1,
            textAlign: 'right',
            transition: `color ${FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s, font-weight ${
              FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC
            }s`,
            ...style
          }}
        >
          {children}
        </Text>
      ) : (
        <TypographyContextProvider {...commonProps}>{children}</TypographyContextProvider>
      )}
    </Row>
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    padding: `${sp(0.5)} 0`,
    marginLeft: sp(1),
    minWidth: px(24)
  }
});
