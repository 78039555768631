import { createContext, useContext, useMemo } from 'react';
import { type ReadonlyBinding, useBinding, useCallbackRef, useDerivedBinding } from 'react-bindings';

import type { ChildrenProps } from '../types/ChildrenProps';
import { assert } from '../utils/assert';
import { useIncSsrBusyCount } from './ssr';

export interface LoadingCodeTracker {
  isLoadingCode: ReadonlyBinding<boolean>;
  markIsLoadingCode: () => () => void;
}

const LoadingCodeTrackerContext = createContext<LoadingCodeTracker | undefined>(undefined);

export const useLoadingCodeTracker = () => {
  const output = useContext(LoadingCodeTrackerContext);
  assert(output !== undefined, 'LoadingCodeTrackerProvider must be used with useLoadingCodeTracker');
  return output;
};

export const LoadingCodeTrackerProvider = ({ children }: ChildrenProps) => {
  const incSsrBusyCount = useIncSsrBusyCount();
  const isLoadingCodeCount = useBinding(() => 0, { id: 'isLoadingCodeCount', detectChanges: true });
  const isLoadingCode = useDerivedBinding(isLoadingCodeCount, (isLoadingCodeCount) => isLoadingCodeCount > 0, { id: 'isLoadingCode' });
  const markIsLoadingCode = useCallbackRef(() => {
    isLoadingCodeCount.set(isLoadingCodeCount.get() + 1);
    const decSsrBusyCount = incSsrBusyCount?.();

    let alreadyCalled = false;
    return () => {
      if (alreadyCalled) {
        return;
      }
      alreadyCalled = true;

      isLoadingCodeCount.set(isLoadingCodeCount.get() - 1);
      decSsrBusyCount?.();
    };
  });

  const tracker = useMemo<LoadingCodeTracker>(() => ({ isLoadingCode, markIsLoadingCode }), [isLoadingCode, markIsLoadingCode]);

  return <LoadingCodeTrackerContext.Provider value={tracker}>{children}</LoadingCodeTrackerContext.Provider>;
};
