import { makeStringSubtypeArray, schema } from 'yaschema';

export const personaPermissionReasons = makeStringSubtypeArray(
  /** Owners can view, edit, change the access requirements, and delete their personas */
  'persona-owner',
  /** Admins can view, edit, and change the access requirements of their personas */
  'persona-admin',
  /** Editors can view and edit their personas */
  'persona-editor',
  /** Users can view all persona details and act as a persona for authoring articles, but can't change the persona itself */
  'persona-user',
  /** Viewers can view most persona details */
  'persona-viewer',
  /** View access can be granted for members of specific groups */
  'group-member',
  /** The content is viewable by the public */
  'public',
  /** Site admins have further reaching access for maintaining the site and its quality */
  'site-admin'
);
export const personaPermissionReasonSchema = schema.string(...personaPermissionReasons);
export type PersonaPermissionReason = typeof personaPermissionReasonSchema.valueType;
