import { schema } from 'yaschema';

import { MAX_IMAGE_DIMENSION_PX, MIN_IMAGE_DIMENSION_PX } from '../../../../consts/images';
import { articleImageIdentifierSchema } from '../../identifiers/ArticleImageIdentifier';
import { userImageIdentifierSchema } from '../../identifiers/UserImageIdentifier';
import { articleImageModeSchema } from '../ArticleImageMode';
import { makeArticleParagraphSchema } from './internal/makeArticleParagraphSchema';

export const articleImageParagraphSchema = makeArticleParagraphSchema(
  'image',
  schema.object({
    imageId: schema.oneOf(articleImageIdentifierSchema, userImageIdentifierSchema),
    alt: schema.string().optional(),
    caption: schema.string().optional(),
    mode: articleImageModeSchema,
    widthPx: schema.restrictedNumber([{ min: MIN_IMAGE_DIMENSION_PX, max: MAX_IMAGE_DIMENSION_PX }], { divisibleBy: [1] }),
    heightPx: schema.restrictedNumber([{ min: MIN_IMAGE_DIMENSION_PX, max: MAX_IMAGE_DIMENSION_PX }], { divisibleBy: [1] })
  })
);
export type ArticleImageParagraph = typeof articleImageParagraphSchema.valueType;
