import { createContext, useContext, useEffect } from 'react';
import type { Binding, ReadonlyBinding } from 'react-bindings';
import { makeConstBinding, useBinding, useCallbackRef } from 'react-bindings';

import { DomResizeDetector } from '../components/layout/DomResizeDetector';
import type { ChildrenProps } from '../types/ChildrenProps';
import type { Size2DPx } from '../types/Size2DPx';

const defaultVisualViewportSize = makeConstBinding<Size2DPx>(
  {
    widthPx: Math.round(window.visualViewport?.width ?? window.innerWidth),
    heightPx: Math.round(window.visualViewport?.height ?? window.innerHeight)
  },
  { id: 'defaultVisualViewportSize' }
);

const VisualViewportSizeContext = createContext<ReadonlyBinding<Size2DPx>>(defaultVisualViewportSize);

export const useVisualViewportSize = () => useContext(VisualViewportSizeContext);

export interface VisualViewportSizeProviderProps {
  /** @defaultValue `false` */
  disabled?: boolean;
}

export const VisualViewportSizeProvider = ({ children, disabled = false }: ChildrenProps & VisualViewportSizeProviderProps) => {
  const value = useBinding<Size2DPx>(
    () => ({
      widthPx: Math.round(window.visualViewport?.width ?? window.innerWidth),
      heightPx: Math.round(window.visualViewport?.height ?? window.innerHeight)
    }),
    { id: 'safeAreaInsets', detectChanges: true }
  );

  const onResize = useCallbackRef((width: number | undefined, height: number | undefined) =>
    value.set({
      widthPx: Math.round(width ?? 0),
      heightPx: Math.round(height ?? 0)
    })
  );

  return (
    <VisualViewportSizeContext.Provider value={value}>
      {/* Uses the container size if disabled */}
      {!disabled ? <VisualViewportSizeConnector value={value} /> : <DomResizeDetector onResize={onResize} />}
      {children}
    </VisualViewportSizeContext.Provider>
  );
};

// Helpers

interface VisualViewportSizeConnectorProps {
  value: Binding<Size2DPx>;
}

const VisualViewportSizeConnector = ({ value }: VisualViewportSizeConnectorProps) => {
  useEffect(() => {
    const listener = () => {
      value.set({
        widthPx: Math.round(window.visualViewport?.width ?? window.innerWidth),
        heightPx: Math.round(window.visualViewport?.height ?? window.innerHeight)
      });
    };
    window.visualViewport?.addEventListener('resize', listener);
    listener();

    return () => {
      window.visualViewport?.removeEventListener('resize', listener);
    };
  });

  return null;
};
