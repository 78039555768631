import { createUseStyles } from 'react-jss';

import { imageSpinnersLarge } from '../../assets/spinners/large';
import { imageSpinnersPullToRefresh } from '../../assets/spinners/pull-to-refresh';
import { imageSpinnersSmall } from '../../assets/spinners/small';
import type { VariantImageSource } from '../../types/VariantImageSource';
import { Icon } from './Icon';

export type SpinnerVariant = 'small' | 'large' | 'pull-to-refresh';

export interface SpinnerProps {
  color: 'black' | 'white' | 'primary';
  variant: SpinnerVariant;
}

export const Spinner = ({ color, variant }: SpinnerProps) => {
  const classNames = useStyles();

  return <Icon src={imagesByVariant[variant]} variant={color} className={classNames[variant]} />;
};

// Helpers

const imagesByVariant = {
  small: imageSpinnersSmall,
  large: imageSpinnersLarge,
  'pull-to-refresh': imageSpinnersPullToRefresh
} satisfies Record<SpinnerVariant, VariantImageSource>;

const useStyles = createUseStyles({
  '@keyframes spinnerRotation': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  small: {
    animation: '$spinnerRotation 2s infinite linear'
  },
  large: {
    animation: '$spinnerRotation 4s infinite linear'
  },
  'pull-to-refresh': {
    animation: '$spinnerRotation 4s infinite linear'
  }
});
