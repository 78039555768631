import type { CSSProperties } from 'react';
import { BC, type Binding, useCallbackRef } from 'react-bindings';

import { imageIconsCheckboxCheck } from '../../assets/icons/checkbox-check';
import { px, sp, STD_FIELD_SIZE_PX, STD_ICON_SIZE_PX } from '../../consts/layout';
import { disabledOpacity } from '../../consts/palette';
import { useIsScreenLocked } from '../../context/screen-locker';
import { Button } from '../core/Button';
import { Icon, IconPlaceholder } from '../core/Icon';

export type CheckboxSize = 'field' | 'icon';

export interface CheckboxProps {
  /** @defaultValue `false` */
  round?: boolean;
  /** @defaultValue `'field'` */
  size?: CheckboxSize;
  value: Binding<boolean>;
  disabled?: boolean;
}

export const Checkbox = ({ round = false, size = 'field', value, disabled = false }: CheckboxProps) => {
  const isScreenLocked = useIsScreenLocked();

  const toggle = useCallbackRef(() => {
    if (isScreenLocked.get() || disabled) {
      return;
    }

    value.set(!value.get());
  });

  const style = styles[`${size}-${round ? 'round' : 'rectangular'}`];
  const disabledStyle: CSSProperties = { ...style, opacity: disabledOpacity };

  return BC(value, (value) => (
    <Button
      variant={value ? 'contained' : 'outlined'}
      color={value ? 'primary' : 'inputBorder'}
      onClick={toggle}
      style={disabled ? disabledStyle : style}
      disabled={disabled}
    >
      {value ? (
        <Icon src={imageIconsCheckboxCheck} variant="white" standardSize="list-item-icon" />
      ) : (
        <IconPlaceholder standardSize="list-item-icon" style={{ backgroundColor: 'transparent' }} />
      )}
    </Button>
  ));
};

// Helpers

const styles = {
  'field-rectangular': { flexShrink: 0, width: px(STD_FIELD_SIZE_PX), height: px(STD_FIELD_SIZE_PX), padding: sp(0) },
  'field-round': {
    flexShrink: 0,
    width: px(STD_FIELD_SIZE_PX),
    height: px(STD_FIELD_SIZE_PX),
    padding: sp(0),
    borderRadius: px(STD_FIELD_SIZE_PX / 2)
  },
  'icon-rectangular': {
    flexShrink: 0,
    width: px(STD_ICON_SIZE_PX),
    height: px(STD_ICON_SIZE_PX),
    minWidth: px(STD_ICON_SIZE_PX),
    minHeight: px(STD_ICON_SIZE_PX),
    padding: sp(0)
  },
  'icon-round': {
    flexShrink: 0,
    width: px(STD_ICON_SIZE_PX),
    height: px(STD_ICON_SIZE_PX),
    minWidth: px(STD_ICON_SIZE_PX),
    minHeight: px(STD_ICON_SIZE_PX),
    padding: sp(0),
    borderRadius: px(STD_ICON_SIZE_PX / 2)
  }
} satisfies Record<string, CSSProperties>;
