import _ from 'lodash';

import { developmentConfig } from './development';
import type { AppConfig } from './types';

export const localConfig = _.once(
  (): AppConfig =>
    _.merge({}, developmentConfig(), {
      appEnv: 'local',
      api: {
        baseUrls: {
          default: 'http://api.local.dev.linefeedr.com:8080'
        }
      }
    })
);
