import { schema } from 'yaschema';

import { MAX_ARTICLE_HEADING_LENGTH } from '../../../../consts/limits';
import { articleHeadingLevelSchema } from '../ArticleHeadingLevel';
import { makeArticleParagraphSchema } from './internal/makeArticleParagraphSchema';

export const articleHeadingParagraphSchema = makeArticleParagraphSchema(
  'heading',
  schema.object({
    level: articleHeadingLevelSchema,
    content: schema.string().setAllowedLengthRange(1, MAX_ARTICLE_HEADING_LENGTH)
  })
);
export type ArticleHeadingParagraph = typeof articleHeadingParagraphSchema.valueType;
