import { schema } from 'yaschema';

import type { OptionalAuthHeaders } from './api-data/AuthHeaders';
import { optionalAuthHeadersSchema } from './api-data/AuthHeaders';
import { httpResponseStatusSchema } from './HttpResponseStatus';

const makeFailureWithCodeBodySchema = <ErrorCodeT extends string>(...errorCodes: ErrorCodeT[]) =>
  schema.object({
    errorCode: schema.string<ErrorCodeT>(...errorCodes),
    message: schema.string().allowEmptyString().optional()
  });

export const makeFailureWithCodeSchemas = <ErrorCodeT extends string>(...errorCodes: ErrorCodeT[]) => ({
  status: httpResponseStatusSchema.not(schema.number(200)),
  headers: optionalAuthHeadersSchema,
  body: schema.oneOf(makeFailureWithCodeBodySchema(...errorCodes), schema.string().allowEmptyString().optional())
});
export interface FailureWithCode<ErrorCodeT extends string> {
  status: number;
  headers?: OptionalAuthHeaders;
  body?: { errorCode: ErrorCodeT; message?: string } | string;
}
