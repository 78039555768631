import { schema } from 'yaschema';

import { personaPermissionReasonSchema } from './PersonaPermissionReason';
import { personaPermissionsSchema } from './PersonaPermissions';

export const personaPermissionContextSchema = schema.object({
  permissions: personaPermissionsSchema,
  reason: personaPermissionReasonSchema
});
export type PersonaPermissionContext = typeof personaPermissionContextSchema.valueType;
