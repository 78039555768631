import { useEffect } from 'react';
import { useBinding } from 'react-bindings';

export const useWindowScrollTop = () => {
  const output = useBinding<number>(() => window.scrollY, {
    id: 'windowScrollTop',
    detectChanges: true
  });

  useEffect(() => {
    const listener = () => output.set(window.scrollY);
    window.addEventListener('scroll', listener);
    listener();

    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return output;
};
