import { BC } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { ONE_SEC_MSEC } from '../../consts/time';
import { useIsScreenLocked } from '../../context/screen-locker';
import { Row } from '../layout/Row';
import type { SpinnerProps } from './Spinner';
import { Spinner } from './Spinner';

export type ScreenLockingFeedbackProps = SpinnerProps;

export const ScreenLockingFeedback = (props: ScreenLockingFeedbackProps) => {
  const isScreenLocked = useIsScreenLocked();
  const classNames = useStyles();

  return BC(isScreenLocked, (isScreenLocked) =>
    isScreenLocked ? (
      <Row justifyContent="center" alignItems="center" className={classNames.main}>
        <Spinner {...props} />
      </Row>
    ) : null
  );
};

// Helpers

const useStyles = createUseStyles({
  '@keyframes lockingFeedback': {
    from: {
      backdropFilter: 'blur(0px) grayscale(0%)',
      backgroundColor: 'rgba(255,255,255,0)'
    },
    to: {
      backdropFilter: 'blur(16px) grayscale(100%)',
      backgroundColor: 'rgba(255,255,255,0.7)'
    }
  },
  main: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    animation: `$lockingFeedback ${ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s linear`,
    animationFillMode: 'forwards'
  }
});
