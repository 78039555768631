import { apiData } from 'lf-blog-schema';

import type { ArticleScreenArgs } from '../../screens/ArticleScreen';
import { makeRouteInfo } from '../makeRouteInfo';
import { todayRoute } from './todayRoute';

export const articleRoute = makeRouteInfo<{ articleId: apiData.ArticleIdentifier }, [Omit<ArticleScreenArgs, 'articleId'>] | []>({
  path: ['article', ['articleId']],
  pathSchemas: { articleId: apiData.articleIdentifierSchema },
  defaultHistory: (context, _pathParams, _args = {}) => [todayRoute(context, {})],
  screen: async (_context, { articleId }, args = {}) => {
    const { ArticleScreen } = await import('../../screens/ArticleScreen');

    return ArticleScreen({ ...args, articleId });
  }
});
