import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { authTokenDeliveryModeSchema } from '../../types/api-data/auth/AuthTokenDeliveryMode';
import { passwordSchema } from '../../types/api-data/auth/Password';
import { usernameSchema } from '../../types/api-data/identifiers/Username';

export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'auth',
  credentials: 'include',
  url: '/auth',
  schemas: {
    request: {
      body: schema.object({
        username: usernameSchema,
        password: passwordSchema,
        authTokenDeliveryMode: authTokenDeliveryModeSchema
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: schema.object({
        /** If `true`, the auth token isn't verified yet and multi-factor authentication is required before most APIs can be used */
        requiresMfa: schema.boolean()
      })
    },
    failureResponse: genericFailureSchemas
  }
});
