import type { BindingArrayDependencies, ReadonlyBinding, TypeOrDeferredType } from 'react-bindings';
import { resolveTypeOrDeferredType } from 'react-bindings';
import type { ResetType, UseWaitableArgs, Waitable } from 'react-waitables';
import { useWaitableFunction } from 'react-waitables';

import type { UncaughtException } from '../types/UncaughtException';
import { concatArrays, normalizeAsArray, normalizeAsOptionalArray } from '../utils/array-like';
import type { MakeTaskArgs, Task } from './makeTask';
import { makeTask } from './makeTask';

export interface MakeWaitableTaskArgs<ContextT, ArgsT extends any[], SuccessT, FailureT = any>
  extends MakeTaskArgs<ContextT, ArgsT, SuccessT, FailureT> {
  hardResetBindings?: TypeOrDeferredType<ReadonlyBinding | BindingArrayDependencies | undefined>;
  softResetBindings?: TypeOrDeferredType<ReadonlyBinding | BindingArrayDependencies | undefined>;
}

export interface TaskWaitableEnhancements {
  clearCache: () => Promise<void>;
  clearCacheAndReset: (resetType: ResetType) => Promise<void>;
}

// TODO: move to separate lib
export const makeWaitableTask = <ContextT, ArgsT extends any[], SuccessT, FailureT = any>({
  hardResetBindings,
  softResetBindings,
  ...makeTaskArgs
}: MakeWaitableTaskArgs<ContextT, ArgsT, SuccessT, FailureT>): Task<ContextT, ArgsT, SuccessT, FailureT> & {
  useWaitable: (
    params: Omit<UseWaitableArgs<SuccessT, FailureT | UncaughtException>, 'id'> & {
      context: ContextT;
      args: TypeOrDeferredType<ArgsT>;
      id?: string;
    }
  ) => Waitable<SuccessT, FailureT | UncaughtException> & TaskWaitableEnhancements;
} => {
  const task = makeTask(makeTaskArgs);

  let resolvedHardResetBindings: BindingArrayDependencies | undefined;
  let resolvedSoftResetBindings: BindingArrayDependencies | undefined;

  return {
    ...task,
    useWaitable: ({ context, args, ...waitableArgs }) => {
      if (resolvedHardResetBindings === undefined && hardResetBindings !== undefined) {
        resolvedHardResetBindings = normalizeAsArray(resolveTypeOrDeferredType(hardResetBindings));
      }
      if (resolvedSoftResetBindings === undefined && softResetBindings !== undefined) {
        resolvedSoftResetBindings = normalizeAsArray(resolveTypeOrDeferredType(softResetBindings));
      }

      const regularWaitable = useWaitableFunction<SuccessT, FailureT | UncaughtException>(
        () => task.run(context, ...resolveTypeOrDeferredType(args)),
        {
          id: task.id,
          defaultValue: 'use-primary-function',
          ...waitableArgs,
          hardResetBindings:
            (resolvedHardResetBindings?.length ?? 0) > 0
              ? concatArrays(resolvedHardResetBindings, normalizeAsOptionalArray(waitableArgs.hardResetBindings))
              : waitableArgs.hardResetBindings,
          softResetBindings:
            (resolvedSoftResetBindings?.length ?? 0) > 0 !== undefined
              ? concatArrays(resolvedSoftResetBindings, normalizeAsOptionalArray(waitableArgs.softResetBindings))
              : waitableArgs.softResetBindings
        }
      ) as Waitable<SuccessT, FailureT | UncaughtException> & Partial<TaskWaitableEnhancements>;

      regularWaitable.clearCache = async () => task.clearCache(...resolveTypeOrDeferredType(args));
      regularWaitable.clearCacheAndReset = async (resetType: ResetType) => {
        const resolvedArgs = resolveTypeOrDeferredType(args);
        await task.clearCache(...resolvedArgs);
        regularWaitable.reset(resetType);
      };

      return regularWaitable as Waitable<SuccessT, FailureT | UncaughtException> & TaskWaitableEnhancements;
    }
  };
};
