import type { TFunction } from 'i18next';
import * as i18next from 'i18next';

import { normalizeForLocalizationKey } from './normalizeForLocalizationKey';

const LOCALIZATION_NOT_FOUND = 'LOCALIZATION_NOT_FOUND';

export function LOCALIZE(defaultLangValue: string): (options: { ns: string }) => (t: TFunction) => string;
export function LOCALIZE<ParamT extends string>(
  strings: TemplateStringsArray,
  ...params: ParamT[]
): (options: { ns: string }) => (t: TFunction, params: Record<ParamT, string>) => string;
export function LOCALIZE<ParamsT extends string>(
  defaultLangValueOrStrings: string | TemplateStringsArray,
  ...params: ParamsT[]
): (options: { ns: string }) => ((t: TFunction) => string) | ((t: TFunction, params: Record<ParamsT, string>) => string) {
  if (typeof defaultLangValueOrStrings === 'string') {
    const key = normalizeForLocalizationKey(defaultLangValueOrStrings);
    return ({ ns }) =>
      (t: TFunction) =>
        t(key, { ns, defaultValue: defaultLangValueOrStrings });
  } else {
    const templateStrings = defaultLangValueOrStrings as TemplateStringsArray;

    const defaultValueParts: string[] = [];
    const keyParts: string[] = [];
    for (let index = 0; index < templateStrings.length; index += 1) {
      defaultValueParts.push(templateStrings[index]);
      keyParts.push(normalizeForLocalizationKey(templateStrings[index]));
      if (index < params.length) {
        defaultValueParts.push(`{{${params[index]}}}`);
        keyParts.push(`_${normalizeForLocalizationKey(params[index])}_`);
      }
    }

    const defaultValue = defaultValueParts.join('');

    const key = keyParts.join('');
    return ({ ns }) =>
      (t: TFunction, params: Record<ParamsT, string>) => {
        const resolved = t(key, { ...(params as Record<any, string>), ns, defaultValue: LOCALIZATION_NOT_FOUND });

        if (resolved === LOCALIZATION_NOT_FOUND) {
          i18next.loadNamespaces(ns);
          return t(key, { ...(params as Record<any, string>), ns, defaultValue });
        } else {
          return resolved;
        }
      };
  }
}
