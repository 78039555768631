import { BC } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { LOADING_CODE_Z_INDEX } from '../../consts/z-index';
import { useLoadingCodeTracker } from '../../context/loading-code';
import { Row } from '../layout/Row';
import { AppLoadingIndicator } from './AppLoadingIndicator';

export const LoadingCodeIndicator = () => {
  const { isLoadingCode } = useLoadingCodeTracker();
  const classNames = useStyles();

  return BC(isLoadingCode, (isLoadingCode) =>
    isLoadingCode ? (
      <Row alignItems="stretch" className={classNames.main}>
        <AppLoadingIndicator />
      </Row>
    ) : null
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: LOADING_CODE_Z_INDEX,
    backgroundColor: 'rgba(255,255,255,0.9)'
  }
});
