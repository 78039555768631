import { schema } from 'yaschema';

import type { OptionalAuthHeaders } from './api-data/AuthHeaders';
import { optionalAuthHeadersSchema } from './api-data/AuthHeaders';
import { httpResponseStatusSchema } from './HttpResponseStatus';

export const genericFailureSchemas = {
  status: httpResponseStatusSchema.not(schema.number(200)),
  headers: optionalAuthHeadersSchema,
  body: schema.string().allowEmptyString().optional()
};
export interface GenericFailure {
  status: number;
  headers?: OptionalAuthHeaders;
  body?: string;
}
