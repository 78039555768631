import { darken } from 'color2k';
import { useCallbackRef } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC } from '../../consts/animation';
import { br, px, sp, STD_FIELD_SIZE_PX, STD_SMALL_FIELD_SIZE_PX } from '../../consts/layout';
import { contrastingTextPrimaryColorByBackgroundColorClass, resolveColorVariant, resolveColorVariantClass } from '../../consts/palette';
import { ONE_SEC_MSEC } from '../../consts/time';
import { useIsScreenLocked } from '../../context/screen-locker';
import type { RouteInfoResolver } from '../../routing/types/RouteInfoResolver';
import type { ChildrenProps, StringChildrenProps } from '../../types/ChildrenProps';
import { type ColorVariant, makeCustomColorVariant } from '../../types/ColorVariant';
import type { NavMode } from '../../types/NavMode';
import type { RefForwardingProps } from '../../types/RefForwardingProps';
import { typographyOrContext } from '../aliases/typographyOrContext';
import type { LinkElement } from './Link';
import { Link } from './Link';
import { ScreenLockingFeedback } from './ScreenLockingFeedback';

export type ButtonSize = 'small' | 'regular';
export type ButtonVariant = 'contained' | 'contained-text' | 'outlined' | 'padded-text' | 'text';

export interface ButtonProps<ValueT>
  extends Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<LinkElement>, LinkElement>, 'className' | 'color' | 'onClick' | 'value'>,
    RefForwardingProps<LinkElement> {
  color: ColorVariant;
  /** @defaultValue `'regular'` */
  size?: ButtonSize;
  variant: ButtonVariant;
  disabled?: boolean;
  onClick?: (value: ValueT) => void;
  nav?: RouteInfoResolver;
  /** @defaultValue `'only-secondary'` */
  navMode?: NavMode;
  value?: ValueT;
  /** @defaultValue `false` */
  showScreenLockingFeedback?: boolean;
}

export const Button = <ValueT,>({
  children,
  fwdRef,
  color,
  size = 'regular',
  variant,
  disabled = false,
  onClick,
  nav,
  navMode,
  value,
  showScreenLockingFeedback = false,
  ...props
}: (ChildrenProps | StringChildrenProps) & ButtonProps<ValueT>) => {
  const resolvedColor = disabled ? 'disabledText' : color;
  const textColor =
    variant === 'contained' ? contrastingTextPrimaryColorByBackgroundColorClass[resolveColorVariantClass(resolvedColor)] : resolvedColor;

  const isScreenLocked = useIsScreenLocked();
  const classNames = useStyles({ color: resolvedColor, textColor });

  const wrappedOnClick = useCallbackRef(() => {
    if (isScreenLocked.get()) {
      return;
    }

    if (disabled) {
      return;
    }

    onClick?.(value as ValueT);
  });
  const isClickable =
    !disabled && (onClick !== undefined || (navMode !== 'only-secondary' && nav !== undefined) || props.href !== undefined);

  return (
    <Link
      fwdRef={fwdRef}
      disabled={disabled}
      {...props}
      className={`Button ${classNames.common} ${classNames[variant]} ${size} ${isClickable ? classNames[`${variant}-clickable`] : ''}`}
      onClick={isClickable ? wrappedOnClick : undefined}
      nav={nav}
      navMode={navMode}
    >
      {isClickable && variant === 'text' ? <div className="interaction-feedback" /> : null}
      {showScreenLockingFeedback ? <ScreenLockingFeedback color="black" variant="small" /> : null}
      {typographyOrContext(children, {
        color: makeCustomColorVariant('inherit', resolveColorVariantClass(textColor)),
        variant: size === 'small' ? 'buttonSmall' : 'button'
      })}
    </Link>
  );
};

// Helpers

interface StylesArgs {
  color: ColorVariant;
  textColor: ColorVariant;
}

const useStyles = createUseStyles({
  common: ({ textColor }: StylesArgs) => ({
    color: resolveColorVariant(textColor),
    transition: `background-color ${FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC}s, color ${
      FAST_ELEMENT_LEVEL_TRANSITION_DURATION_MSEC / ONE_SEC_MSEC
    }s`,
    fontSize: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  contained: ({ color }: StylesArgs) => ({
    backgroundColor: resolveColorVariant(color),
    borderRadius: br(0.5),
    border: 'none',
    overflow: 'hidden',
    padding: `${sp(1)} ${sp(2)}`,
    minHeight: px(STD_FIELD_SIZE_PX),
    minWidth: px(STD_FIELD_SIZE_PX),
    '&.small': {
      padding: `${sp(0.5)} ${sp(1)}`,
      minHeight: px(STD_SMALL_FIELD_SIZE_PX),
      minWidth: px(STD_SMALL_FIELD_SIZE_PX)
    }
  }),
  'contained-clickable': ({ color }: StylesArgs) => ({
    '&:hover': {
      backgroundColor: darken(resolveColorVariant(color), 0.05),
      '&:active': {
        backgroundColor: darken(resolveColorVariant(color), 0.1)
      }
    }
  }),
  'contained-text': {
    backgroundColor: 'rgba(0,0,0,0.02)',
    border: 'none',
    borderRadius: br(0.5),
    overflow: 'hidden',
    padding: `${sp(1)} ${sp(2)}`,
    minHeight: px(STD_FIELD_SIZE_PX),
    minWidth: px(STD_FIELD_SIZE_PX),
    '&.small': {
      padding: `${sp(0.5)} ${sp(1)}`,
      minHeight: px(STD_SMALL_FIELD_SIZE_PX),
      minWidth: px(STD_SMALL_FIELD_SIZE_PX)
    }
  },
  'contained-text-clickable': {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      '&:active': {
        backgroundColor: 'rgba(0,0,0,0.1)'
      }
    }
  },
  outlined: ({ color }: StylesArgs) => ({
    backgroundColor: 'transparent',
    border: `1px solid ${resolveColorVariant(color)}`,
    borderRadius: br(0.5),
    overflow: 'hidden',
    padding: `${sp(1)} ${sp(2)}`,
    minHeight: px(STD_FIELD_SIZE_PX),
    minWidth: px(STD_FIELD_SIZE_PX),
    '&.small': {
      padding: `${sp(0.5)} ${sp(1)}`,
      minHeight: px(STD_SMALL_FIELD_SIZE_PX),
      minWidth: px(STD_SMALL_FIELD_SIZE_PX)
    }
  }),
  'outlined-clickable': {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      '&:active': {
        backgroundColor: 'rgba(0,0,0,0.1)'
      }
    }
  },
  'padded-text': {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: br(0.5),
    overflow: 'hidden',
    padding: `${sp(1)} ${sp(2)}`,
    minHeight: px(STD_FIELD_SIZE_PX),
    minWidth: px(STD_FIELD_SIZE_PX),
    '&.small': {
      padding: `${sp(0.5)} ${sp(1)}`,
      minHeight: px(STD_SMALL_FIELD_SIZE_PX),
      minWidth: px(STD_SMALL_FIELD_SIZE_PX)
    }
  },
  'padded-text-clickable': {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      '&:active': {
        backgroundColor: 'rgba(0,0,0,0.1)'
      }
    }
  },
  text: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0px',
    padding: '0px'
  },
  'text-clickable': {
    '& > .interaction-feedback': {
      position: 'absolute',
      top: sp(-0.5),
      right: sp(-0.5),
      bottom: sp(-0.5),
      left: sp(-0.5),
      pointerEvents: 'none',
      overflow: 'hidden',
      zIndex: 1,
      borderRadius: br(0.5)
    },
    '&:hover': {
      '& > .interaction-feedback': {
        backgroundColor: 'rgba(0,0,0,0.05)'
      },
      '&:active': {
        '& > .interaction-feedback': {
          backgroundColor: 'rgba(0,0,0,0.1)'
        }
      }
    }
  }
});
