import { createContext, useContext } from 'react';
import { useCallbackRef } from 'react-bindings';

import type { ChildrenProps } from '../types/ChildrenProps';
import { ContainerHeightProvider } from './container-height';
import { ContainerWidthProvider } from './container-width';
import { ModalPresenterProvider } from './modals';
import { useDomRef } from './useDomRef';

const ContainerContext = createContext<() => HTMLElement | null>(() => null);

export const useContainer = () => useContext(ContainerContext);

export const ContainerProvider = ({ children }: ChildrenProps) => {
  const ref = useDomRef<HTMLDivElement>();

  const getContainer = useCallbackRef(() => ref.current?.parentElement ?? null);

  return (
    <ContainerContext.Provider value={getContainer}>
      <div ref={ref} style={{ width: 0, height: 0 }} />
      <ContainerWidthProvider>
        <ContainerHeightProvider>
          <ModalPresenterProvider>{children}</ModalPresenterProvider>
        </ContainerHeightProvider>
      </ContainerWidthProvider>
    </ContainerContext.Provider>
  );
};
