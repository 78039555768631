import { schema } from 'yaschema';

import { richTextSchema } from '../../RichText';
import { makeArticleParagraphSchema } from './internal/makeArticleParagraphSchema';

export const articleTextParagraphSchema = makeArticleParagraphSchema(
  'text',
  schema.object({
    /** Max length should be applied to the concatenated text.  We check the lengths of the individual parts against the max, but the
     * total length will have to be validated separately from yaschema */
    content: richTextSchema
  })
);
export type ArticleTextParagraph = typeof articleTextParagraphSchema.valueType;
