import type { ICache } from '../types/ICache';

class CacheRegistry {
  private readonly _registeredCaches: Array<ICache<any>> = [];

  public readonly register = <T extends ICache<any>>(cache: T): T => {
    this._registeredCaches.push(cache);
    return cache;
  };

  public readonly clearAllCaches = async () => Promise.all(this._registeredCaches.map((cache) => cache.clear()));

  public readonly clearAllCachesTagged = async (tags: string[]) =>
    Promise.all(this._registeredCaches.map((cache) => cache.clearTagged(tags)));
}

export const registry = new CacheRegistry();
