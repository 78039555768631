/* eslint-disable */
import svg_black from './black.svg';
import svg_white from './white.svg';
import svg_error from './error.svg';

export const imageLargeIconsError = {
  isVariantImageSource: true as const,
  widthPx: 48,
  heightPx: 48,
  svgs: { black: svg_black, white: svg_white, error: svg_error }
};
