import { schema } from 'yaschema';

import { personaIdentifierSchema } from '../identifiers/PersonaIdentifier';
import { personaAccessStatusSchema } from './PersonaAccessStatus';
import { personaInfoSchema } from './PersonaInfo';

export const userPersonaInfoSchema = schema.allOf(
  personaInfoSchema,
  schema.object({
    id: personaIdentifierSchema,
    status: personaAccessStatusSchema
  })
);
export type UserPersonaInfo = typeof userPersonaInfoSchema.valueType;
