import { StatusCodes } from 'http-status-codes';
import type { apiData } from 'lf-blog-schema';
import { api } from 'lf-blog-schema';
import { authApiData } from 'linefeedr-auth-schema';
import { apiFetch } from 'yaschema-api-fetcher';

import { getConfig } from '../../config/getConfig';
import { ONE_SEC_MSEC } from '../../consts/time';
import { authStatusDevTool } from '../../dev/tools/authStatusDevTool';
import { getDevToolValue } from '../../dev/tools/dev-tools-support';
import { makeWaitableTask } from '../../makers/makeWaitableTask';

export const checkAuth = makeWaitableTask<undefined, [], apiData.AuthCheck>({
  id: 'checkAuth',
  run: async () => {
    const res = await apiFetch(
      api.auth.check.GET,
      {},
      {
        shouldRetry: async ({ retryCount, res }) => {
          // This should always be retried, except if status is StatusCodes.UNAUTHORIZED, which is a success state that indicates the user
          // isn't signed in
          if (res?.status === StatusCodes.UNAUTHORIZED) {
            return false;
          } else {
            return { afterDelayMSec: Math.min(retryCount * 300, 30 * ONE_SEC_MSEC) };
          }
        }
      }
    );

    if (!res.ok) {
      // The only case that should reach here is if status is UNAUTHORIZED
      return { ok: true, value: {} };
    }

    return { ok: true, value: res.body };
  },
  hardResetBindings: () => (getConfig().devMode === 'offline' ? getDevToolValue(authStatusDevTool) : undefined),
  ifOfflineDev: () => {
    switch (getDevToolValue(authStatusDevTool).get()) {
      case 'success':
        return {
          ok: true,
          value: {
            username: authApiData.makeUsername('mock-username'),
            userType: 'default',
            sessionId: authApiData.makeSessionIdentifier('mock-session-id'),
            requiresMfa: false
          }
        };
      case 'success-needs-mfa':
        return { ok: true, value: { sessionId: authApiData.makeSessionIdentifier('mock-session-id'), requiresMfa: true } };
      case 'error':
        return { ok: false, value: undefined };
      case 'unauthorized':
        return { ok: true, value: {} };
    }
  }
});
