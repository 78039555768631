import type { Binding } from 'react-bindings';

import type { NavScreen, NavScreenHook } from '../context/navigation/types/NavScreen';
import { LoadingScreen } from '../screens/LoadingScreen';
import type { RealReactNode } from '../types/RealReactNode';

export interface MakeRoutingScreenArgs<ArgsT extends any[]> {
  useHook: (...args: ArgsT) => void;
  useMessage?: (...args: ArgsT) => RealReactNode | string | Binding<RealReactNode | string>;
}

export const makeRoutingScreen =
  <ArgsT extends any[] = []>(useNavScreen: (...args: ArgsT) => MakeRoutingScreenArgs<ArgsT>) =>
  (...args: ArgsT): NavScreenHook =>
  (): NavScreen => {
    const { useHook, useMessage } = useNavScreen(...args);

    useHook(...args);
    const message = useMessage?.(...args);

    return LoadingScreen({ message })();
  };
