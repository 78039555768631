import { schema } from 'yaschema';

import { MAX_EMAIL_LENGTH, MAX_PHONE_LENGTH, MAX_URL_LENGTH } from '../../../consts/limits';
import { physicalAddressSchema } from './PhysicalAddress';

export const contactInfoSchema = schema.object({
  homePageUrl: schema.string().setAllowedLengthRange(1, MAX_URL_LENGTH).optional(),
  email: schema.string().setAllowedLengthRange(1, MAX_EMAIL_LENGTH).optional(),
  displayEmail: schema.boolean(),
  phone: schema.string().setAllowedLengthRange(1, MAX_PHONE_LENGTH).optional(),
  displayPhone: schema.boolean(),
  address: physicalAddressSchema.optional(),
  displayAddress: schema.boolean()
});
export type ContactInfo = typeof contactInfoSchema.valueType;
