import { BC, useBinding, useBindingEffect, useCallbackRef } from 'react-bindings';

import { imageIconsRotate } from '../../../assets/icons/rotate';
import { Button } from '../../../components/core/Button';
import { Icon } from '../../../components/core/Icon';
import type { SelectFieldChoiceGroup } from '../../../components/fields/SelectField';
import { SelectField } from '../../../components/fields/SelectField';
import { ListItem } from '../../../components/list/ListItem';
import { disabledOpacity } from '../../../consts/palette';
import { type Size2DPx, size2DPxToString } from '../../../types/Size2DPx';
import type { DevTool } from '../dev-tools-support';

export interface DeviceSizeDevToolProps {
  devTool: DevTool<{ size: Size2DPx; landscapeMode: boolean }>;
}

export const DeviceSizeDevTool = ({ devTool }: DeviceSizeDevToolProps) => {
  const selectedDeviceSize = useBinding<Size2DPx>(() => devTool.value.get().size, {
    id: 'selectedDeviceSize',
    detectChanges: true
  });
  const landscapeMode = useBinding(() => devTool.value.get().landscapeMode, { id: 'landscapeMode', detectChanges: true });

  useBindingEffect(
    devTool.value,
    (value) => {
      selectedDeviceSize.set(value.size);
      landscapeMode.set(value.landscapeMode);
    },
    { triggerOnMount: true }
  );

  useBindingEffect({ selectedDeviceSize, landscapeMode }, ({ selectedDeviceSize, landscapeMode }) =>
    devTool.value.set({ size: selectedDeviceSize, landscapeMode })
  );

  const onToggleLandscapeClick = useCallbackRef(() => landscapeMode.set(!landscapeMode.get()));

  return (
    <ListItem>
      <SelectField
        title="Device Size"
        value={selectedDeviceSize}
        valueToString={size2DPxToString}
        choiceGroups={deviceInfoChoiceGroups}
        width="100%"
        post={BC(selectedDeviceSize, (selectedDeviceSize) => {
          const supportsLandscapeMode = deviceInfosByKey[size2DPxToString(selectedDeviceSize)]?.supportsLandscapeMode ?? true;

          return (
            <Button variant="text" color="textPrimary" onClick={onToggleLandscapeClick} disabled={!supportsLandscapeMode}>
              <Icon src={imageIconsRotate} variant="black" style={{ opacity: !supportsLandscapeMode ? disabledOpacity : undefined }} />
            </Button>
          );
        })}
      />
    </ListItem>
  );
};

// Helpers

interface DeviceInfo {
  /** @defaultValue `true` */
  supportsLandscapeMode?: boolean;
  title: string;
  size: Size2DPx;
}

const deviceInfos: DeviceInfo[] = [
  { title: 'Smallest Supported', size: { widthPx: 320, heightPx: 480 } },
  { title: 'iPhone SE', size: { widthPx: 375, heightPx: 667 } },
  { title: 'iPhone 15 Pro', size: { widthPx: 393, heightPx: 852 } },
  // 0 is treated as 100%
  { title: 'Browser', size: { widthPx: 0, heightPx: 0 }, supportsLandscapeMode: false }
];

const deviceInfosByKey = deviceInfos.reduce((out: Partial<Record<string, DeviceInfo>>, deviceInfo) => {
  out[size2DPxToString(deviceInfo.size)] = deviceInfo;
  return out;
}, {});

const deviceInfoChoiceGroups: SelectFieldChoiceGroup<Size2DPx>[] = [
  {
    choices: deviceInfos.map((info) => ({
      value: info.size,
      title: info.title
    }))
  }
];
