import type { ColorClass } from '../types/ColorClass';
import {
  type ColorVariant,
  type CustomColorVariant,
  isCustomColorVariant,
  makeCustomColorVariant,
  type NamedColorVariant
} from '../types/ColorVariant';

export const disabledOpacity = 0.33;

export const palette = {
  contrastTextPrimary: makeCustomColorVariant('#fff', 'light'),
  contrastTextSecondary: makeCustomColorVariant('rgba(255,255,255,0.66)', 'light'),
  devModeBackground: makeCustomColorVariant('#888', 'dark'),
  disabledText: makeCustomColorVariant(`rgba(0,0,0,${disabledOpacity})`, 'light'),
  divider: makeCustomColorVariant('rgba(0,0,0,0.25)', 'light'),
  error: makeCustomColorVariant('#c20', 'dark'),
  glassPane: makeCustomColorVariant('rgba(0,0,0,0.3)', 'dark'),
  highlightBackground: makeCustomColorVariant('#e6f8ff', 'light'),
  inputBackground: makeCustomColorVariant('#fff', 'light'),
  inputBorder: makeCustomColorVariant('rgba(0,0,0,0.33)', 'light'),
  inputFocusOutline: makeCustomColorVariant('#8CB3FB', 'dark'),
  mainBackground: makeCustomColorVariant('#fff', 'light'),
  placeholderBackground: makeCustomColorVariant('rgba(0,0,0,0.1)', 'light'),
  placeholderText: makeCustomColorVariant('rgba(0,0,0,0.33)', 'light'),
  primary: makeCustomColorVariant('#0bf', 'dark'),
  scrollToolBackground: makeCustomColorVariant('rgba(0,0,0,0.1)', 'dark'),
  shadow: makeCustomColorVariant('rgba(0,0,0,0.33)', 'dark'),
  textPrimary: makeCustomColorVariant('#000', 'dark'),
  textSecondary: makeCustomColorVariant('rgba(0,0,0,0.66)', 'dark'),
  toolbarBackground: makeCustomColorVariant('#fff', 'light'),
  transientModalBackground: makeCustomColorVariant('rgba(255,255,255,0.95)', 'light')
} satisfies Record<NamedColorVariant, CustomColorVariant>;

export const contrastingTextPrimaryColorByBackgroundColorClass: Record<ColorClass, ColorVariant> = {
  dark: 'contrastTextPrimary',
  light: 'textPrimary'
};
export const contrastingTextSecondaryColorByBackgroundColorClass: Record<ColorClass, ColorVariant> = {
  dark: 'contrastTextSecondary',
  light: 'textSecondary'
};

export const resolveColorVariant = (color: ColorVariant) => (isCustomColorVariant(color) ? color : palette[color]).color;
export const resolveColorVariantClass = (color: ColorVariant) => (isCustomColorVariant(color) ? color : palette[color]).colorClass;
