import { BC, makeConstBinding, useDerivedBinding, useFlattenedBinding } from 'react-bindings';
import { createUseStyles } from 'react-jss';

import { imageIndicatorsScrollToTop } from '../../assets/indicators/scroll-to-top';
import { MAX_PAGE_HEIGHT_PX, px, sp, SP_PX } from '../../consts/layout';
import { palette, resolveColorVariant } from '../../consts/palette';
import type { NavigationBarStyle } from '../../context/navigation/types/NavigationBarStyle';
import { useDocument } from '../../context/navigation/useDocument';
import { useScrollableParent } from '../../context/scrollable-parent';
import { PopIn } from '../animation/PopIn';
import { Row } from '../layout/Row';
import { Icon } from './Icon';

const MIN_SCROLL_BEFORE_SHOWING_PX = MAX_PAGE_HEIGHT_PX * 3;

export interface ScrollToTopIndicatorProps {
  onClick: () => void;
}

export const ScrollToTopIndicator = ({ onClick }: ScrollToTopIndicatorProps) => {
  const doc = useDocument();
  const scrollableParent = useScrollableParent();
  const classNames = useStyles();

  const navigationBarStyle = useFlattenedBinding(doc, (doc) => doc?.navigationBarStyle ?? defaultNavigationBarStyle, {
    id: 'navigationBarStyle'
  });
  const wantsMovingNavigationBar = useDerivedBinding(navigationBarStyle, (navigationBarStyle): boolean => navigationBarStyle === 'moving', {
    id: 'wantsMovingNavigationBar'
  });

  const shouldShow = useDerivedBinding(
    { wantsMovingNavigationBar, scrollPosition: scrollableParent?.scrollPosition, isScrolling: scrollableParent?.isScrolling },
    ({ wantsMovingNavigationBar, scrollPosition, isScrolling = true }) =>
      wantsMovingNavigationBar && !isScrolling && (scrollPosition?.yPx ?? 0) > MIN_SCROLL_BEFORE_SHOWING_PX,
    { id: 'shouldShow' }
  );

  return BC(shouldShow, (shouldShow) =>
    shouldShow ? (
      <Row justifyContent="center" className={classNames.main}>
        <PopIn immediate={true}>
          <div className={classNames.popIn} onClick={onClick}>
            <Icon src={imageIndicatorsScrollToTop} variant="black" />
          </div>
        </PopIn>
      </Row>
    ) : null
  );
};

// Helpers

const useStyles = createUseStyles({
  main: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: sp(0.5),
    zIndex: 1
  },
  popIn: {
    cursor: 'pointer',
    backgroundColor: resolveColorVariant(palette.scrollToolBackground),
    padding: `${sp(0.5)} ${sp(4)}`,
    borderRadius: px((SP_PX + imageIndicatorsScrollToTop.heightPx) / 2)
  }
});

// Helpers

const defaultNavigationBarStyle = makeConstBinding<NavigationBarStyle>('hidden', { id: 'defaultNavigationBarStyle' });
