/* eslint-disable */
import svg_black from './black.svg';
import svg_white from './white.svg';
import svg_primary from './primary.svg';

export const imageSpinnersSmall = {
  isVariantImageSource: true as const,
  widthPx: 24,
  heightPx: 24,
  svgs: { black: svg_black, white: svg_white, primary: svg_primary }
};
