import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { authCheckSchema } from '../../types/api-data/auth/AuthCheck';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'auth',
  credentials: 'include',
  url: '/auth/check',
  isSafeToRetry: true,
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: basicApiData.optionalAuthHeadersSchema,
      body: authCheckSchema
    },
    failureResponse: genericFailureSchemas
  }
});
