import { StatusCodes } from 'http-status-codes';
import { basicApiData, genericFailureSchemas } from 'linefeedr-basic-schema';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { userImageIdentifierSchema } from '../../../types/api-data/identifiers/UserImageIdentifier';
import { supportedImageTypeSchema } from '../../../types/api-data/image/SupportedImageType';

// TODO: support multiple resolutions
export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'content',
  credentials: 'include',
  url: '/image/{imageId}',
  isSafeToRetry: true,
  responseType: 'arraybuffer',
  schemas: {
    request: {
      headers: basicApiData.optionalAuthHeadersSchema,
      params: schema.object({
        imageId: userImageIdentifierSchema
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: schema.allOf(basicApiData.optionalAuthHeadersSchema, schema.object({ 'Content-Type': supportedImageTypeSchema })),
      body: schema.any()
    },
    failureResponse: genericFailureSchemas
  }
});
